import React from 'react';

import { Grid, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';

interface GridItemProps {
  title: string | undefined;
  seenOrIsHeading: boolean;
  breakpoint: number;
  message?: string;
  textColor?: string;
}

const GridItem = ({ title, seenOrIsHeading, breakpoint, message, textColor }: GridItemProps) => {
  const theme = useTheme();

  return (
    <Grid
      item
      md={breakpoint}
      sm={breakpoint}
      xs={breakpoint}
      sx={{
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        maxHeight: '100%',
        display: 'flex',
        flexDirection: 'column',
        gap: '4px',
      }}>
      <Typography
        sx={{
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          display: '-webkit-box',
          WebkitBoxOrient: 'vertical',
          WebkitLineClamp: 2,
          color: textColor || (seenOrIsHeading ? theme.palette.text.disabled : theme.palette.text.primary),
          paddingInlineStart: theme.spacing(2),
        }}
        variant={seenOrIsHeading ? 'body2' : 'subtitle1'}>
        {title}
      </Typography>
      {message && (
        <Typography
          sx={{
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            display: '-webkit-box',
            WebkitBoxOrient: 'vertical',
            WebkitLineClamp: 2,
            color: seenOrIsHeading ? theme.palette.text.disabled : theme.palette.text.primary,
            paddingInlineStart: theme.spacing(2),
          }}
          variant={seenOrIsHeading ? 'body2' : 'body1'}>
          {message}
        </Typography>
      )}
    </Grid>
  );
};

export default GridItem;
