import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import dayjs, { Dayjs } from 'dayjs';
import { eeaLogo } from 'images';

import { ArrowBackIos } from '@mui/icons-material';
import {
  Autocomplete,
  Box,
  FormControl,
  MenuItem,
  Select,
  Table,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';

import CustomButton from 'components/Button';
import BasicDatePicker from 'components/DatePicker/DatePicker';
import DeleteConfirmationDialog from 'components/DeleteConfirmationDialog';
import { FilterButtons, FilterPanelContainer, PageContainer } from 'components/GlobalStyles/styles';
import Loader from 'components/Loader';
import SnackBar, { MessageType } from 'components/SnackBar';
import { worklogActions } from 'features/worklog/worklogSlice';
import {
  useDeleteQuoteMutation,
  useGetClientsQuery,
  useGetQuotesByIdQuery,
  useUpdateQuotesMutation,
} from 'services/busmanApi';
import { LineHeader, LineItem, QuoteDetailProps } from 'types/QuoteTypes';
import { ActivitiesProps } from 'types/WorkLogTypes';
import { companyDetails } from 'utils/constants';
import { convertLineHeadersToCamelCase, convertLineHeadersToSnakeCase, instanceOfLine } from 'utils/quotes';

import { RootState } from '../../store';

import NewLineHeader from './Components/LineHeader';
import TextFieldBox from './Components/TextFieldBox';
import {
  ActivityFormContainer,
  BusinessDetails,
  ContactDetails,
  Footer,
  FormContent,
  FormContentReverse,
  HeaderContent,
  LogoContainer,
  NewLineBtnGroup,
  QuoteDocContainer,
  QuoteDocSummaryContainer,
  QuoteHeader,
  QuoteTotalsContainer,
  SummaryContainer,
  ToggleButton,
  ToggleButtonPanel,
  TypographyContainer,
  ViewQuoteContainer,
} from './styles';

const DEFAULT_VALUES = {
  id: '',
  quoteNumber: '',
  quotedDate: dayjs().format('DD/MM/YYYY'),
  taxCode: '',
  client: null,
  potentialClient: null,
  quotationPreparedBy: {
    userId: '',
    fullName: '',
    username: '',
    userRole: '',
  },
  quotesMetaData: null,
  status: 'Pending',
  createdAt: '',
  expirationDate: dayjs().add(1, 'month').format('DD/MM/YYYY'),
  updatedAt: '',
  lineHeaders: [{ jobQuoteId: '', description: '', lineItems: [] }],
};

const EditQuote = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { quoteId } = useParams();
  const { data: quoteData, isFetching } = useGetQuotesByIdQuery(quoteId);
  const [updateQuote, { isLoading }] = useUpdateQuotesMutation();
  const [deleteQuote] = useDeleteQuoteMutation();
  const [formValues, setFormValues] = useState<QuoteDetailProps>(DEFAULT_VALUES);
  const [toggleGst, setToggleGst] = useState('GST');
  const [toggleCustomClient, setToggleCustomClient] = useState(false);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [message, setMessage] = React.useState<MessageType | null>(null);

  const clientsResult = useGetClientsQuery({});
  const { clients } = useSelector((state: RootState) => state.worklogs);
  const { activities } = useSelector((state: RootState) => state.worklogs);
  const types = ['Pending', 'Submitted', 'Accepted'];

  React.useEffect(() => {
    if (!clientsResult.isLoading && !clientsResult.error && clientsResult.data) {
      dispatch(worklogActions.setClients(clientsResult.data));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clientsResult]);

  const clientObj = formValues.client ? clients.find((client) => client.client_id === formValues.client) : null;

  React.useEffect(() => {
    if (!quoteData) return;

    setFormValues({
      ...formValues,
      id: quoteData.id,
      status: quoteData.status,
      quoteNumber: quoteData.quote_number,
      quotedDate: quoteData.quoted_date,
      taxCode: clientObj?.tax_code.tax_id || null,
      client: quoteData.client,
      potentialClient: quoteData.potential_client && {
        clientName: quoteData.potential_client?.client_name || undefined,
        clientAddress: quoteData.potential_client?.client_address || undefined,
      },
      quotationPreparedBy: {
        userId: quoteData.quotation_prepared_by.user_id,
        fullName: quoteData.quotation_prepared_by.full_name,
        username: quoteData.quotation_prepared_by.username,
        userRole: quoteData.quotation_prepared_by.user_role,
      },
      createdAt: quoteData.created_at,
      quotesMetaData: quoteData.quotes_meta_data,
      expirationDate: quoteData.expiration_date || dayjs().add(1, 'month').format('YYYY-MM-DD'),
      updatedAt: quoteData.updated_at,
      lineHeaders:
        quoteData.line_headers.length > 0
          ? [...convertLineHeadersToSnakeCase(quoteData.line_headers)]
          : [...formValues.lineHeaders],
    });

    if (quoteData?.potential_client) {
      setToggleCustomClient(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [quoteData]);

  const calculateLineTotal = (lineItem: LineItem) => {
    const lineRate =
      activities.find((activity: ActivitiesProps) => activity.activity_id === lineItem.activityDetail?.activityId)
        ?.chargeable_detail?.rate_excluding_tax || 0;
    return lineItem.units * Number(lineRate) - lineItem.discount;
  };

  const calculateTotal = (lineHeaders: LineHeader[]) => {
    let total = 0;

    lineHeaders.forEach((lineHeader) => {
      lineHeader.lineItems.forEach((line) => {
        total += calculateLineTotal(line);
      });
    });

    return total;
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const total = React.useMemo(() => calculateTotal(formValues.lineHeaders), [formValues.lineHeaders]);
  const isFetchingData = isFetching || isLoading;

  const handleSetFormValues = (
    key: string,
    value: string | LineHeader | LineItem | Dayjs | null,
    lineHeaderidx?: number,
  ) => {
    //Check for line_header description
    if (key === 'lineHeaders' && !instanceOfLine(value) && typeof value === 'string') {
      const updatedLineHeaders = formValues.lineHeaders.map((header, idx) => {
        if (lineHeaderidx === idx) {
          const newLineHeader = { ...header, description: value };
          return newLineHeader;
        }
        return header;
      });
      return setFormValues({ ...formValues, [key]: updatedLineHeaders });
    }

    //Check for lineItem
    if (instanceOfLine(value) && lineHeaderidx !== undefined) {
      const preLineHeaders = formValues.lineHeaders.map((header, idx) => {
        if (idx === lineHeaderidx) {
          return { ...header, lineItems: [...header.lineItems, value] };
        }
        return header;
      });
      return setFormValues({ ...formValues, [key]: preLineHeaders });
    }
    //Everything else
    setFormValues((prevState) => {
      return { ...prevState, [key]: value };
    });
  };

  const handleSetCustomClient = (key: string, value: string) => {
    setFormValues((prevState) => {
      return { ...prevState, potentialClient: { ...prevState.potentialClient, [key]: value } };
    });
  };

  const handleSetMetaData = (key: string, value: string) => {
    setFormValues((prevState) => {
      return { ...prevState, quotesMetaData: { ...prevState.quotesMetaData, [key]: value } };
    });
  };

  const handleDeleteLine = (key: string, lineheaderIdx: number, lineItemIdx: number) => {
    const updatedLineHeaders = formValues.lineHeaders.map((header, idx) => {
      if (idx === lineheaderIdx) {
        header.lineItems.splice(lineItemIdx, 1);
        return header;
      }
      return header;
    });
    return setFormValues({ ...formValues, [key]: updatedLineHeaders });
  };

  const createNewLineHeader = () => {
    setFormValues((prevState) => ({
      ...prevState,
      lineHeaders: [...prevState.lineHeaders, { jobQuoteId: formValues.id, description: '', lineItems: [] }],
    }));
  };

  const handleDeleteLineHeader = (lineHeaderIdx: number) => {
    const updatedLineHeaders = formValues.lineHeaders.filter((lineHeader, idx) => idx !== lineHeaderIdx);

    setFormValues((prevState) => {
      return { ...prevState, lineHeaders: updatedLineHeaders };
    });
  };

  const handleDeleteQuote = async () => {
    setDeleteModalOpen(false);
    const result = await deleteQuote(formValues.id);

    if ('error' in result) {
      setMessage({ type: 'error', msg: 'Error deleting quote' });
    }

    if ('data' in result) {
      navigate('/quotes', {
        state: { type: 'success', msg: 'Quote deleted succesfully' },
      });
    }
  };

  const handleDuplicateLineHeader = (lineHeaderIdx: number) => {
    const lineHeaderCopy = formValues.lineHeaders.find((lineHeader, idx) => idx === lineHeaderIdx);
    const deepCopy = JSON.parse(JSON.stringify(lineHeaderCopy));
    if (lineHeaderCopy) {
      const duplicatedLineHeader = {
        ...deepCopy,
        lineItems: deepCopy?.lineItems.map((line: LineItem) => ({ ...line })),
      };

      setFormValues((prevState) => ({
        ...prevState,
        lineHeaders: [...prevState.lineHeaders, duplicatedLineHeader],
      }));
    }
  };

  const handleQuoteDateChange = (newValue: Dayjs | null) => {
    if (!newValue) return;
    setFormValues((prevState) => {
      return { ...prevState, quotedDate: newValue.format('YYYY-MM-DD') };
    });
  };

  const handleExpirationDateChange = (newValue: Dayjs | null) => {
    if (!newValue) return;
    setFormValues((prevState) => {
      return { ...prevState, expirationDate: newValue.format('YYYY-MM-DD') };
    });
  };

  const handleClientChange = (value: string | null) => {
    const selectedClient = clientsResult && clientsResult.data?.find((client) => client.client_id === value);

    if (selectedClient && selectedClient.address) {
      setFormValues({ ...formValues, quotesMetaData: { attn: selectedClient.address.contact_name } });
    }
    setFormValues({ ...formValues, client: value, taxCode: selectedClient?.tax_code.tax_id || '' });
  };

  const handleGstToggle = (event: React.MouseEvent<HTMLElement>, newAlignment: string) => {
    if (newAlignment !== null) {
      setToggleGst(newAlignment);
    }
    if (toggleCustomClient) {
      setFormValues((prevState) => {
        return { ...prevState, quotesMetaData: { ...prevState.quotesMetaData, taxCode: newAlignment } };
      });
    }
  };

  const handleToggleCustomClient = () => {
    setToggleGst('GST');
    setToggleCustomClient(!toggleCustomClient);
    if (toggleCustomClient) {
      setFormValues((prevState) => {
        return { ...prevState, quotesMetaData: { ...prevState.quotesMetaData, taxCode: null } };
      });
    }
    if (!toggleCustomClient) {
      setFormValues((prevState) => {
        return { ...prevState, quotesMetaData: { ...prevState.quotesMetaData, taxCode: toggleGst } };
      });
    }
  };

  const handleSubmitQuote = async () => {
    if (!formValues.client && !formValues.potentialClient) {
      setMessage({ type: 'error', msg: 'Please select a client' });
      return;
    }

    if (!formValues.lineHeaders.length) {
      setMessage({ type: 'error', msg: 'Please add at least one line header' });
      return;
    }

    const isLineItemEmpty = (lineItem: LineItem) => {
      return !lineItem.activityDetail || !lineItem.units;
    };

    const isLineHeaderEmpty = (lineHeader: LineHeader) => {
      return !lineHeader.description || lineHeader.lineItems.some(isLineItemEmpty);
    };

    const isFormValuesEmpty = () => {
      return (!toggleCustomClient && !formValues.client) || formValues.lineHeaders.some(isLineHeaderEmpty);
    };

    if (isFormValuesEmpty()) {
      setMessage({ type: 'error', msg: 'Please fill in all line item fields' });
      return;
    }

    if (
      (toggleCustomClient && !formValues.potentialClient?.clientName) ||
      (toggleCustomClient && !formValues.potentialClient?.clientAddress)
    ) {
      setMessage({ type: 'error', msg: 'Please fill in all custom client fields' });
      return;
    }

    const payload = {
      id: formValues.id,
      quote_number: formValues.quoteNumber,
      quoted_date: formValues.quotedDate,
      taxCode: toggleCustomClient ? null : formValues.taxCode,
      client: toggleCustomClient ? null : formValues.client,
      potential_client: toggleCustomClient
        ? {
            client_name: formValues.potentialClient?.clientName,
            client_address: formValues.potentialClient?.clientAddress,
          }
        : null,
      quotation_prepared_by: {
        user_id: formValues.quotationPreparedBy.userId,
        full_name: formValues.quotationPreparedBy.fullName,
        username: formValues.quotationPreparedBy.username,
        user_role: formValues.quotationPreparedBy.userRole,
      },
      quotes_meta_data: formValues.quotesMetaData
        ? {
            attn: formValues.quotesMetaData.attn,
            scope: formValues.quotesMetaData.scope,
            required: formValues.quotesMetaData.required,
            deliverables: formValues.quotesMetaData.deliverables,
            personnel: formValues.quotesMetaData.personnel,
            resources: formValues.quotesMetaData.resources,
            schedule: formValues.quotesMetaData.schedule,
            taxCode: toggleCustomClient ? null : formValues.quotesMetaData.taxCode,
          }
        : null,
      status: formValues.status,
      expiration_date: formValues.expirationDate,
      line_headers: convertLineHeadersToCamelCase(formValues.lineHeaders),
    };

    const result = await updateQuote(payload);

    if ('error' in result) {
      setMessage({ type: 'error', msg: 'Error Saving quote' });
    }

    if ('data' in result) {
      navigate(`/viewquote/${quoteData?.id}`, {
        state: { type: 'success', msg: 'Quote Saved succesfully' },
      });
    }
  };

  const handleSetStatus = (status: string) => {
    setFormValues((prevState) => {
      return { ...prevState, status: status };
    });
  };

  const handleCloseSnackbar = () => {
    setMessage({ type: '', msg: '' });
  };

  const ButtonGroup = () => {
    return (
      <>
        <Box
          sx={{ display: 'flex', justifyContent: 'flex-end', gap: theme.spacing(1), paddingRight: theme.spacing(1) }}>
          <CustomButton
            variant="contained_grey"
            text="New Line Header"
            onClick={() => createNewLineHeader()}
            width="medium"
            toolTip="Add New Line Header"
          />
        </Box>
      </>
    );
  };

  const RenderHeader = () => {
    return (
      <>
        <QuoteHeader>
          <HeaderContent>
            <LogoContainer>
              <img style={{ objectFit: 'contain', width: '100%' }} src={eeaLogo} alt="Element Logo" />
            </LogoContainer>
            <BusinessDetails>
              <Typography variant="h4">Quote</Typography>
              <Typography variant="body1">{companyDetails.company_name}</Typography>
            </BusinessDetails>
          </HeaderContent>
          <ContactDetails>
            <BusinessDetails>
              <Typography variant="body1">{companyDetails.company_abn}</Typography>
              <Typography variant="body1">{companyDetails.company_phone}</Typography>
              <Typography variant="body1">{companyDetails.company_email}</Typography>
            </BusinessDetails>
            <BusinessDetails>
              <Typography variant="body2">ABN</Typography>
              <Typography variant="body2">PHONE</Typography>
              <Typography variant="body2">EMAIL</Typography>
            </BusinessDetails>
          </ContactDetails>
        </QuoteHeader>
      </>
    );
  };

  const RenderClientForm = () => {
    const GstSwitch = () => {
      return (
        <Box>
          <Typography textAlign={'left'} variant="body2">
            TAX CODE
          </Typography>
          <ToggleButtonPanel value={toggleGst} exclusive onChange={handleGstToggle}>
            <ToggleButton
              sx={{
                width: !toggleCustomClient ? '100%' : '50%',
                cursor: !toggleCustomClient ? 'default' : 'pointer',
              }}
              value="GST">
              <Typography
                sx={{
                  fontSize: '11px',
                  color: toggleGst === 'GST' ? theme.palette.primary.light : theme.palette.background.paper,
                }}
                variant="body2">
                {!toggleCustomClient ? clientObj?.tax_code.code : 'GST'}
              </Typography>
            </ToggleButton>
            {toggleCustomClient && (
              <ToggleButton value="FRE">
                <Typography
                  sx={{
                    fontSize: '11px',
                    color: toggleGst === 'FRE' ? theme.palette.primary.light : theme.palette.background.paper,
                  }}
                  variant="body2">
                  FRE
                </Typography>
              </ToggleButton>
            )}
          </ToggleButtonPanel>
        </Box>
      );
    };
    return (
      <>
        <FormControl>
          <FormContent>
            <TextFieldBox
              label="Quote Number"
              placeholderText={false}
              purpose="disabled"
              onChangeFn={handleSetFormValues}
              formKey="quoteNumber"
              value={formValues.quoteNumber}
            />
            <Box>
              <Typography textAlign={'left'} variant="body2">
                QUOTE DATE
              </Typography>
              <BasicDatePicker allowMaxDate onChange={handleQuoteDateChange} value={dayjs(formValues.quotedDate)} />
            </Box>
            <Box>
              <Typography textAlign={'left'} variant="body2">
                EXPIRATION
              </Typography>
              <BasicDatePicker
                allowMaxDate
                onChange={handleExpirationDateChange}
                value={dayjs(formValues.expirationDate)}
              />
            </Box>
            {GstSwitch()}
          </FormContent>
          <FormContentReverse>
            <CustomButton
              variant="contained_grey"
              text={toggleCustomClient ? 'Select Existing Client' : 'Custom Client Details'}
              onClick={() => handleToggleCustomClient()}
              width="100%"
              customSx={{ marginTop: theme.spacing(2), alignSelf: 'center' }}
            />
            {!toggleCustomClient && (
              <Box>
                <Typography textAlign={'left'} variant="body2">
                  CLIENT
                </Typography>
                <Autocomplete
                  size="small"
                  value={clientObj}
                  options={clients.map(({ client_name, client_id }) => {
                    return { client_name, client_id };
                  })}
                  getOptionLabel={(option) => option.client_name || ''}
                  isOptionEqualToValue={(option, value) => option.client_id === value?.client_id}
                  onChange={(event, value) => handleClientChange(value?.client_id || null)}
                  ListboxProps={{
                    style: {
                      fontSize: '10px',
                      textAlign: 'left',
                      padding: '0',
                    },
                  }}
                  renderInput={(params) => <TextField {...params} required sx={{ direction: 'ltr' }} />}
                />
              </Box>
            )}
            {toggleCustomClient && (
              <TextFieldBox
                required
                label="Client Name"
                placeholderText={false}
                purpose=""
                onChangeFn={handleSetCustomClient}
                formKey="clientName"
                value={formValues.potentialClient?.clientName || ''}
              />
            )}
            <TextFieldBox
              label="attn"
              placeholderText={false}
              purpose=""
              onChangeFn={handleSetMetaData}
              formKey="attn"
              value={formValues.quotesMetaData?.attn || ''}
            />
            {toggleCustomClient && (
              <TextFieldBox
                required
                label="Address"
                placeholderText={false}
                purpose=""
                onChangeFn={handleSetCustomClient}
                formKey="clientAddress"
                value={formValues.potentialClient?.clientAddress || ''}
              />
            )}
          </FormContentReverse>
        </FormControl>
      </>
    );
  };

  const RenderActivityForm = () => {
    const tableFields = ['UNITS', 'DESCRIPTION', 'RATE/COST', 'DISCOUNT', 'LINE TOTAL'];

    return (
      <>
        <ActivityFormContainer>
          <TableContainer>
            <Table>
              <colgroup>
                <col width="10%" />
                <col width="35%" />
                <col width="20%" />
                <col width="12.5%" />
                <col width="12.5%" />
                <col width="5%" />
              </colgroup>
              <TableHead sx={{ lineHeight: '20px' }}>
                <TableRow>
                  {tableFields.map((field) => {
                    return (
                      <TableCell sx={{ padding: '8px' }} key={field}>
                        {field}
                      </TableCell>
                    );
                  })}
                  <TableCell></TableCell>
                </TableRow>
              </TableHead>
              {formValues.lineHeaders.map((lineHeader, idx) => {
                return (
                  <NewLineHeader
                    key={idx}
                    setFormValues={handleSetFormValues}
                    lineHeaderIdx={idx}
                    lineHeader={lineHeader}
                    handleDeleteLine={handleDeleteLine}
                    handleDuplicateLineHeader={handleDuplicateLineHeader}
                    handleDeleteLineHeader={handleDeleteLineHeader}
                    setMessage={setMessage}
                  />
                );
              })}
            </Table>
          </TableContainer>
          <NewLineBtnGroup>{ButtonGroup()}</NewLineBtnGroup>
        </ActivityFormContainer>
      </>
    );
  };

  const RenderFooter = (docType?: string) => {
    const getGstValue = () => {
      if (clientObj && !toggleCustomClient) {
        return Number(clientObj.tax_code.rate) / 100;
      }
      if (toggleCustomClient && toggleGst === 'GST') {
        return 0.1;
      }
      return 1;
    };
    return (
      <>
        {docType === 'detailed' && (
          <QuoteTotalsContainer>
            <TypographyContainer>
              <Typography variant="body2">SUBTOTAL</Typography>
              <Typography variant="body1">${total.toFixed(2)}</Typography>
            </TypographyContainer>
            {toggleGst === 'GST' && (
              <TypographyContainer>
                <Typography variant="body2">GST</Typography>
                <Typography variant="body1">
                  ${clientObj ? (total * getGstValue()).toFixed(2) : (total * 0.1).toFixed(2)}
                </Typography>
              </TypographyContainer>
            )}
            <TypographyContainer>
              <Typography variant="body2">TOTAL</Typography>
              <Typography variant="body1">
                ${toggleGst === 'GST' ? (total * (1 - getGstValue())).toFixed(2) : total}
              </Typography>
            </TypographyContainer>
          </QuoteTotalsContainer>
        )}
        <Footer>
          <Box sx={{ display: 'flex', justifyContent: 'flex-start', gap: theme.spacing(2) }}>
            <TextFieldBox
              label="quotation_prepared_by"
              placeholderText={false}
              purpose=""
              onChangeFn={handleSetFormValues}
              formKey="quotationPreparedBy"
              value={formValues.quotationPreparedBy.fullName}
            />
          </Box>
          <Box>
            <Typography variant="body1">
              This quote outlines the maximum cost using information supplied at the time of quotation. Any change in
              the scope will result in a variation or will be time billed in addition to the quoted amount whereby the
              client will be informed prior. If design objectives cannot be achieved in accordance with relevant
              standards, the client will be notified and all hours utilised up to this point of discovery will be billed
              to the client. This quote is subject to Element Engineering Australias terms and conditions available upon
              request
            </Typography>
          </Box>
        </Footer>
      </>
    );
  };

  const RenderSummary = () => {
    return (
      <>
        <SummaryContainer>
          <TextFieldBox
            label={'scope'}
            placeholderText={false}
            purpose=""
            onChangeFn={handleSetMetaData}
            formKey={'scope'}
            value={formValues.quotesMetaData?.scope || ''}
          />
          <TextFieldBox
            label={'required'}
            placeholderText={false}
            purpose=""
            onChangeFn={handleSetMetaData}
            formKey={'required'}
            value={formValues.quotesMetaData?.required || ''}
          />
          <TextFieldBox
            label={'deliverables'}
            placeholderText={false}
            purpose=""
            onChangeFn={handleSetMetaData}
            formKey={'deliverables'}
            value={formValues.quotesMetaData?.deliverables || ''}
          />
          <TextFieldBox
            label={'personnel'}
            placeholderText={false}
            purpose=""
            onChangeFn={handleSetMetaData}
            formKey={'personnel'}
            value={formValues.quotesMetaData?.personnel || ''}
          />
          <TextFieldBox
            label={'resources'}
            placeholderText={false}
            purpose=""
            onChangeFn={handleSetMetaData}
            formKey={'resources'}
            value={formValues.quotesMetaData?.resources || ''}
          />
          <TextFieldBox
            label={'schedule'}
            placeholderText={false}
            purpose=""
            onChangeFn={handleSetMetaData}
            formKey={'schedule'}
            value={formValues.quotesMetaData?.schedule || ''}
          />
        </SummaryContainer>
      </>
    );
  };

  return (
    <>
      <SnackBar message={message} onClose={handleCloseSnackbar} />
      <Loader open={isFetchingData} />
      <PageContainer>
        <FilterPanelContainer>
          <Box display="flex" flexDirection="row" alignItems="center" sx={{ mb: 4, gap: theme.spacing(1) }}>
            <ArrowBackIos
              sx={{
                color: theme.palette.text.disabled,
                fontSize: '0.8rem',
                cursor: 'pointer',
              }}
              onClick={() => {
                navigate(-1);
              }}
            />
            <Typography variant="h4">Edit {formValues.quoteNumber}</Typography>
          </Box>
          <Typography variant="body2" gutterBottom>
            STATUS
          </Typography>
          <Select
            defaultValue={formValues.status}
            onChange={(newSelection) => {
              handleSetStatus(newSelection.target.value);
            }}
            inputProps={{ MenuProps: { disableScrollLock: true } }}
            sx={{
              height: '32px',
              width: '100%',
            }}
            value={formValues.status}>
            {types.map((name) => {
              return (
                <MenuItem key={name} value={name}>
                  {name}
                </MenuItem>
              );
            })}
          </Select>
          <FilterButtons ml={'auto'}>
            <CustomButton
              variant="contained_red"
              text="Delete Quote"
              onClick={() => setDeleteModalOpen(true)}
              width="medium"
            />
            <CustomButton variant="contained" text="Save Quote" onClick={() => handleSubmitQuote()} width="medium" />
          </FilterButtons>
        </FilterPanelContainer>
        <ViewQuoteContainer>
          <QuoteDocContainer>
            {RenderHeader()}
            {RenderClientForm()}
            {RenderActivityForm()}
            {RenderFooter('detailed')}
          </QuoteDocContainer>
          <QuoteDocSummaryContainer>
            {RenderHeader()}
            {RenderSummary()}
            {RenderFooter('summary')}
          </QuoteDocSummaryContainer>
        </ViewQuoteContainer>
      </PageContainer>
      {deleteModalOpen && (
        <DeleteConfirmationDialog
          title={'Confirm deletion'}
          description={`Are you sure you want to delete ${formValues.quoteNumber}?`}
          secondaryText="This action cannot be undone"
          onClose={() => setDeleteModalOpen(false)}
          onDelete={() => handleDeleteQuote()}
          buttonText={'Delete'}
        />
      )}
    </>
  );
};

export default EditQuote;
