import * as React from 'react';
import { useSelector } from 'react-redux';
import dayjs, { Dayjs } from 'dayjs';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';

import ClearIcon from '@mui/icons-material/Clear';
import CloseIcon from '@mui/icons-material/Close';
import {
  Autocomplete,
  Box,
  IconButton,
  List,
  ListItem,
  ListItemText,
  MenuItem,
  Select,
  TextField,
  Typography,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';

import CustomButton from 'components/Button';
import DatePicker from 'components/DatePicker';
import Loader from 'components/Loader';
import { MessageType } from 'components/SnackBar';
import { useUpdateJobMutation } from 'services/busmanApi';
import { ActivitiesProps, JobManagerType, JobProps } from 'types/WorkLogTypes';
import { DESCRIPTION_LIMIT } from 'utils/constants';
import { formatDate } from 'utils/date';

import { RootState } from '../../store';

import { DateBox, DateRangeBox, Header, JobSelect, JobStatusBox } from './styles';

const SearchActivitiesModal = React.lazy(() => import('../../components/SearchActivities'));

interface StateTypes {
  billingCycle: string | undefined;
  clientId: string | undefined;
  description: string | undefined;
  endDate: Dayjs | null;
  header: string | undefined;
  isActive: boolean | undefined;
  jobContactName: string | undefined;
  jobId: string | undefined;
  jobManager: JobManagerType | undefined;
  jobName: string | undefined;
  jobNumber: string | undefined;
  jobStatus: string | undefined;
  jobType: string | undefined;
  maxCost: string | undefined;
  orderNumber: string | undefined;
  primaryContact: string | undefined;
  quoteNumber: string | undefined;
  quotedHours: string | undefined;
  startDate: Dayjs | null;
}

interface EditJobProps {
  close: () => void;
  showMessage: (args: MessageType) => void;
  job: JobProps | null | undefined;
  jobManagers: Array<JobManagerType>;
}

type AllowableActivitiesType = {
  activityId: string;
  activityName: string;
};

const BILLING_CYCLES: { [key: string]: number } = { Weekly: 7, Fortnightly: 14, Monthly: 30 };

const EditJob = ({ close, job, jobManagers, showMessage }: EditJobProps) => {
  const theme = useTheme();
  const [updateJob] = useUpdateJobMutation();
  const [open, setOpen] = React.useState<boolean>(false);
  const [error, setError] = React.useState<string | null>(null);
  const [allowableActivities, setAllowableActivities] = React.useState<AllowableActivitiesType[]>([]);
  const [formValues, setFormValues] = React.useState<StateTypes>({
    billingCycle: job?.job_billing_cycle,
    clientId: job?.client.client_id,
    description: job?.job_description,
    header: job?.invoice_header,
    jobContactName: job?.job_metadata?.contact_name,
    jobId: job?.job_id,
    jobManager: job?.job_manager_data,
    jobName: job?.job_metadata?.job_name,
    jobNumber: job?.job_number,
    isActive: job?.is_active,
    jobStatus: job?.job_status,
    jobType: job?.job_type,
    endDate: dayjs(job?.end_date),
    maxCost: job?.job_metadata?.max_cost,
    orderNumber: job?.job_metadata?.order_number,
    primaryContact: job?.job_metadata?.primary_contact,
    quoteNumber: job?.job_metadata?.quote_number,
    quotedHours: job?.job_metadata?.quoted_hours,
    startDate: dayjs(job?.start_date),
  });

  const {
    billingCycle,
    clientId,
    description,
    endDate,
    header,
    isActive,
    jobContactName,
    jobId,
    jobManager,
    jobName,
    jobNumber,
    jobStatus,
    jobType,
    maxCost,
    orderNumber,
    primaryContact,
    quotedHours,
    quoteNumber,
    startDate,
  } = formValues;
  const { activities, clients } = useSelector((state: RootState) => state.worklogs);
  const clientName = clients.find((client) => client.client_id === clientId)?.client_name;

  let filteredActivities: ActivitiesProps[] = activities;

  filteredActivities = activities.filter((activity) => {
    // Filter activity thats already in allowable list
    const isNotInAllowableList = !allowableActivities.some(
      (allowedActivity: AllowableActivitiesType) => allowedActivity.activityId === activity.activity_id,
    );
    // Filter Chargeable activity to be shown for Timebilled/Quoted Jobs only
    const activityStatus = jobType === 'Internal' ? 'NonChargeable' : 'Chargeable';
    return isNotInAllowableList && activity.status === activityStatus;
  });

  const removeActivity = (id: string) => {
    const newActivities = allowableActivities.filter((activity: AllowableActivitiesType) => activity.activityId !== id);
    setAllowableActivities(newActivities);
  };

  const handleChange = (key: string, value: string | unknown) => {
    if (error) setError(null);

    setFormValues({ ...formValues, [key]: value });
  };

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    const dateIsValid = dayjs(endDate).isValid() && dayjs(startDate).isValid();

    dayjs.extend(isSameOrAfter);

    if (dateIsValid && !endDate?.isSameOrAfter(startDate)) {
      setError('Error: End date cannot be before start date !');
      return;
    }

    const jobUpdates = {
      job_id: jobId,
      job_description: description,
      is_active: isActive,
      job_status: jobStatus,
      job_type: jobType,
      end_date: dayjs(endDate).isValid() ? formatDate(endDate) : null,
      invoice_header: header,
      allowed_activities: allowableActivities?.map((activity) => activity.activityId),
      job_manager: jobManager?.user_id,
      job_billing_cycle: billingCycle || null,
      job_metadata: {
        job_name: jobName,
        primary_contact: primaryContact,
        contact_name: jobContactName,
        quote_number: quoteNumber,
        order_number: orderNumber,
        quoted_hours: quotedHours,
        max_cost: maxCost,
      },
    };

    const result = await updateJob({ clientId, jobUpdates });

    if ('error' in result) {
      setError('Error updating job!');
    }

    if ('data' in result) {
      close();
      showMessage({ type: 'success', msg: 'Job Updated' });
    }
  };

  const setEndDate = (value: Dayjs | null) => {
    if (error) setError(null);

    setFormValues({ ...formValues, endDate: value });
  };

  const onSelectActivity = (jobActivities: ActivitiesProps[]) => {
    const selectedActivities = jobActivities.map((activity) => {
      return {
        activityId: activity.activity_id,
        activityName: activity.activity_name,
      };
    });
    setAllowableActivities((prevActivities) => [...prevActivities, ...selectedActivities]);
  };

  React.useEffect(() => {
    if (job?.activities?.length) {
      const allowedActivities = job?.activities.map((jobActivity: ActivitiesProps) => {
        return { activityId: jobActivity.activity_id, activityName: jobActivity.activity_name };
      });

      if (allowedActivities.length) setAllowableActivities(allowedActivities);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Box
      component="form"
      onSubmit={handleSubmit}
      sx={{
        display: 'flex',
        flexDirection: 'column',
        boxShadow: theme.shadows[2],
        borderRadius: '5px',
        backgroundColor: theme.palette.background.paper,
        height: 'fit-content',
        maxHeight: '95vh',
        overflowY: 'auto',
        width: '500px',
        padding: theme.spacing(2),
        position: 'sticky',
        top: theme.spacing(1),
        marginRight: '10px',
        [theme.breakpoints.down('md')]: {
          position: 'fixed',
          maxWidth: '100vw',
        },
        [theme.breakpoints.down('sm')]: {
          width: 'auto',
          maxHeight: '98dvh',
          left: '10px',
        },
      }}>
      <Header>
        <Typography variant="h4"> Edit Job </Typography>
        <CloseIcon
          onClick={() => close()}
          sx={{
            color: '#9E9E9E',
            ':hover': {
              color: theme.palette.primary.main,
              cursor: 'pointer',
            },
          }}
        />
      </Header>
      <JobStatusBox>
        <JobSelect onChange={(event) => handleChange('jobType', event.target.value as string)} value={jobType}>
          <MenuItem key="Quoted" value="Quoted">
            Quoted
          </MenuItem>
          <MenuItem key="TimeBilled" value="TimeBilled">
            TimeBilled
          </MenuItem>
          <MenuItem key="Internal" value="Internal">
            Internal
          </MenuItem>
        </JobSelect>
        <JobSelect onChange={(event) => handleChange('jobStatus', event.target.value as string)} value={jobStatus}>
          <MenuItem key="inprogress" value="InProgress">
            In Progress
          </MenuItem>
          <MenuItem key="completed" value="Completed">
            Completed
          </MenuItem>
          <MenuItem key="billed" value="Billed">
            Billed
          </MenuItem>
        </JobSelect>
        <JobSelect onChange={(event) => handleChange('isActive', event.target.value as string)} value={isActive}>
          <MenuItem key="active" value={true as never}>
            Active
          </MenuItem>
          <MenuItem key="inactive" value={false as never}>
            Inactive
          </MenuItem>
        </JobSelect>
      </JobStatusBox>
      <DateRangeBox>
        <DateBox>
          <Typography variant="body2" gutterBottom>
            START DATE
          </Typography>
          <DatePicker allowMaxDate disabled value={startDate} />
        </DateBox>
        <DateBox>
          <Typography variant="body2" gutterBottom>
            END DATE
          </Typography>
          <DatePicker allowMaxDate onChange={setEndDate} value={endDate} />
        </DateBox>
      </DateRangeBox>
      <Typography variant="body2" gutterBottom sx={{ mt: 1 }}>
        CLIENT
      </Typography>
      <TextField disabled fullWidth value={clientName || clientId} name="clientName" size="small" />
      <Typography variant="body2" gutterBottom sx={{ mt: 1 }}>
        JOB NUMBER
      </Typography>
      <TextField disabled fullWidth value={jobNumber} name="jobNumber" size="small" />
      <Typography variant="body2" gutterBottom sx={{ mt: 1 }}>
        DESCRIPTION
      </Typography>
      <TextField
        value={description || ''}
        InputProps={{
          multiline: true,
          fullWidth: true,
          rows: 4,
        }}
        name="description"
        sx={{ height: '85px', mb: 2 }}
        onChange={(e) => handleChange('description', e.target.value)}
        inputProps={{ maxLength: DESCRIPTION_LIMIT }}
        helperText={description ? `${description.length}/${DESCRIPTION_LIMIT}` : `${0}/${DESCRIPTION_LIMIT}`}
        FormHelperTextProps={{ style: { color: '#D9534F', margin: 0, textAlign: 'right' } }}
      />
      <Typography variant="body2" gutterBottom sx={{ mt: 1 }}>
        INVOICE HEADER
      </Typography>
      <TextField
        value={header || ''}
        InputProps={{
          multiline: true,
          fullWidth: true,
          rows: 4,
        }}
        name="header"
        sx={{ height: '85px', mb: 2 }}
        onChange={(e) => handleChange('header', e.target.value)}
        inputProps={{ maxLength: DESCRIPTION_LIMIT }}
        helperText={header ? `${header.length}/${DESCRIPTION_LIMIT}` : `${0}/${DESCRIPTION_LIMIT}`}
        FormHelperTextProps={{ style: { color: '#D9534F', margin: 0, textAlign: 'right' } }}
      />
      <Typography variant="body2" gutterBottom sx={{ mt: 1 }}>
        JOB MANAGER
      </Typography>
      <Autocomplete
        size="small"
        value={jobManager?.full_name ? jobManager : null}
        options={jobManagers.map(({ full_name, user_id }) => {
          return { full_name, user_id };
        })}
        getOptionLabel={(option) => option?.full_name}
        isOptionEqualToValue={(option, value) => option.full_name === value.full_name}
        onChange={(event, value) => handleChange('jobManager', value)}
        ListboxProps={{
          style: {
            fontSize: '12px',
          },
        }}
        renderInput={(params) => <TextField {...params} required />}
      />
      {jobType === 'TimeBilled' && (
        <>
          <Typography
            variant="h6"
            gutterBottom
            sx={{
              borderBottom: `1px solid ${theme.palette.primary.contrastText}`,
              mt: 1,
              mb: 1,
              pt: 2,
              pb: 2,
            }}>
            Billing
          </Typography>
          <Typography variant="body2" gutterBottom sx={{ mt: 1 }}>
            BILLING PERIOD
          </Typography>
          <Select
            size="small"
            onChange={(event) => handleChange('billingCycle', event.target.value as string)}
            value={billingCycle}>
            {Object.keys(BILLING_CYCLES).map((name: string) => {
              return (
                <MenuItem key={name} value={name}>
                  {name}
                </MenuItem>
              );
            })}
          </Select>
          <Typography variant="body2" gutterBottom sx={{ mt: 1 }}>
            NO. DAYS IN BILLING CYCLE
          </Typography>
          <TextField
            fullWidth
            disabled
            value={billingCycle ? BILLING_CYCLES[billingCycle] : null}
            name="billingDays"
            size="small"
          />
        </>
      )}
      <Typography
        variant="h6"
        gutterBottom
        sx={{
          borderBottom: `1px solid ${theme.palette.primary.contrastText}`,
          mt: 1,
          mb: 1,
          pt: 2,
          pb: 2,
        }}>
        Allowable Activities
      </Typography>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'flex-end',
          mb: 2,
        }}>
        <List sx={{ width: '100%', maxHeight: '200px', overflowY: 'auto', mb: 2 }}>
          {allowableActivities?.map((activity: AllowableActivitiesType) => (
            <ListItem
              key={activity.activityId}
              sx={{
                fontSize: '12px',
                fontWeight: '500',
                height: '36px',
                bgcolor: theme.palette.background.default,
                mb: 0.5,
              }}
              secondaryAction={
                <IconButton edge="end" aria-label="delete" onClick={() => removeActivity(activity.activityId)}>
                  <ClearIcon sx={{ fontSize: 'small' }} />
                </IconButton>
              }>
              <ListItemText primary={activity.activityName} />
            </ListItem>
          ))}
        </List>
        <CustomButton variant="contained_grey" onClick={() => setOpen(true)} width="large" text="Add Activities" />
      </Box>
      <Typography
        variant="h6"
        gutterBottom
        sx={{
          borderBottom: `1px solid ${theme.palette.primary.contrastText}`,
          mb: 1,
          pt: 2,
          pb: 2,
        }}>
        Optional Metadata
      </Typography>
      <Typography variant="body2" gutterBottom sx={{ mt: 1 }}>
        EEA PRIMARY CONTACT (MUST BE A VALID USERNAME)
      </Typography>
      <TextField
        fullWidth
        value={primaryContact}
        name="EEAcontact"
        size="small"
        onChange={(e) => handleChange('primaryContact', e.target.value)}
      />
      <Typography variant="body2" gutterBottom sx={{ mt: 1 }}>
        JOB NAME
      </Typography>
      <TextField
        inputProps={{ maxLength: 50 }}
        fullWidth
        value={jobName}
        name="jobName"
        size="small"
        onChange={(e) => handleChange('jobName', e.target.value)}
      />
      <Typography variant="body2" gutterBottom sx={{ mt: 1 }}>
        JOB CONTACT NAME
      </Typography>
      <TextField
        fullWidth
        value={jobContactName}
        name="jobContactName"
        size="small"
        onChange={(e) => handleChange('jobContactName', e.target.value)}
      />
      <Typography variant="body2" gutterBottom sx={{ mt: 1 }}>
        EEA QUOTE NUMBER
      </Typography>
      <TextField
        fullWidth
        value={quoteNumber}
        name="quoteNo"
        size="small"
        onChange={(e) => handleChange('quoteNumber', e.target.value)}
      />
      <Typography variant="body2" gutterBottom sx={{ mt: 1 }}>
        PURCHASE ORDER NUMBER
      </Typography>
      <TextField
        fullWidth
        value={orderNumber}
        name="orderNumber"
        size="small"
        onChange={(e) => handleChange('orderNumber', e.target.value)}
      />
      <Typography variant="body2" gutterBottom sx={{ mt: 1 }}>
        QUOTED HOURS
      </Typography>
      <TextField
        fullWidth
        value={quotedHours}
        name="quotedHours"
        size="small"
        onChange={(e) => handleChange('quotedHours', e.target.value)}
      />
      <Typography variant="body2" gutterBottom sx={{ mt: 1 }}>
        MAXIMUM COST (EXCLUSIVE GST)
      </Typography>
      <TextField
        fullWidth
        value={maxCost}
        name="maxCost"
        size="small"
        onChange={(e) => handleChange('maxCost', e.target.value)}
      />
      {error && (
        <Typography
          variant="subtitle2_italic"
          gutterBottom
          sx={{
            color: 'red',
            mb: 1,
            width: '100%',
            textAlign: 'center',
          }}>
          {error}
        </Typography>
      )}
      <Box
        sx={{
          mt: 2,
          mb: 1,
          display: 'flex',
          flexDirection: 'row',
          alignContent: 'flex-end',
          justifyContent: 'flex-end',
        }}>
        <CustomButton variant="contained" width="large" text="Save" type="submit" />
        <React.Suspense fallback={<Loader open={true} />}>
          <SearchActivitiesModal
            activities={filteredActivities}
            open={open}
            onClose={() => setOpen(false)}
            onSelectMultiple={onSelectActivity}
          />
        </React.Suspense>
      </Box>
    </Box>
  );
};

export default EditJob;
