/* eslint-disable @typescript-eslint/no-explicit-any */
import { LineHeaderResponse } from 'services/busmanApi/types';
import { LineHeader, LineItem } from 'types/QuoteTypes';

export const instanceOfLine = (object: any): object is LineItem => {
  if (typeof object !== 'object') return false;
  return 'units' in object;
};

export const convertLineHeadersToSnakeCase = (lineHeaders: LineHeaderResponse[]) => {
  return lineHeaders.map((lineHeader) => {
    return {
      jobQuoteId: lineHeader.job_quote_id,
      description: lineHeader.description,
      lineItems: lineHeader.line_items.map((lineItem) => {
        return {
          units: lineItem.units,
          description: lineItem.description,
          activityDetail: {
            activityId: lineItem.activity_detail?.activity_id || '',
            activityName: lineItem.activity_detail?.activity_name || '',
            rate: lineItem.activity_detail?.rate || '',
          },
          discount: lineItem.discount,
          total: lineItem.total,
        };
      }),
    };
  });
};

export const convertLineHeadersToCamelCase = (lineHeaders: LineHeader[]) => {
  return lineHeaders.map((lineHeader) => {
    return {
      job_quote_id: lineHeader.jobQuoteId,
      description: lineHeader.description,
      line_items: lineHeader.lineItems.map((lineItem) => {
        return {
          units: lineItem.units,
          description: lineItem.description,
          activity_id: lineItem.activityDetail?.activityId || '',
          activity_detail: {
            activity_id: lineItem.activityDetail?.activityId || '',
            activity_name: lineItem.activityDetail?.activityName || '',
            rate: lineItem.activityDetail?.rate || '',
          },
          discount: lineItem.discount,
          total: lineItem.total,
        };
      }),
    };
  });
};

// export const convertFormValuesToSnakeCase = (values: QuoteDetailProps) => {
//   const convertKeyToSnakeCase = (key: string) => {
//     return key.replace(/([A-Z])/g, '_$1').toLowerCase();
//   };

//   const convertObjectToSnakeCase = (obj: any) => {
//     const snakeCaseObj: { [key: string]: any } = {};
//     for (const key in obj) {
//       if (Object.prototype.hasOwnProperty.call(obj, key)) {
//         const snakeKey = convertKeyToSnakeCase(key);
//         const value = obj[key];
//         if (Array.isArray(value)) {
//           snakeCaseObj[snakeKey] = value.map((item: any) => {
//             if (typeof item === 'object' && item !== null) {
//               return convertObjectToSnakeCase(item);
//             } else {
//               return item;
//             }
//           });
//         } else if (typeof value === 'object' && value !== null) {
//           snakeCaseObj[snakeKey] = convertObjectToSnakeCase(value);
//         } else {
//           snakeCaseObj[snakeKey] = value;
//         }
//       }
//     }
//     return snakeCaseObj;
//   };

//   return convertObjectToSnakeCase(values);
// };
