import { COMPANY_ID } from 'utils/constants';

import { busmanApiSlice } from './busmanApiSlice';

export const myobEndpoints = busmanApiSlice.injectEndpoints({
  endpoints: (builder) => ({
    //MYOB refresh token before start syncing
    //To be deleted as this is going to be handled at backend
    MYOBRefreshToken: builder.query({
      query: () => '/myob/refresh-token',
      transformResponse: (response: any) => response.data,
    }),
    //MYOB Sync
    syncWorklogsFromMYOB: builder.query({
      query: () => `/myob/timebilling/activityslips/?company_id=${COMPANY_ID}`,
      transformResponse: (response: any) => response.data,
    }),
    syncActivities: builder.query({
      query: () => `/myob/timebilling/activities/?company_id=${COMPANY_ID}`,
      transformResponse: (response: any) => response.data,
    }),
    syncCompanyFiles: builder.query({
      query: () => '/myob/companyfiles/',
      transformResponse: (response: any) => response.data,
    }),
    syncCustomers: builder.query({
      query: () => `/myob/customers/?company_id=${COMPANY_ID}`,
      transformResponse: (response: any) => response.data,
    }),
    syncEmployees: builder.query({
      query: () => `/myob/employees/?company_id=${COMPANY_ID}`,
      transformResponse: (response: any) => response.data,
    }),
    syncEmployeePayroll: builder.query({
      query: () => `/myob/employees/payroll/?company_id=${COMPANY_ID}`,
      transformResponse: (response: any) => response.data,
    }),
    syncJobsFromMYOB: builder.query({
      query: () => `/myob/general/sync_jobs/?company_id=${COMPANY_ID}`,
      transformResponse: (response: any) => response.data,
    }),
    syncPayrollCategory: builder.query({
      query: () => `/myob/timebilling/payrollcategory/?company_id=${COMPANY_ID}`,
      transformResponse: (response: any) => response.data,
    }),
    syncJobsToMYOB: builder.mutation({
      query: () => {
        return {
          url: '/myob/general/sync_jobs_to_myob/',
          method: 'post',
          body: { company_id: COMPANY_ID },
        };
      },
    }),
    syncWorklogsToMYOB: builder.mutation({
      query: (body) => {
        return {
          url: '/myob/timebilling/sync_worklogs_to_myob/',
          method: 'post',
          body: { ...body, company_id: COMPANY_ID },
        };
      },
    }),
    syncTimeSheets: builder.query({
      query: ({ startDate, endDate }) =>
        `/myob/payroll/timesheets/?company_id=${COMPANY_ID}&start_date=${startDate}&end_date=${endDate}`,
      transformResponse: (response: any) => response.data,
    }),
    adjustTimeSheets: builder.query({
      query: ({ start_date, end_date, user_id }) =>
        `/myob/payroll/timesheets/adjustment/?company_id=${COMPANY_ID}&start_date=${start_date}&end_date=${end_date}&user_id=${user_id}`,
      transformResponse: (response: any) => response.data,
    }),
  }),
});

export const {
  useLazyAdjustTimeSheetsQuery,
  useLazySyncTimeSheetsQuery,
  useLazySyncActivitiesQuery,
  useLazySyncWorklogsFromMYOBQuery,
  useLazySyncCompanyFilesQuery,
  useLazySyncCustomersQuery,
  useLazySyncEmployeePayrollQuery,
  useLazySyncEmployeesQuery,
  useLazySyncJobsFromMYOBQuery,
  useLazySyncPayrollCategoryQuery,
  useLazyMYOBRefreshTokenQuery,
  useSyncJobsToMYOBMutation,
  useSyncWorklogsToMYOBMutation,
} = myobEndpoints;
