import type { BaseQueryFn, FetchArgs, FetchBaseQueryError } from '@reduxjs/toolkit/query';
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

import { authActions } from 'features/Auth/authSlice';

import { RootState } from '../../store';

import { GetActivitiesResponse, GetClientResponse } from './types';

const getUserTokenFromStorage = () => {
  if (sessionStorage.getItem('user') || localStorage.getItem('user')) {
    const userDetail = JSON.parse(sessionStorage.getItem('user') || localStorage.getItem('user') || '');

    return userDetail?.token;
  }

  return null;
};

const rtkBaseQuery = fetchBaseQuery({
  baseUrl: process.env.REACT_APP_BASE_URL,
  // prepareHeaders is used to configure the header of every request and gives access to getState which we use to include the token from the store
  prepareHeaders: (headers, { getState }) => {
    const token = (getState() as RootState).auth.token || getUserTokenFromStorage();

    if (token) {
      // include token in req header
      headers.set('Authorization', `Token ${token}`);
      return headers;
    }
  },
});

const baseQueryWithReauth: BaseQueryFn<string | FetchArgs, unknown, FetchBaseQueryError> = async (
  args,
  api,
  extraOptions,
) => {
  let result = await rtkBaseQuery(args, api, extraOptions);
  if (result.error && result.error.status === 401) {
    // try to get a new token
    const refreshResult = await rtkBaseQuery('/refresh-token/', api, extraOptions);
    if (refreshResult.data) {
      // TODO - Need to implement refresh token
      // api.dispatch(authActions.setCredentials(refreshResult));
      result = await rtkBaseQuery(args, api, extraOptions);
    } else {
      api.dispatch(authActions.resetCredentials());
    }
  }
  return result;
};

// Define a service using a base URL and expected endpoints
export const busmanApiSlice = createApi({
  reducerPath: 'busmanApi',
  baseQuery: baseQueryWithReauth,
  tagTypes: [
    'Worklog',
    'Productivity',
    'Job',
    'User',
    'Category',
    'Team',
    'JobComments',
    'LogComments',
    'FCM',
    'Notification',
    'JobWorklog',
    'Quote',
    'QuoteById',
    'WorklogCount',
  ],

  endpoints: (builder) => ({
    login: builder.mutation({
      query: (credentials) => ({
        url: '/login/',
        method: 'post',
        body: credentials,
      }),
    }),

    // Fetches all activities
    getActivities: builder.query({
      query: ({ isActive = 'true' }) => `/activities/?is_active=${isActive}`,
      transformResponse: (response: GetActivitiesResponse) => response.data,
    }),

    //Fetch all Clients
    getClients: builder.query({
      query: ({ isActive = 'true' }) => `/clients/?is_active=${isActive}`,
      transformResponse: (response: GetClientResponse) => response.data,
    }),

    // Change Password
    changePassword: builder.mutation({
      query: (data) => {
        return {
          url: 'reset-password/',
          method: 'post',
          body: data,
        };
      },
    }),

    //Request reset password link
    requestResetPassword: builder.mutation({
      query: (data) => {
        return {
          url: '/request-reset-password/',
          method: 'post',
          body: data,
        };
      },
    }),

    //Reset User Password
    resetPassword: builder.mutation({
      query: ({ userId, token, newPass }) => {
        return {
          url: `password-reset-complete/${userId}/${token}/`,
          method: 'post',
          body: newPass,
        };
      },
    }),
  }),
});

// Export hooks for usage in functional components
export const {
  useGetActivitiesQuery,
  useGetClientsQuery,
  useLoginMutation,
  useChangePasswordMutation,
  useRequestResetPasswordMutation,
  useResetPasswordMutation,
} = busmanApiSlice;
