import React from 'react';

import { Box, Button, ButtonProps, Tooltip } from '@mui/material';

type ExtendedButtonType = ButtonProps & {
  text?: string | React.ReactNode;
  width?: string;
  toolTip?: string;
  customSx?: object;
  height?: string;
};

const CustomButton = ({
  variant,
  text,
  endIcon,
  width,
  toolTip,
  disabled,
  type,
  onClick,
  height,
  customSx,
}: ExtendedButtonType) => {
  const getWidth = () => {
    switch (width) {
      case 'small':
        return '32px';
      case 'medium':
        return '132px';
      case 'large':
        return '162px';
      default:
        return width;
    }
  };

  return (
    <Box sx={{ width: width }}>
      <Tooltip
        PopperProps={{
          modifiers: [
            {
              name: 'offset',
              options: {
                offset: [0, 8],
              },
            },
          ],
        }}
        title={toolTip || ''}>
        <span>
          <Button
            disabled={disabled || false}
            type={type}
            variant={variant}
            endIcon={endIcon}
            onClick={(e) => onClick && onClick(e)}
            sx={{
              ...customSx,
              width: getWidth(),
              minWidth: getWidth(),
              height: height ? height : '32px',
              padding: '8px 8px 8px 8px',
              boxShadow: 'none',
              '@media print': {
                display: 'none',
              },
            }}>
            {text}
          </Button>
        </span>
      </Tooltip>
    </Box>
  );
};

export default CustomButton;
