import CloseIcon from '@mui/icons-material/Close';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { Box, styled, ToggleButton as MuiToggleButton, ToggleButtonGroup } from '@mui/material';
export const ViewQuoteContainer = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '16px',
  height: 'fit-content',
}));

export const QuoteDocSummaryContainer = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  backgroundColor: 'white',
  width: '950px',
  height: 'fit-content',
  marginBottom: '16px',
}));

export const QuoteDocContainer = styled(Box)(() => ({
  component: 'form',
  display: 'flex',
  flexDirection: 'column',
  backgroundColor: 'white',
  width: '950px',
  aspectRatio: '1/1.41',
}));

export const QuoteHeader = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  gap: '40px',
  borderBottom: '1px solid #F2F2F2',
  padding: '24px',
}));

export const LogoContainer = styled(Box)(() => ({
  width: '150px',
  height: '50px',
  marginBottom: '20',
}));

export const HeaderContent = styled(Box)(() => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignitems: 'center',
  width: '100%',
}));

export const ContactDetails = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'flex-end',
  justifyContent: 'flex-end',
  gap: '24px',
  width: '100%',
}));

export const FormContent = styled(Box)(() => ({
  display: 'grid',
  gridTemplateColumns: '1fr 1fr 1fr .8fr',
  gridTemplateRows: '1fr',
  gap: '24px',
  padding: '40px 24px 8px 24px',
  alignItems: 'start',
}));

export const FormContentReverse = styled(Box)(() => ({
  display: 'grid',
  gridTemplateColumns: '.8fr 1fr 1fr 1fr',
  gridTemplateRows: '1fr',
  direction: 'rtl',
  gap: '24px',
  padding: '0px 24px 40px 24px',
  alignItems: 'start',
}));

export const Footer = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  gap: '24px',
  padding: '24px',
  borderTop: '1px solid #F2F2F2',
}));

export const TypographyContainer = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-end',
}));

export const GstSwitchStyles = {
  backgroundColor: 'white',
  width: '50%',
  height: '100%',
  position: 'relative',
  top: '0',
  zIndex: '1',
  borderRadius: '2px',
  transition: ' .5s',
};

export const NewLineBtnGroup = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'flex-end',
  width: '100%',
  gap: '8px',
}));

export const ActivityFormContainer = styled(Box)(({ theme }) => ({
  paddingInline: theme.spacing(4),
  paddingTop: theme.spacing(2),
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(2),
}));

export const SummaryContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(2),
  padding: theme.spacing(4),
  width: '33%',
}));

export const QuoteTotalsContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-end',
  gap: theme.spacing(2),
  marginTop: 'auto',
  borderBottom: `1px solid ${theme.palette.background.default}`,
  padding: theme.spacing(4),
}));

export const BusinessDetails = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-end',
  gap: theme.spacing(1),
}));

export const CloseButton = styled(CloseIcon)(({ theme }) => ({
  color: theme.palette.primary.light,
  cursor: 'pointer',
  height: '24px',
  padding: '4px',
}));

export const DuplicateIcon = styled(ContentCopyIcon)(({ theme }) => ({
  color: theme.palette.primary.light,
  cursor: 'pointer',
  // fontSize: '12px',
  padding: '4px',
  height: '22px',
}));

export const ToggleButton = styled(MuiToggleButton)(({ theme }) => ({
  color: theme.palette.primary.light,
  width: '50%',
  border: 'none',
  textTransform: 'none',
  '&.Mui-selected, &.Mui-selected:hover': {
    backgroundColor: theme.palette.background.paper,
    color: theme.palette.primary.dark,
  },
  borderRadius: '2px',
  '&:hover': { backgroundColor: 'transparent', color: '#9E9E9E' },
  '&:focus': { opacity: 0.5 },
}));

export const ToggleButtonPanel = styled(ToggleButtonGroup)(({ theme }) => ({
  '& .MuiButtonBase-root.MuiToggleButton-root.Mui-selected': {
    borderRadius: '2px',
  },
  height: '36px',
  width: '100%',
  marginBottom: theme.spacing(2),
  padding: theme.spacing(0.3),
  borderRadius: '2px',
  backgroundColor: '#9E9E9E',
}));
