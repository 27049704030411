import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import dayjs from 'dayjs';
import { eeaLogo } from 'images';
import { RootState } from 'store';

import { ArrowBackIos } from '@mui/icons-material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import {
  Box,
  Chip,
  PaletteColor,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import IconButton from '@mui/material/IconButton';
import { useTheme } from '@mui/material/styles';

import CustomButton from 'components/Button';
import { FilterButtons, FilterPanelContainer } from 'components/GlobalStyles/styles';
import SnackBar, { MessageType } from 'components/SnackBar';
import NewJob from 'features/NewJob';
import { ActivityType } from 'features/NewWorklog/types';
import { BackingDataContainer } from 'pages/JobDetail/BackingDocumentation/Styles/PDFstyles';
import { useGetManagerListQuery } from 'services/busmanApi';
import { useGetQuotesByIdQuery } from 'services/busmanApi/quotesEndpoints';
import { AllowableActivitiesType, LineHeader, LineItem, QuoteDetailProps, SummaryDetails } from 'types/QuoteTypes';
import { companyDetails } from 'utils/constants';
import { convertLineHeadersToSnakeCase } from 'utils/quotes';

import {
  ContactDetails,
  Footer,
  HeaderContent,
  LogoContainer,
  QuoteDocMain,
  QuoteDocSummaryContainer,
  QuoteHeader,
  tableCell,
  TypographyContainer,
} from './styles';

const DEFAULT_VALUES = {
  id: '',
  quoteNumber: '',
  quotedDate: dayjs().format('DD/MM/YYYY'),
  taxCode: '',
  client: null,
  potentialClient: null,
  quotationPreparedBy: {
    userId: '',
    fullName: '',
    username: '',
    userRole: '',
  },
  quotesMetaData: null,
  status: 'Pending',
  createdAt: '',
  expirationDate: dayjs().add(1, 'month').format('DD/MM/YYYY'),
  updatedAt: '',
  lineHeaders: [{ jobQuoteId: '', description: '', lineItems: [] }],
};

const ViewQuote = () => {
  const { quoteId } = useParams();
  const navigate = useNavigate();
  const theme = useTheme();
  const { state: snackBarMessage } = useLocation();
  const [formValues, setFormValues] = useState<QuoteDetailProps>(DEFAULT_VALUES);
  const [jobModalOpen, setJobModalOpen] = useState<boolean>(false);
  const [isOpen, setIsOpen] = useState(true);
  const [message, setMessage] = React.useState<MessageType | null>(snackBarMessage || null);

  const { clients } = useSelector((state: RootState) => state.worklogs);
  const { activities } = useSelector((state: RootState) => state.worklogs);
  const { data: jobManagers } = useGetManagerListQuery({});
  const { data: quoteData } = useGetQuotesByIdQuery(quoteId);
  const GST = 0.1;
  const clientObj = formValues.client ? clients.find((client) => client.client_id === formValues.client) : null;
  const isGST = clientObj?.tax_code.code === 'GST';

  if (snackBarMessage) {
    // eslint-disable-next-line no-restricted-globals
    history.replaceState({}, '');
  }

  React.useEffect(() => {
    if (quoteData) {
      setFormValues({
        ...formValues,
        id: quoteData.id,
        status: quoteData.status,
        quoteNumber: quoteData.quote_number,
        quotedDate: dayjs(quoteData.quoted_date).format('DD-MM-YYYY'),
        taxCode: clientObj?.tax_code.tax_id || null,
        client: quoteData.client,
        potentialClient: quoteData.potential_client && {
          clientName: quoteData.potential_client?.client_name || undefined,
          clientAddress: quoteData.potential_client?.client_address || undefined,
        },
        quotationPreparedBy: {
          userId: quoteData.quotation_prepared_by.user_id,
          fullName: quoteData.quotation_prepared_by.full_name,
          username: quoteData.quotation_prepared_by.username,
          userRole: quoteData.quotation_prepared_by.user_role,
        },
        quotesMetaData: quoteData.quotes_meta_data,
        createdAt: quoteData.created_at,
        expirationDate:
          dayjs(quoteData.expiration_date).format('DD-MM-YYYY') || dayjs().add(1, 'month').format('YYYY-MM-DD'),
        updatedAt: quoteData.updated_at,
        lineHeaders:
          quoteData.line_headers.length > 0
            ? [...convertLineHeadersToSnakeCase(quoteData.line_headers)]
            : [...formValues.lineHeaders],
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [quoteData]);

  const getUniqueActivities = () => {
    const uniqueActivityIdsSet = new Set();
    const uniqueActivities: AllowableActivitiesType[] = [];
    formValues.lineHeaders.map((lineHeader) => {
      return lineHeader.lineItems.forEach((line) => {
        // eslint-disable-next-line @typescript-eslint/naming-convention
        const activityObj: ActivityType | undefined = activities.find(
          (activity) => activity.activity_id === line.activityDetail?.activityId,
        );

        if (activityObj && !uniqueActivityIdsSet.has(activityObj.activity_id)) {
          uniqueActivityIdsSet.add(activityObj.activity_id);
          uniqueActivities.push({ activityId: activityObj.activity_id, activityName: activityObj.activity_name });
        }
      });
    });
    return uniqueActivities;
  };

  const prefillData = {
    client: { client_name: clientObj?.client_name || '', client_id: clientObj?.client_id || '' },
    allowableActivities: getUniqueActivities(),
    quoteNumber: formValues.quoteNumber,
    jobContactName: formValues.quotesMetaData?.attn || '',
    quotedHours: formValues.lineHeaders.reduce((acc, lineHeader) => {
      lineHeader.lineItems.forEach((line) => {
        acc += Number(line.units);
      });
      return acc;
    }, 0),
  };

  const calculateLineTotal = (lineItem: LineItem) => {
    const lineRate = lineItem.activityDetail?.rate || '0';
    return lineItem.units * Number(lineRate) - lineItem.discount;
  };

  const calculateLineHeaderTotal = (lineHeader: LineHeader) => {
    let total = 0;
    lineHeader.lineItems.forEach((line) => {
      total += calculateLineTotal(line);
    });

    return total.toFixed(2);
  };

  const calculateTotal = (lineHeaders: LineHeader[]) => {
    let total = 0;

    lineHeaders.forEach((lineHeader) => {
      lineHeader.lineItems.forEach((line) => {
        total += calculateLineTotal(line);
      });
    });

    return total;
  };

  const handleCloseJobModal = () => {
    setJobModalOpen(!jobModalOpen);
  };

  const handleOpenSnackbar = (displayMessage: MessageType) => {
    setMessage(displayMessage);
  };

  const openPdf = async () => {
    window.print();
  };

  const RenderHeader = () => {
    return (
      <>
        <QuoteHeader>
          <HeaderContent>
            <LogoContainer>
              <img style={{ objectFit: 'contain', width: '100%' }} src={eeaLogo} alt="Element Logo" />
            </LogoContainer>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'flex-end',
                gap: theme.spacing(1),
              }}>
              <Typography sx={{ fontWeight: 'bold' }} variant="h4">
                Quote
              </Typography>
              <Typography variant="subtitle1">{companyDetails.company_name}</Typography>
            </Box>
          </HeaderContent>
          <ContactDetails>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'flex-end',
                gap: theme.spacing(1),
              }}>
              <Typography variant="body1">{companyDetails.company_abn}</Typography>
              <Typography variant="body1">{companyDetails.company_phone}</Typography>
              <Typography variant="body1">{companyDetails.company_email}</Typography>
            </Box>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'flex-end',
                gap: theme.spacing(1),
              }}>
              <Typography variant="body2">ABN</Typography>
              <Typography variant="body2">PHONE</Typography>
              <Typography variant="body2">EMAIL</Typography>
            </Box>
          </ContactDetails>
          <HeaderContent>
            <Box sx={{ display: 'flex', flexDirection: 'column', width: '25%' }}>
              <Typography variant="body2">ATTN</Typography>
              <Typography sx={{ marginBottom: theme.spacing(2) }} variant="body1">
                {formValues.quotesMetaData?.attn}
              </Typography>
              <Typography variant="body2">CLIENT</Typography>
              <Typography variant="body1">
                {clientObj?.client_name || formValues.potentialClient?.clientName}
              </Typography>
              <Typography variant="body1">
                {clientObj?.address.street || formValues.potentialClient?.clientAddress}
              </Typography>
              {formValues.client && (
                <Typography variant="body1">
                  {clientObj?.address.city} {clientObj?.address.postal_code} {clientObj?.address.state}
                </Typography>
              )}
            </Box>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'flex-end',
                gap: theme.spacing(2),
              }}>
              <TypographyContainer>
                <Typography variant="body2">QUOTE NUMBER</Typography>
                <Typography variant="body1">{formValues.quoteNumber}</Typography>
              </TypographyContainer>
              <TypographyContainer>
                <Typography variant="body2">QUOTE DATE</Typography>
                <Typography variant="body1">{formValues.quotedDate}</Typography>
              </TypographyContainer>
              <TypographyContainer>
                <Typography variant="body2">QUOTE EXPIRATION</Typography>
                <Typography variant="body1">
                  {formValues.expirationDate !== 'Invalid Date' ? formValues.expirationDate : ''}
                </Typography>
              </TypographyContainer>
              <TypographyContainer>
                <Typography variant="body2">TAX CODE</Typography>
                <Typography variant="body1">
                  {clientObj?.tax_code.code || quoteData?.quotes_meta_data?.tax_code}
                </Typography>
              </TypographyContainer>
            </Box>
          </HeaderContent>
        </QuoteHeader>
      </>
    );
  };

  const RenderTable = () => {
    const tableFields = ['UNITS', 'DESCRIPTION', 'RATE/COST', 'DISCOUNT', 'LINE TOTAL'];

    return (
      <Box
        sx={{
          paddingLeft: theme.spacing(4),
          paddingRight: theme.spacing(4),
          paddingTop: theme.spacing(2),
          display: 'flex',
          flexDirection: 'column',
          gap: theme.spacing(2),
        }}>
        <TableContainer>
          <Table>
            <colgroup>
              <col width="10%" />
              <col width="35%" />
              <col width="25%" />
              <col width="12.5%" />
              <col width="12.5%" />
              <col width="5%" />
            </colgroup>
            <TableHead>
              <TableRow>
                {tableFields.map((field) => {
                  return field === 'LINE TOTAL' ? (
                    <TableCell align="right" key={field}>
                      {field}
                    </TableCell>
                  ) : (
                    <TableCell key={field}>{field}</TableCell>
                  );
                })}
              </TableRow>
            </TableHead>
            {formValues.lineHeaders.map((lineheader, idx) => {
              return (
                <TableBody
                  key={idx}
                  sx={{
                    borderLeft: `1px solid ${theme.palette.background.default}`,
                    borderRight: `1px solid ${theme.palette.background.default}`,
                    borderBottom: `1px solid ${theme.palette.background.default}`,
                    padding: theme.spacing(4),
                    pageBreakInside: 'avoid',
                  }}>
                  <TableRow>
                    <TableCell sx={tableCell}>
                      <IconButton
                        sx={{
                          '@media print': {
                            display: 'none',
                          },
                        }}
                        aria-label="expand row"
                        size="small"
                        onClick={() => setIsOpen(!isOpen)}>
                        {isOpen ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                      </IconButton>
                    </TableCell>
                    <TableCell sx={{ ...tableCell, width: '40%', fontWeight: 'bold' }}>
                      {lineheader.description}
                    </TableCell>
                    <TableCell sx={tableCell}></TableCell>
                    <TableCell sx={tableCell}></TableCell>
                    <TableCell sx={{ ...tableCell, textAlign: 'right' }}>
                      ${calculateLineHeaderTotal(lineheader)}
                    </TableCell>
                  </TableRow>
                  {isOpen &&
                    lineheader.lineItems.map((line) => {
                      return (
                        <TableRow key={line.description}>
                          <TableCell sx={tableCell}>{line.units}</TableCell>
                          <TableCell sx={tableCell}>{line.description}</TableCell>
                          <TableCell sx={tableCell}>
                            <Typography sx={{ ...tableCell, paddingTop: 0, paddingBottom: 0 }}>
                              ${line.activityDetail?.rate}
                            </Typography>
                            <Typography sx={{ ...tableCell, paddingTop: 0, paddingBottom: 0 }}>
                              {line?.activityDetail?.activityName}
                            </Typography>
                          </TableCell>
                          <TableCell sx={tableCell}>${line.discount}</TableCell>
                          <TableCell sx={{ ...tableCell, textAlign: 'right' }}>
                            ${calculateLineTotal(line).toFixed(2)}
                          </TableCell>
                        </TableRow>
                      );
                    })}
                </TableBody>
              );
            })}
          </Table>
        </TableContainer>
        <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
          <CustomButton
            onClick={() => {
              setIsOpen(!isOpen);
            }}
            text={isOpen ? 'Collapse All' : 'Expand All'}
            variant="contained_grey"
            width="medium"
          />
        </Box>
      </Box>
    );
  };

  const RenderFooter = (docType: string) => {
    const quoteSubTotal = calculateTotal(formValues.lineHeaders);
    const quoteTotal = isGST ? (quoteSubTotal * (1 - GST)).toFixed(2) : quoteSubTotal.toFixed(2);
    const quoteTax = (quoteSubTotal * GST).toFixed(2);
    return (
      <Box sx={{ marginTop: 'auto' }}>
        {docType === 'detailed' && (
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'flex-end',
              gap: theme.spacing(2),
              marginTop: 'auto',
              padding: theme.spacing(4),
            }}>
            <TypographyContainer>
              <Typography variant="body2">SUBTOTAL</Typography>
              <Typography variant="body1">${quoteSubTotal}</Typography>
            </TypographyContainer>
            {isGST && (
              <TypographyContainer>
                <Typography variant="body2">GST</Typography>
                <Typography variant="body1">${quoteTax}</Typography>
              </TypographyContainer>
            )}
            <TypographyContainer>
              <Typography variant="body2">TOTAL</Typography>
              <Typography variant="body1">${quoteTotal}</Typography>
            </TypographyContainer>
          </Box>
        )}
        <Footer>
          <Box sx={{ display: 'flex', justifyContent: 'flex-start', gap: theme.spacing(2) }}>
            <Typography variant="body2">QUOTATION PREPARED BY</Typography>
            <Typography variant="body1">{formValues.quotationPreparedBy.fullName}</Typography>
          </Box>
          <Box>
            <Typography variant="body1">
              This quote outlines the maximum cost using information supplied at the time of quotation. Any change in
              the scope will result in a variation or will be time billed in addition to the quoted amount whereby the
              client will be informed prior. If design objectives cannot be achieved in accordance with relevant
              standards, the client will be notified and all hours utilised up to this point of discovery will be billed
              to the client. This quote is subject to Element Engineering Australias terms and conditions available upon
              request
            </Typography>
          </Box>
        </Footer>
      </Box>
    );
  };

  const RenderSummary = () => {
    const summaryKeys = ['scope', 'required', 'deliverables', 'resources', 'schedule'];
    return (
      <>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: theme.spacing(2),
            padding: theme.spacing(4),
          }}>
          {summaryKeys.map((key: string) => {
            return (
              <>
                <Box key={key} sx={{ display: 'flex', flexDirection: 'row', gap: theme.spacing(2) }}>
                  <Box sx={{ width: '15%' }}>
                    <Typography variant="body2">{key.toUpperCase()}</Typography>
                  </Box>
                  {formValues.quotesMetaData && (
                    <Typography variant="body1">{formValues.quotesMetaData[key as keyof SummaryDetails]}</Typography>
                  )}
                </Box>
              </>
            );
          })}
        </Box>
      </>
    );
  };

  const FilterPanel = () => {
    const renderStatusChip = (status: string) => {
      const QUOTE_STATUS = {
        ACCEPTED: 'Accepted',
        PENDING: 'Pending',
        SUBMITTED: 'Submitted',
      };
      const chipColor = () => {
        switch (status) {
          case QUOTE_STATUS.ACCEPTED:
            return theme.palette.busmanColors.busmanGreen as PaletteColor['main'];
          case QUOTE_STATUS.PENDING:
            return theme.palette.busmanColors.lightGrey as PaletteColor['main'];
          case QUOTE_STATUS.SUBMITTED:
            return theme.palette.busmanColors.busmanPrimary as PaletteColor['main'];
        }
      };
      return (
        <Chip
          key={status}
          size="small"
          label={status}
          variant="outlined"
          sx={{
            mt: theme.spacing(3),
            mb: theme.spacing(1),
            width: 'fit-content',
            color: chipColor(),
            borderColor: chipColor(),
          }}
        />
      );
    };
    return (
      <FilterPanelContainer>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'flex-start',
            borderRadius: '2px',
            padding: theme.spacing(1),
            height: 'fit-content',
          }}>
          <Box display="flex" alignItems="center" sx={{ gap: theme.spacing(1) }}>
            <ArrowBackIos
              sx={{
                color: theme.palette.text.disabled,
                fontSize: '0.8rem',
                cursor: 'pointer',
              }}
              onClick={() => {
                navigate('/quotes');
              }}
            />
            <Typography variant="h4">{formValues.quoteNumber}</Typography>
          </Box>
        </Box>
        {renderStatusChip(formValues.status)}
        <FilterButtons>
          {formValues.status === 'Accepted' ? (
            <CustomButton onClick={handleCloseJobModal} text="Create Job" width="100%" variant="contained_purple" />
          ) : (
            <CustomButton
              onClick={() => {
                navigate(`/editquote/${formValues.id}`);
              }}
              text="Edit Quote"
              width="100%"
              variant="contained_grey"
            />
          )}
          <CustomButton
            onClick={() => {
              openPdf();
            }}
            text="Create PDF"
            width="100%"
            variant="contained"
          />
        </FilterButtons>
      </FilterPanelContainer>
    );
  };

  return (
    <>
      <Box sx={BackingDataContainer}>
        {!jobModalOpen && <FilterPanel />}
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: theme.spacing(2),
            height: 'fit-content',
            marginBottom: theme.spacing(4),
            '@media print': { gap: 0 },
          }}>
          <QuoteDocMain>
            {RenderHeader()}
            {RenderTable()}
            {RenderFooter('detailed')}
          </QuoteDocMain>
          <QuoteDocSummaryContainer>
            {RenderHeader()}
            {RenderSummary()}
            {RenderFooter('summary')}
          </QuoteDocSummaryContainer>
        </Box>
        {jobModalOpen && (
          <NewJob
            close={handleCloseJobModal}
            showMessage={handleOpenSnackbar}
            jobManagers={jobManagers || []}
            preFillData={prefillData}
          />
        )}
      </Box>
      <SnackBar message={message} onClose={() => setMessage(null)} />
    </>
  );
};

export default ViewQuote;
