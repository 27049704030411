import * as React from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { ListItem, ListItemButton, ListItemIcon, SvgIconTypeMap, Tooltip } from '@mui/material';
import { OverridableComponent } from '@mui/material/OverridableComponent';
import { useTheme } from '@mui/material/styles';

import NotificationCountBadge from 'components/NotificationsBadge';

import { RootState } from '../../store';
interface SideBarlistProps {
  active?: boolean;
  label: string;
  MenuIcon: OverridableComponent<SvgIconTypeMap<object, 'svg'>> & { muiName: string };
  handleClick: (arg: string) => void;
}

export default function SideBarList({ active = false, label = '', MenuIcon, handleClick }: SideBarlistProps) {
  const { user } = useSelector((state: RootState) => state.auth);
  const navigate = useNavigate();
  const theme = useTheme();

  const handleMenuClick = (iconLabel: string) => {
    handleClick(iconLabel);

    if (iconLabel === 'NOTIFICATION') {
      navigate('/notifications');
      return;
    }
  };

  return (
    <>
      <ListItem key={label} disablePadding sx={{ display: 'block' }}>
        {label === 'NOTIFICATION' && <NotificationCountBadge />}
        <ListItemButton
          id={`id-${label}`}
          aria-haspopup="true"
          onClick={() => handleMenuClick(label)}
          sx={{
            minHeight: 48,
            justifyContent: 'center',
            px: 2.5,
          }}>
          <ListItemIcon
            sx={{
              justifyContent: 'center',
              color: active ? theme.palette.primary.contrastText : theme.palette.primary.light,
              ':hover': {
                color: theme.palette.primary.contrastText,
              },
            }}>
            <Tooltip title={label === 'ACCOUNT' ? user?.full_name : label} placement="right">
              <MenuIcon />
            </Tooltip>
          </ListItemIcon>
        </ListItemButton>
      </ListItem>

      {/* <Drawer
        variant="temporary"
        // hideBackdrop
        sx={{
          // '& .MuiBackdrop-root': {
          backgroundColor: 'transparent',
          // },
          '& .MuiDrawer-paper': {
            backgroundColor: theme.palette.primary.main,
            height: '95vh',
            width: 'auto',
            top: theme.spacing(1),
            left: theme.spacing(9),
          },
        }}
        anchor="left"
        open={openDrawer}
        onClose={toggleDrawer(false)}>
        <DrawerHeader>
          <Typography variant="body2" sx={{ pl: theme.spacing(1) }}>
            {label}
          </Typography>
        </DrawerHeader>
        {menuList()}
      </Drawer> */}
    </>
  );
}
