import React from 'react';
import { useSelector } from 'react-redux';
import { BrowserRouter, Navigate, Outlet, Route, Routes } from 'react-router-dom';

import { useMediaQuery, useTheme } from '@mui/material';
import CssBaseline from '@mui/material/CssBaseline';
import { styled } from '@mui/material/styles';

import MobileAppBar from 'components/MobileAppBar';
import ServerError from 'components/ServerError/ServerError';
import SideBar from 'components/SideBar';
import Auth from 'features/Auth';
import Login from 'features/Auth/components/Login';
import FetchData from 'features/GlobalFetchData';
import UserDetail from 'features/UserDetail';
import ChangePassword from 'pages/ChangePassword';
import ClientDetail from 'pages/ClientDetail';
import Clients from 'pages/Clients';
import EditQuote from 'pages/EditQuote';
import EmployeeWorklogs from 'pages/EmployeeWorklogs';
import ForgotPassword from 'pages/ForgotPassword';
import HomePage from 'pages/HomePage';
import JobDetail from 'pages/JobDetail';
import BackingDoc from 'pages/JobDetail/BackingDocumentation';
import Jobs from 'pages/Jobs';
import MissingLogs from 'pages/MissingLogs';
import MYOBConfigure from 'pages/MYOBConfigure';
import NotificationsPage from 'pages/Notifications';
import Quotes from 'pages/Quotes';
import ResetPassword from 'pages/ResetPassword';
import TeamDetail from 'pages/TeamDetail';
import Teams from 'pages/Teams';
import ThemePage from 'pages/ThemePage';
import ViewQuote from 'pages/ViewQuote/ViewQuote';
import WorkLog from 'pages/WorkLog';
import WorklogDetail from 'pages/WorklogDetail';
import WorkLogManager from 'pages/WorkLogManager';

import { RootState } from './store';

const Root = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'row',
  maxWidth: '100vw',
}));

const Main = styled('main')(({ theme }) => ({
  flexGrow: 2,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  minHeight: '100vh',
  minWidth: '100vw',
  maxWidth: '100vw',
  position: 'relative',
  background: theme.palette.background.default,
  [theme.breakpoints.down('sm')]: {
    width: '98vw',
    overflowX: 'hidden',
  },
}));

const ProtectedRoute = ({ isAllowed, redirectPath }: { isAllowed: boolean; redirectPath: string }) => {
  if (!isAllowed) {
    return <Navigate to={redirectPath} replace />;
  }

  return <Outlet />;
};

function App() {
  const theme = useTheme();
  const { isAuthenticated, user } = useSelector((state: RootState) => state.auth);
  const [serverStatus, setServerStatus] = React.useState<boolean | null>(true);
  const isMobileScreen = useMediaQuery(theme.breakpoints.down('sm'));

  window.addEventListener('error', (e) => {
    if (e.message === 'ResizeObserver loop completed with undelivered notifications.') {
      const resizeObserverErrDiv = document.getElementById('webpack-dev-server-client-overlay-div');
      const resizeObserverErr = document.getElementById('webpack-dev-server-client-overlay');
      if (resizeObserverErr) {
        resizeObserverErr.setAttribute('style', 'display: none');
      }
      if (resizeObserverErrDiv) {
        resizeObserverErrDiv.setAttribute('style', 'display: none');
      }
    }
  });

  return (
    <>
      <CssBaseline />
      <BrowserRouter>
        <Root>
          {isAuthenticated && !isMobileScreen && <SideBar />}

          <Auth setServerStatus={setServerStatus}>
            <Main>
              {isAuthenticated && isMobileScreen && <MobileAppBar />}
              {!serverStatus && <ServerError />}
              {isAuthenticated && <FetchData />}
              <Routes>
                <Route path="/theme" element={<ThemePage />} />
                <Route path="/login" element={<Login setServerStatus={setServerStatus} />} />
                <Route path="/resetpassword" element={<ResetPassword />} />
                <Route path="/forgotpassword" element={<ForgotPassword />} />
                <Route element={<ProtectedRoute isAllowed={isAuthenticated} redirectPath={'/login'} />}>
                  <Route path="/" element={<HomePage />} />
                  <Route path="/changepassword" element={<ChangePassword />} />
                  <Route path="/employeeworklogs/:empID" element={<EmployeeWorklogs />} />
                  <Route path="/worklogs" element={<WorkLog />} />
                  <Route path="/worklogs/:workLogID" element={<WorklogDetail />} />
                  <Route path="/*" element={<Navigate replace to="/" />} />
                  <Route path="/notifications" element={<NotificationsPage />} />
                  {/* <Route path="/forgotpassword" element={<ForgotPassword />} /> */}
                </Route>
                <Route
                  element={
                    <ProtectedRoute
                      isAllowed={isAuthenticated && (user?.user_role === 'A' || user?.user_role === 'M')}
                      redirectPath={'/'}
                    />
                  }>
                  <Route path="/worklogmanager" element={<WorkLogManager />} />
                  <Route path="/missinglogs" element={<MissingLogs />} />
                  <Route path="/clients" element={<Clients />} />
                  <Route path="/clients/:clientID" element={<ClientDetail />} />
                  <Route path="/jobs" element={<Jobs />} />
                  <Route path="/viewquote/:quoteId" element={<ViewQuote />} />
                  <Route path="/editquote/:quoteId" element={<EditQuote />} />
                  <Route path="/jobs/:jobID" element={<JobDetail />} />
                  <Route path="/jobs/:jobID/documentation" element={<BackingDoc />} />;
                  <Route path="/teams" element={<Teams />} />
                  <Route path="/teams/:teamID" element={<TeamDetail />} />
                  <Route path="/userDetail/:empID" element={<UserDetail />} />
                  <Route path="/quotes" element={<Quotes />} />
                </Route>
                <Route
                  element={
                    <ProtectedRoute isAllowed={isAuthenticated && user?.user_role === 'A'} redirectPath={'/'} />
                  }>
                  <Route path="/myobconfigure" element={<MYOBConfigure />} />
                </Route>
              </Routes>
            </Main>
          </Auth>
        </Root>
      </BrowserRouter>
    </>
  );
}

export default App;
