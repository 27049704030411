import { busmanApiSlice } from './busmanApiSlice';
import { GetClientJobsResponse, GetCommentResponse, GetJobAcitivityBreakdownResponse, GetJobResponse } from './types';

export const jobEndpoints = busmanApiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getActivityBreakdownByJob: builder.query({
      query: ({ jobID, startDate, endDate }) =>
        `/clients/jobs/activitybreakdown?job_id=${jobID}&start_date=${startDate}&end_date=${endDate}`,
      transformResponse: (response: GetJobAcitivityBreakdownResponse) => response.data,
    }),
    getClientJobs: builder.query({
      query: ({ isActive = 'true' }) => `client/jobs/?is_active=${isActive}`,
      transformResponse: (response: GetClientJobsResponse) => response.data,
      providesTags: ['Job'],
    }),

    getJobsByClient: builder.query({
      query: (clientID) => `/client/jobs/?client_id=${clientID}&is_active=${true}`,
      transformResponse: (response: GetClientJobsResponse) => {
        return response.data;
      },
    }),

    getJobById: builder.query({
      query: (id) => `/client/jobs/${id}`,
      transformResponse: (response: GetJobResponse) => {
        return response.data;
      },
      providesTags: ['Job'],
    }),

    addJob: builder.mutation({
      query: (newJob) => ({
        url: 'client/jobs/',
        method: 'post',
        body: newJob,
      }),
      invalidatesTags: ['Job'],
    }),

    updateJob: builder.mutation({
      query: ({ clientId, jobUpdates }) => {
        return {
          url: `client/jobs/?client_id=${clientId}`,
          method: 'put',
          body: jobUpdates,
        };
      },
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        try {
          const {
            data: { data: updatedJob },
          } = await queryFulfilled;
          dispatch(
            jobEndpoints.util.updateQueryData('getClientJobs', {}, (draft) => {
              const jobToUpdateIndex = draft.findIndex((jobItem) => jobItem.job_id === updatedJob.job_id);
              if (jobToUpdateIndex !== -1) {
                draft[jobToUpdateIndex] = updatedJob;
              }
            }),
          );
        } catch (error) {
          console.log(error);
        }
      },
    }),

    getJobComments: builder.query({
      query: ({ id, page = 1, limit = 5 }) => `/clients/jobs/comments?job_id=${id}&page=${page}&limit=${limit}`,
      transformResponse: (response: GetCommentResponse) => response.data,
      providesTags: ['JobComments'],
    }),

    addJobComment: builder.mutation({
      query: (newComment) => ({
        url: 'clients/jobs/comments',
        method: 'post',
        body: newComment,
      }),
      invalidatesTags: ['JobComments'],
    }),

    deleteJobComment: builder.mutation({
      query: (id) => ({
        url: 'clients/jobs/comments',
        method: 'delete',
        body: { comment_id: id },
      }),
      invalidatesTags: ['JobComments'],
    }),

    updateJobComment: builder.mutation({
      query: (updatedComment) => {
        return {
          url: 'clients/jobs/comments',
          method: 'put',
          body: updatedComment,
        };
      },
      invalidatesTags: ['JobComments'],
    }),
  }),
});

export const {
  useAddJobCommentMutation,
  useAddJobMutation,
  useDeleteJobCommentMutation,
  useGetActivityBreakdownByJobQuery,
  useGetClientJobsQuery,
  useGetJobByIdQuery,
  useGetJobCommentsQuery,
  useUpdateJobMutation,
  useUpdateJobCommentMutation,
  useLazyGetJobsByClientQuery,
  useGetJobsByClientQuery,
} = jobEndpoints;
