import * as React from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import dayjs, { Dayjs } from 'dayjs';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';

import CloseIcon from '@mui/icons-material/Close';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import InfoRoundedIcon from '@mui/icons-material/InfoRounded';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Autocomplete,
  Box,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';

import CustomButton from 'components/Button';
import DatePicker from 'components/DatePicker';
import Loader from 'components/Loader';
import { MessageType } from 'components/SnackBar';
import TimePicker from 'components/TimePicker';
import { RecentlyEditedLogType } from 'pages/WorkLog/WorkLog';
import { useAddWorklogMutation } from 'services/busmanApi';
import { ActivitiesProps, JobProps, PayrollCategoryTypes } from 'types/WorkLogTypes';
import { CategoriseActivities } from 'utils/activities';
import { DESCRIPTION_LIMIT } from 'utils/constants';
import { CalculateTotalHours } from 'utils/date';
import { getPreferncesFromLocalStorage, setPreferencesInLocalStorage } from 'utils/localStorage';
import { sortLists } from 'utils/sort';

import { RootState } from '../../store';

import { Header, ToggleButton, ToggleButtonPanel } from './styles';
import { ActivityType, ClientOptionType, JobOptionType, StateTypes } from './types';

const SearchActivitiesModal = React.lazy(() => import('components/SearchActivities'));

const getTooltipInfo = (groupName: string) => {
  switch (groupName) {
    case 'Matched':
      return 'Matched between your allowable activities and job allowable activities';
    case 'From Job':
      return 'Activities recommended for the selected job';
    case 'Your Activities':
      return 'Select only when Matched activities are not correct for your selection';
    case 'Other Activity':
      return 'Select Other Activity';
    default:
      return '';
  }
};

interface PredefinedData {
  date?: Dayjs;
  startTime?: Dayjs;
  endTime?: Dayjs;
}

const DEFAULT_DATA = {
  activity: null,
  breakTime: '',
  client: null,
  clientJob: null,
  date: null,
  description: '',
  endTime: null,
  leaveCategory: null,
  wageCategory: null,
  startTime: null,
};
interface NewWorklogProps {
  close: () => void;
  showMessage: (args: MessageType) => void;
  reset: () => void;
  preDefinedData?: PredefinedData;
  setRecentlyEdited?: (log: RecentlyEditedLogType) => void;
}

const NewWorklog = ({ close, reset, showMessage, setRecentlyEdited, preDefinedData }: NewWorklogProps) => {
  const theme = useTheme();
  const [addWorklog, { isLoading }] = useAddWorklogMutation();
  const [alignment, setAlignment] = React.useState('Worklog');
  const [error, setError] = React.useState<string | null>(null);
  const [recentClients, setRecentClients] = React.useState<ClientOptionType[]>([]);
  const [recentJobs, setRecentJobs] = React.useState<JobOptionType[]>([]);
  const [formValues, setFormValues] = React.useState<StateTypes>(DEFAULT_DATA);
  const [open, setOpen] = React.useState<boolean>(false);
  const location = useLocation();
  const isManagerView = location.pathname.startsWith('/employeeworklogs');
  const { user } = useSelector((state: RootState) => state.auth);
  const { clients, activities, jobs } = useSelector((state: RootState) => state.worklogs);
  const { selectedEmployee } = useSelector((state: RootState) => state.employee);
  const {
    activities: userActivities,
    payroll_details: {
      employment_status: employeeStatus,
      leaves_category: leaveCategories,
      wages_category: wagesCategories,
    },
  } = isManagerView ? selectedEmployee : user;

  const activitiesList = React.useMemo(() => {
    if (formValues?.clientJob?.job_id) {
      return CategoriseActivities(
        activities,
        userActivities, // show selected employee activities in managers view
        jobs,
        formValues?.clientJob?.job_id,
      );
    }
    return [];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [jobs, userActivities, formValues.clientJob]);

  const filteredActivities = activities.filter((activity) => {
    if (!formValues?.clientJob) return activity;
    // Chargeable activity to be shown for Timebilled/Quoted Jobs
    const activityStatus = formValues.clientJob?.job_type === 'Internal' ? 'NonChargeable' : 'Chargeable';
    return activity.status === activityStatus;
  });

  const totalLoggedHours = React.useMemo(() => {
    return CalculateTotalHours(formValues?.startTime, formValues?.endTime, Number(formValues?.breakTime));
  }, [formValues?.breakTime, formValues?.endTime, formValues?.startTime]);

  const filterOptions = (options: ActivityType[]) => {
    const isInternal = formValues.clientJob?.job_type === 'Internal';
    const desiredStatus = isInternal ? 'NonChargeable' : 'Chargeable';

    return options.filter(
      (option: ActivityType) => option.activity_name === 'Select Other Activity' || option.status === desiredStatus,
    );
  };

  const clientsList = React.useMemo(() => {
    if (!clients.length) return [];

    const sortedClients: ClientOptionType[] = sortLists([...clients], 'client_name', 'client_id').map(
      ({ client_name, client_id }) => {
        return { client_name, client_id };
      },
    );
    if (recentClients.length) {
      const filteredSortedList = sortedClients.filter(
        (client) => !recentClients.some((recent) => recent.client_id === client.client_id),
      );
      return [...recentClients, ...filteredSortedList];
    }

    return sortedClients;
  }, [clients, recentClients]);

  const jobList = React.useMemo(() => {
    if (!jobs.length) return [];

    const filteredJobs =
      formValues.client !== null
        ? jobs?.filter((job: JobProps) => job.client?.client_id === formValues.client?.client_id)
        : jobs;

    const sortedJobs: JobOptionType[] = sortLists([...filteredJobs], 'job_number', 'job_id').map(
      ({ job_name, job_id, job_number, job_description, job_type, client }) => {
        return { job_name, job_id, job_number, job_description, job_type, client_id: client.client_id };
      },
    );

    if (recentJobs.length) {
      const filteredRecentJobPerClient =
        formValues.client !== null
          ? recentJobs?.filter((job: JobOptionType) => job.client_id === formValues.client?.client_id)
          : recentJobs;
      const filteredSortedList = sortedJobs.filter(
        (job) => !filteredRecentJobPerClient.some((recent) => recent.job_id === job.job_id),
      );
      return [...filteredRecentJobPerClient, ...filteredSortedList];
    }

    return sortedJobs;
  }, [formValues.client, jobs, recentJobs]);

  const getStyle = () => {
    if (formValues.activity) {
      const selectedActivity = activitiesList.find(
        (activity) => activity.activity_id === formValues.activity?.activity_id,
      );

      return selectedActivity?.groupType !== 'Matched' ? { bgcolor: theme.palette.busmanColors.busmanWarn } : {};
    }
    return { bgcolor: 'none' };
  };

  const formatDate = (value: Dayjs | null) => {
    return dayjs(value)
      .set('year', dayjs(formValues.date).get('year'))
      .set('month', dayjs(formValues.date).get('month'))
      .set('date', dayjs(formValues.date).get('date'))
      .format();
  };

  const updateRecentClientList = (selectedClient: ClientOptionType) => {
    const newRecentClients = [
      selectedClient,
      ...recentClients.filter((client) => client.client_id !== selectedClient.client_id),
    ];
    const trimmedRecentClients = newRecentClients.slice(0, 5);
    setRecentClients(trimmedRecentClients);
    setPreferencesInLocalStorage('recentClients', trimmedRecentClients);
  };

  const handleChange = (key: string, value: JobOptionType | string | number | PayrollCategoryTypes | null) => {
    return setFormValues({ ...formValues, [key]: value });
  };

  const handleAutoCompleteChange = (key: string, value: ActivityType | PayrollCategoryTypes | null) => {
    if (value && 'activity_id' in value && value?.activity_id === 'other') {
      setOpen(true);
      return;
    }
    setFormValues({ ...formValues, [key]: value });
  };

  const checkIsClientJob = (value: JobOptionType | null) => {
    return value?.job_id !== null;
  };

  const handleJobChange = (key: string, value: JobOptionType | null) => {
    // Update recent jobs
    if (value) {
      const newRecentJobs = [value, ...recentJobs.filter((job) => job.job_id !== value.job_id)];
      const trimmedRecentJobs = newRecentJobs.slice(0, 5);
      setRecentJobs(trimmedRecentJobs);
      setPreferencesInLocalStorage('recentJobs', trimmedRecentJobs);
    }

    if (formValues?.client === null && formValues?.activity === null && checkIsClientJob(value)) {
      const selectedJob = jobList.find((job) => job.job_id === value?.job_id);
      const selectedClient = clients.find((client) => client.client_id === selectedJob?.client_id);

      const clientSelection = {
        client_id: selectedClient ? selectedClient.client_id : '',
        client_name: selectedClient ? selectedClient.client_name : '',
      };

      // Update recent client list
      updateRecentClientList(clientSelection);

      return setFormValues({
        ...formValues,
        [key]: value,
        ['client' as string]: clientSelection,
      });
    }

    return setFormValues({ ...formValues, [key]: value, ['activity' as string]: null });
  };

  const handleClientChange = (key: string, value: ClientOptionType | null) => {
    setFormValues({ ...formValues, [key]: value, clientJob: null, activity: null });

    // Update recent clients list
    if (value) updateRecentClientList(value);
  };

  const handleChangeAlignment = (event: React.MouseEvent<HTMLElement>, newAlignment: string) => {
    if (newAlignment !== null) {
      setAlignment(newAlignment);
    }

    if (error) setError(null);
  };

  const hasValidDate = () => {
    if (!dayjs(formValues.date).isValid()) {
      setError('Error: Invalid Date');

      return false;
    }

    const currentDate = dayjs();
    const selectedDate = dayjs(formValues.date).format('YYYY-MM-DD');

    const diff = currentDate.diff(selectedDate, 'month');

    if (diff >= 12 || diff <= -12) {
      setError('Error: Date should be within 12 months');
      return false;
    }

    if (alignment === 'Worklog' && dayjs(formValues.date).isAfter(currentDate)) {
      setError('Error: Worklog date cannot be in the future');
      return false;
    }

    return true;
  };

  const hasValidTime = () => {
    if (!dayjs(formValues.startTime).isValid() || !dayjs(formValues.endTime).isValid()) {
      setError('Error: Invalid Time!');
      return false;
    }

    dayjs.extend(isSameOrAfter);
    if (!formValues.endTime?.isSameOrAfter(formValues.startTime)) {
      setError('Error: End time cannot be before start time !');
      return false;
    }
    return true;
  };

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    if (!hasValidDate()) return;

    if (!hasValidTime()) return;

    if (totalLoggedHours === 0) {
      setError('Error: No worklog hours has been logged !');
      return;
    }

    if (formValues.wageCategory === null) {
      setError('Error: Please select a payroll category !');
      return;
    }

    const newLogData = {
      activity_id: formValues.activity?.activity_id,
      break_time: formValues.breakTime || 0,
      client_id: formValues.client?.client_id,
      // selected employee id to be used while creating log by manager for their employee
      employee_id: !isManagerView ? user?.user_id : selectedEmployee?.user_id,
      end_time: formatDate(formValues.endTime),
      job_id: formValues.clientJob?.job_id,
      mapped_category_id:
        alignment === 'Worklog'
          ? formValues.wageCategory?.mapped_category_id
          : formValues.leaveCategory?.mapped_category_id,
      start_time: formatDate(formValues.startTime),
      worklog_date: formValues.date?.format('YYYY-MM-DD'),
      worklog_description: formValues.description,
      worklog_hours: totalLoggedHours,
      worklog_type: alignment === 'Worklog' ? 'WorkLog' : 'LeaveLog',
    };

    const result = await addWorklog(newLogData);

    if ('error' in result) {
      setError('Error adding worklog!');
    }

    if ('data' in result) {
      reset();
      close();
      if (setRecentlyEdited)
        setRecentlyEdited({ date: result.data.data.worklog_date, slip_id: result.data.data.slip_id, edited: false });
      showMessage({ type: 'success', msg: 'New WorkLog added' });
    }
  };

  const onSelectActivity = (activity: ActivitiesProps) => {
    handleAutoCompleteChange('activity', {
      activity_id: activity.activity_id,
      activity_name: activity.activity_name,
      status: activity.status,
      groupType: 'Other Activity',
    });
  };

  const setDate = (value: Dayjs | null) => {
    if (error) setError(null);

    setFormValues({ ...formValues, date: value });
  };

  const setStartTime = (value: Dayjs | null) => {
    if (error) setError(null);
    setFormValues({ ...formValues, startTime: value });
  };

  const setEndTime = (value: Dayjs | null) => {
    if (error) setError(null);
    setFormValues({ ...formValues, endTime: value });
  };

  React.useEffect(() => {
    const wageName = employeeStatus !== 'Casual' ? 'Base Salary' : 'Base Hourly';
    const wageCategory = wagesCategories.find((category: PayrollCategoryTypes) => category.category_name === wageName);
    setFormValues({
      ...formValues,
      ...preDefinedData,
      date: preDefinedData?.date || dayjs(),
      startTime: preDefinedData?.startTime || dayjs(dayjs().set('hour', 8).format('YYYY-MM-DD HH')),
      endTime: preDefinedData?.endTime || dayjs(dayjs().set('hour', 16).format('YYYY-MM-DD HH')),
      leaveCategory: leaveCategories[0],
      wageCategory: wageCategory,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [alignment, leaveCategories, wagesCategories, preDefinedData]);

  React.useEffect(() => {
    const localRecentClients: ClientOptionType[] | [] = getPreferncesFromLocalStorage('recentClients') || [];
    const localRecentJobs = getPreferncesFromLocalStorage('recentJobs') || [];

    setRecentClients(localRecentClients);
    setRecentJobs(localRecentJobs);
  }, []);

  return (
    <Box
      component="form"
      onSubmit={handleSubmit}
      sx={{
        display: 'flex',
        flexDirection: 'column',
        boxShadow: '2px 2px 6px 0px rgba(0, 0, 0, 0.10)',
        borderRadius: '5px',
        backgroundColor: theme.palette.background.paper,
        height: '95svh',
        width: '500px',
        maxWidth: '500px',
        position: 'sticky',
        top: theme.spacing(1),
        [theme.breakpoints.down('md')]: {
          position: 'fixed',
          width: '100%',
          height: 'fit-content',
        },
        [theme.breakpoints.down('sm')]: {
          marginTop: '5dvh',
          width: '92%',
          maxWidth: '100%',
          height: '90dvh',
        },
      }}>
      <Loader open={isLoading} />
      <Header>
        <Typography variant="h4">
          New {alignment} {isManagerView && `for ${selectedEmployee?.full_name}`}
        </Typography>
        <CloseIcon
          onClick={() => close()}
          sx={{
            color: '#9E9E9E',
            ':hover': {
              color: theme.palette.primary.main,
              cursor: 'pointer',
            },
          }}
        />
      </Header>
      <Box sx={{ p: 2, overflowY: 'auto' }}>
        {employeeStatus !== 'Casual' && (
          <ToggleButtonPanel value={alignment} exclusive onChange={handleChangeAlignment}>
            <ToggleButton value="Worklog">
              <Typography sx={{ fontSize: '11px' }} variant="body2">
                Worklog
              </Typography>
            </ToggleButton>
            <ToggleButton value="Leave">
              <Typography sx={{ fontSize: '11px' }} variant="body2">
                Leave
              </Typography>
            </ToggleButton>
          </ToggleButtonPanel>
        )}
        {alignment === 'Leave' && (
          <>
            <Typography variant="body2" gutterBottom>
              LEAVE TYPE
            </Typography>
            <Autocomplete
              size="small"
              value={formValues.leaveCategory}
              options={leaveCategories}
              getOptionLabel={(option: PayrollCategoryTypes) => `${option?.category_name}`}
              isOptionEqualToValue={(option, value) => option.category_name === value.category_name}
              onChange={(event, value) => handleAutoCompleteChange('leaveCategory', value)}
              ListboxProps={{
                style: {
                  fontSize: '12px',
                },
              }}
              popupIcon={<ExpandMoreIcon fontSize="small" />}
              renderInput={(params) => <TextField {...params} required />}
            />
          </>
        )}
        <Typography variant="body2" gutterBottom sx={{ mt: 1 }}>
          DATE
        </Typography>
        <DatePicker allowMaxDate={alignment === 'Leave'} onChange={setDate} value={formValues.date} />
        {alignment !== 'Leave' && (
          <>
            <Typography variant="body2" gutterBottom sx={{ mt: 1 }}>
              CLIENT
            </Typography>
            <Autocomplete
              size="small"
              value={formValues.client}
              options={clientsList}
              groupBy={(option) =>
                recentClients?.some((client) => client.client_id === option?.client_id) ? 'Recent' : 'All'
              }
              getOptionLabel={(option) => option.client_name || ''}
              isOptionEqualToValue={(option, value) => option.client_id === value?.client_id}
              onChange={(event, value) => handleClientChange('client', value || null)}
              ListboxProps={{
                style: {
                  fontSize: '12px',
                },
              }}
              popupIcon={<ExpandMoreIcon fontSize="small" />}
              renderInput={(params) => <TextField {...params} required />}
            />
            <Typography variant="body2" gutterBottom sx={{ mt: 1 }}>
              CLIENT JOB NUMBER
            </Typography>
            <Autocomplete
              size="small"
              value={formValues.clientJob}
              options={jobList}
              groupBy={(option) => (recentJobs?.some((job) => job.job_id === option?.job_id) ? 'Recent' : 'All')}
              getOptionLabel={(option) => `${option.job_number} `}
              isOptionEqualToValue={(option, value) => option.job_number === value.job_number}
              onChange={(event, value) => handleJobChange('clientJob', value || null)}
              ListboxProps={{
                style: {
                  fontSize: '12px',
                },
              }}
              popupIcon={<ExpandMoreIcon fontSize="small" />}
              renderInput={(params) => <TextField {...params} required />}
            />
            {formValues.clientJob?.job_description && (
              <Box
                sx={{
                  padding: theme.spacing(1),
                  display: 'flex',
                  alignContent: 'center',
                  borderRadius: '2px',
                  gap: '2px',
                }}>
                <InfoRoundedIcon
                  sx={{ fontSize: '16px', color: theme.palette.busmanColors.busmanPrimary, mt: '1px', opacity: 0.7 }}
                />
                <Typography variant="h6" sx={{ color: theme.palette.busmanColors.busmanPrimary, opacity: 0.7 }} m={0}>
                  <strong>{formValues.clientJob?.job_name}:</strong> {formValues.clientJob?.job_description}
                </Typography>
              </Box>
            )}
            <Typography variant="body2" gutterBottom sx={{ mt: 1 }}>
              ACTIVITY ID
            </Typography>
            <Autocomplete
              size="small"
              options={activitiesList}
              value={formValues.activity}
              filterOptions={filterOptions}
              groupBy={(option) => option.groupType}
              getOptionLabel={(option) => `${option.activity_name}`}
              isOptionEqualToValue={(option, value) => option.activity_name === value.activity_name}
              disabled={!formValues.client && !formValues.clientJob}
              onChange={(event, value) => handleAutoCompleteChange('activity', value)}
              sx={getStyle()}
              ListboxProps={{
                style: {
                  fontSize: '12px',
                  fontWeight: '500px',
                },
              }}
              popupIcon={<ExpandMoreIcon fontSize="small" />}
              renderInput={(params) => <TextField {...params} required />}
              renderOption={(props, option) => (
                <Tooltip title={getTooltipInfo(option.groupType)}>
                  <li {...props}>{option.activity_name}</li>
                </Tooltip>
              )}
            />
          </>
        )}
        <Typography variant="body2" gutterBottom sx={{ mt: 1 }}>
          DESCRIPTION
        </Typography>
        <TextField
          InputProps={{
            rows: 4,
            multiline: true,
            required: true,
            fullWidth: true,
          }}
          name="description"
          sx={{ height: '100px', width: '100%' }}
          value={formValues.description}
          inputProps={{ maxLength: DESCRIPTION_LIMIT }}
          onChange={(e) => handleChange('description', e.target.value)}
          helperText={`${formValues.description.length}/${DESCRIPTION_LIMIT}`}
          FormHelperTextProps={{ style: { color: '#D9534F', margin: 0, textAlign: 'right' } }}
        />
        <Typography variant="body2" gutterBottom sx={{ mt: 1 }}>
          START TIME
        </Typography>
        <TimePicker onChange={setStartTime} value={formValues.startTime} />
        <Typography variant="body2" gutterBottom sx={{ mt: 1 }}>
          END TIME
        </Typography>
        <TimePicker onChange={setEndTime} value={formValues.endTime} />
        {alignment !== 'Leave' && (
          <>
            <Typography variant="body2" gutterBottom sx={{ mt: 1 }}>
              BREAK(MINS)
            </Typography>
            <TextField
              fullWidth
              inputProps={{ maxLength: 4, min: 0, step: '15' }}
              name="breakTime"
              onChange={(e) => handleChange('breakTime', Number(e.target.value))}
              size="small"
              type="number"
              value={formValues.breakTime || ''}
            />
            <Accordion>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography variant="h6" gutterBottom sx={{ mt: 2, color: '#282828' }}>
                  Payroll Category
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Autocomplete
                  size="small"
                  value={formValues.wageCategory}
                  options={wagesCategories}
                  getOptionLabel={(option: PayrollCategoryTypes) => `${option?.category_name}`}
                  isOptionEqualToValue={(option, value) => option.category_name === value.category_name}
                  onChange={(event, value) => handleAutoCompleteChange('wageCategory', value)}
                  ListboxProps={{
                    style: {
                      fontSize: '12px',
                    },
                  }}
                  popupIcon={<ExpandMoreIcon fontSize="small" />}
                  renderInput={(params) => <TextField {...params} />}
                />
              </AccordionDetails>
            </Accordion>
          </>
        )}
      </Box>
      {error && (
        <Typography
          variant="subtitle2_italic"
          gutterBottom
          sx={{
            color: 'red',
            mb: 1,
            width: '100%',
            textAlign: 'center',
          }}>
          {error}
        </Typography>
      )}
      <Box
        sx={{
          mx: 1,
          p: 1.5,
          display: 'flex',
          flexDirection: 'row',
          alignContent: 'flex-end',
          justifyContent: 'flex-end',
        }}>
        {totalLoggedHours > 0 && (
          <Typography variant="h6" sx={{ flexGrow: 1 }}>{`Total Time Logged: ${totalLoggedHours} `}</Typography>
        )}
        <CustomButton type="submit" variant="contained" width="medium" text="Submit" />
      </Box>
      <React.Suspense fallback={<Loader open={true} />}>
        <SearchActivitiesModal
          activities={filteredActivities}
          multipleSelection={false}
          open={open}
          onClose={() => setOpen(false)}
          onSelect={onSelectActivity}
        />
      </React.Suspense>
    </Box>
  );
};

export default NewWorklog;
