// eslint-disable-next-line @typescript-eslint/no-explicit-any
const setPreferencesInLocalStorage = (key: string, value: any) => {
  const allPref = JSON.parse(localStorage.getItem('userPref') || '{}');
  const newObject = { ...allPref, [key]: value };
  localStorage.setItem('userPref', JSON.stringify(newObject));
};

const getPreferncesFromLocalStorage = (key?: string) => {
  const localValues = JSON.parse(localStorage.getItem('userPref') || '{}');
  if ('showGantt' in localValues && key === 'showGantt') return localValues.showGantt;
  if ('worklogOrder' in localValues && key === 'worklogOrder') return localValues.worklogOrder;
  if ('employeeWorklogOrder' in localValues && key === 'employeeWorklogOrder') return localValues.employeeWorklogOrder;
  if ('openTeam' in localValues && key === 'openTeam') return localValues.openTeam;
  if ('recentClients' in localValues && key === 'recentClients') return localValues.recentClients;
  if ('recentJobs' in localValues && key === 'recentJobs') return localValues.recentJobs;
  return null;
};

export { getPreferncesFromLocalStorage, setPreferencesInLocalStorage };
