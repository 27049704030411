import { ActivitiesProps, JobProps } from 'types/WorkLogTypes';

interface ActivityType {
  activity_id: string;
  activity_name: string;
  status: string;
  groupType: string;
}

export const CategoriseActivities = (
  activities: ActivitiesProps[],
  userActivities: ActivitiesProps[],
  jobs: JobProps[],
  jobId: string,
) => {
  const recommendedActivities: Array<ActivityType> = [];
  const fromJobActivities: Array<ActivityType> = [];
  const yourActivities: Array<ActivityType> = [];
  const jobActivities = jobs.find((job) => job.job_id === jobId)?.activities;

  jobActivities?.forEach((activity) => {
    const commonActivity = userActivities.find(
      (userActivity: ActivitiesProps) => userActivity.activity_id === activity.activity_id,
    );
    if (commonActivity) {
      recommendedActivities.push({
        activity_id: activity.activity_id,
        activity_name: activity.activity_name,
        status: activities.find((aty) => aty.activity_id === activity.activity_id)?.status || '',
        groupType: 'Matched',
      });
    } else {
      fromJobActivities.push({
        activity_id: activity.activity_id,
        activity_name: activity.activity_name,
        status: activities.find((aty) => aty.activity_id === activity.activity_id)?.status || '',
        groupType: 'From Job',
      });
    }
  });

  userActivities.forEach((activity: ActivitiesProps) => {
    if (!jobActivities?.some((jobActivity) => jobActivity.activity_id === activity.activity_id)) {
      yourActivities.push({
        activity_id: activity.activity_id,
        activity_name: activity.activity_name,
        status: activities.find((aty) => aty.activity_id === activity.activity_id)?.status || '',
        groupType: 'Your Activities',
      });
    }
  });

  return [
    ...recommendedActivities,
    ...fromJobActivities,
    ...yourActivities,
    { activity_name: 'Select Other Activity', activity_id: 'other', groupType: 'Other Activity', status: '' },
  ];
};
