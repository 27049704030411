import * as React from 'react';
import { Helmet } from 'react-helmet-async';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import AutoSizer from 'react-virtualized-auto-sizer';
import { FixedSizeList, ListChildComponentProps } from 'react-window';

import { Search } from '@mui/icons-material';
import { Box, Grid, InputAdornment, MenuItem, OutlinedInput, Select, Typography, useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';

import CustomButton from 'components/Button';
import { FilterButtons, FilterPanelContainer, ItemListContainer, PageContainer } from 'components/GlobalStyles/styles';
import GridItem from 'components/GridItem';
import Loader from 'components/Loader';
import SnackBar, { MessageType } from 'components/SnackBar';
import EditJob from 'features/EditJob';
import NewJob from 'features/NewJob';
import { useGetClientJobsQuery, useGetManagerListQuery } from 'services/busmanApi';
import { JobProps } from 'types/WorkLogTypes';

import { RootState } from '../../store';

import { Job } from './styles';

const JobsPage = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const statuses = ['All', 'InProgress', 'Completed'];
  const [addNewJob, setAddNewJob] = React.useState<boolean>(false);
  const [editJob, setEditJob] = React.useState<boolean>(false);
  const [selectedJob, setSelectedJob] = React.useState<JobProps | null>(null);
  const [inputText, setInputText] = React.useState('');
  const [message, setMessage] = React.useState<MessageType | null>(null);
  const [status, setStatus] = React.useState(statuses[0]);
  const { clients, jobs } = useSelector((state: RootState) => state.worklogs);
  const isMobileScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const { filterPanel } = useSelector((state: RootState) => state.filterPanel);

  const jobsResult = useGetClientJobsQuery({});
  const { data: jobManagers } = useGetManagerListQuery({});

  const handleCloseSnackbar = () => {
    setMessage(null);
  };

  const handleOpenSnackbar = (displayMessage: MessageType) => {
    setMessage(displayMessage);
  };

  const filteredJobs = jobs
    .filter((data) => {
      if (inputText === '') {
        return true;
      } else {
        return data.job_number.toLowerCase().includes(inputText.toLowerCase());
      }
    })
    .filter((data) => {
      if (status === statuses[0]) {
        return true;
      } else {
        return data.job_status === status;
      }
    })
    .sort((a, b) => {
      return a.job_number.localeCompare(b.job_number);
    });

  const isFetching = jobsResult.isFetching;

  const closeEditJob = () => {
    setEditJob(false);
    setSelectedJob(null);
  };

  // const onEditJob = (event: React.MouseEvent<HTMLButtonElement>, job: JobProps) => {
  //   event.stopPropagation();
  //   if (addNewJob) setAddNewJob(false);
  //   setSelectedJob(job);
  //   setEditJob(true);
  // };

  const renderJobs = () => {
    const rowRenderer = ({ index, style, data }: ListChildComponentProps<JobProps[]>) => {
      const job = data[index];
      const jobClient = clients.find((client) => client.client_id === job.client?.client_id);
      return (
        <Job
          container
          key={job.job_number}
          onClick={() => navigate(`/jobs/${job?.job_id}`)}
          style={style}
          sx={{ border: 1, borderColor: theme.palette.background.default }}>
          <GridItem title={job.job_number} seenOrIsHeading={false} breakpoint={isMobileScreen ? 6 : 3} />
          <GridItem title={jobClient?.client_name} seenOrIsHeading={false} breakpoint={isMobileScreen ? 6 : 4} />
          {!isMobileScreen && <GridItem title={job.job_description} seenOrIsHeading={false} breakpoint={5} />}
        </Job>
      );
    };

    return (
      <ItemListContainer
        sx={{
          [theme.breakpoints.down('lg')]: {
            display: addNewJob || editJob ? 'none' : '',
          },
        }}>
        <Grid container display="flex" flexDirection="row">
          <GridItem title={'JOB NUMBER'} seenOrIsHeading={true} breakpoint={isMobileScreen ? 6 : 3} />
          <GridItem title={'CLIENT'} seenOrIsHeading={true} breakpoint={isMobileScreen ? 6 : 4} />
          {!isMobileScreen && <GridItem title={'DESCRIPTION'} seenOrIsHeading={true} breakpoint={5} />}
        </Grid>
        {filteredJobs && filteredJobs.length === 0 && (
          <Typography sx={{ padding: theme.spacing(2) }} variant="h6">
            No results found...
          </Typography>
        )}
        <AutoSizer>
          {({ height, width }: { height: number; width: number }) => (
            <FixedSizeList
              height={height - 16}
              width={width}
              itemCount={filteredJobs.length}
              itemData={filteredJobs}
              itemSize={72}
              className="no-scrollbars">
              {rowRenderer}
            </FixedSizeList>
          )}
        </AutoSizer>
      </ItemListContainer>
    );
  };

  return (
    <>
      <Helmet>
        <title>BusMin</title>
        <meta name="Busmin" content="EEA-Jobs" />
      </Helmet>
      <Loader open={isFetching} />
      <PageContainer>
        <>
          {filterPanel && !addNewJob && (
            <FilterPanelContainer>
              {!isMobileScreen && (
                <Typography
                  gutterBottom
                  sx={{
                    mb: theme.spacing(2),
                  }}
                  variant="h4">
                  Jobs
                </Typography>
              )}
              <OutlinedInput
                endAdornment={
                  <InputAdornment position="end">
                    <Search />
                  </InputAdornment>
                }
                onChange={(event) => setInputText(event.target.value)}
                placeholder="Search Job Number"
                sx={{
                  height: '32px',
                  mb: theme.spacing(2),
                }}
                value={inputText}
              />
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                }}>
                <Typography
                  sx={{
                    mb: theme.spacing(0.5),
                    color: theme.palette.text.disabled,
                    [theme.breakpoints.down('md')]: {
                      mr: theme.spacing(1),
                    },
                  }}
                  variant="body2">
                  STATUS
                </Typography>
                <Select
                  onChange={(newSelection) => {
                    setStatus(newSelection.target.value);
                  }}
                  sx={{
                    height: '32px',
                  }}
                  value={status}>
                  {statuses.map((jobStatus) => {
                    return (
                      <MenuItem key={jobStatus} value={jobStatus}>
                        {jobStatus}
                      </MenuItem>
                    );
                  })}
                </Select>
              </Box>
              <FilterButtons sx={{ marginLeft: 'auto' }}>
                <CustomButton
                  variant="contained"
                  disabled={editJob}
                  onClick={() => {
                    setAddNewJob(true);
                  }}
                  width="medium"
                  text="New Job"
                />
              </FilterButtons>
            </FilterPanelContainer>
          )}
          {renderJobs()}
          {addNewJob && (
            <NewJob
              close={() => setAddNewJob(false)}
              showMessage={handleOpenSnackbar}
              jobManagers={jobManagers || []}
            />
          )}
          {editJob && (
            <EditJob
              key={selectedJob?.job_id}
              close={closeEditJob}
              job={selectedJob}
              jobManagers={jobManagers || []}
              showMessage={handleOpenSnackbar}
            />
          )}
        </>
      </PageContainer>
      <SnackBar message={message} onClose={handleCloseSnackbar} />
    </>
  );
};

export default JobsPage;
