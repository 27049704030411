import * as React from 'react';
import dayjs from 'dayjs';

import { Tooltip, Typography } from '@mui/material';

import { WorklogType } from 'types/WorkLogTypes';

import { ActivityContainer, CardContainer, CloseButton, DateTimeBox } from './styles';

interface ApproveWorkLogCardProps {
  worklog: WorklogType;
  onRemove: (workLogId: string) => void;
}

const ApproveWorkLogCard = ({ onRemove, worklog }: ApproveWorkLogCardProps) => {
  const {
    activity,
    date,
    end_time: endTime,
    payroll_category: leaveCategory,
    slip_id: slipID,
    start_time: startTime,
  } = worklog;

  return (
    <CardContainer>
      <Tooltip title="Remove" sx={{ cursor: 'pointer', position: 'absolute', right: '-7px', top: '-7px' }}>
        <CloseButton onClick={() => onRemove(slipID)} />
      </Tooltip>
      <DateTimeBox>
        <Typography variant="h6">{dayjs(date).format('D/MM/YYYY')}</Typography>
        <Typography variant="h6">
          {dayjs(startTime).format('LT')} - {dayjs(endTime).format('LT')}
        </Typography>
      </DateTimeBox>
      <ActivityContainer>
        <Typography variant="h6">{activity?.activity_name || leaveCategory?.category_name}</Typography>
      </ActivityContainer>
    </CardContainer>
  );
};

export default ApproveWorkLogCard;
