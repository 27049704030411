import dayjs, { Dayjs } from 'dayjs';

export const convertDateToMills = (givenDate: Date) => {
  return dayjs(givenDate).valueOf();
};

export const formatDate = (value: Dayjs | null) => {
  return dayjs(value)
    .set('year', dayjs(value).get('year'))
    .set('month', dayjs(value).get('month'))
    .set('date', dayjs(value).get('date'))
    .format();
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const SortWorkLogsByDescDate = (dataA: any, dataB: any) => {
  const dateA = dayjs(dataA?.start_time || dataA?.date);
  const dateB = dayjs(dataB?.start_time || dataB?.date);
  // Compare the 2 dates
  if (dateB < dateA) return -1;
  if (dateB > dateA) return 1;
  return 0;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const SortWorkLogsByAscDate = (dataA: any, dataB: any) => {
  const dateA = dayjs(dataA?.start_time || dataA?.date);
  const dateB = dayjs(dataB?.start_time || dataB?.date);
  // Compare the 2 dates
  if (dateA < dateB) return -1;
  if (dateA > dateB) return 1;
  return 0;
};

export const CalculateTotalHours = (startTime: Dayjs | null, endTime: Dayjs | null, breakTime: number) => {
  if (startTime !== null && endTime !== null) {
    if (breakTime > 0) {
      const newEndTime = endTime.subtract(breakTime, 'minute');
      return newEndTime.diff(startTime, 'hour', true);
    } else {
      return endTime.diff(startTime, 'minute') / 60;
    }
  }

  return 0;
};

export const generateDateRange = (from: Dayjs | null, to: Dayjs | null) => {
  if (from === null || to === null) return [];

  // Array to store generated dates
  const generatedDates = [];

  // Iterate through the date range and generate dates from the range
  let currentDate = from;

  while (currentDate.startOf('day').isSameOrBefore(to.startOf('day'))) {
    generatedDates.push(currentDate.format('YYYY-MM-DD'));
    currentDate = currentDate.add(1, 'day');
  }

  return generatedDates;
};
