import React from 'react';
import { Helmet } from 'react-helmet-async';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';

import { Box, Typography, useMediaQuery, useTheme } from '@mui/material';

import DataNotFound from 'components/DataNotFound';
import { PageContainer } from 'components/GlobalStyles/styles';
import Loader from 'components/Loader';
import SnackBar, { MessageType } from 'components/SnackBar';
import WorkLogCard from 'components/WorklogCard';
import EditWorklog from 'features/EditWorklog';
import { busmanApiSlice, useDeleteWorklogMutation, useGetWorklogsByIdQuery } from 'services/busmanApi';

import { Worklog } from './styles';

const WorklogDetail = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [editWorklog, setEditWorkLog] = React.useState<boolean>(false);
  const [message, setMessage] = React.useState<MessageType | null>(null);
  const isMobileScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const [deleteWorkLog] = useDeleteWorklogMutation();

  const worklogID = useParams();
  const {
    data: worklog,
    refetch,
    isFetching,
  } = useGetWorklogsByIdQuery(worklogID.workLogID, { refetchOnMountOrArgChange: true });

  const onEditWorklog = () => {
    setEditWorkLog(!editWorklog);
  };

  const onDeleteWorkLog = async (worklogId: string) => {
    const result = await deleteWorkLog(worklogId);

    if ('data' in result) {
      setMessage({ type: 'success', msg: 'WorkLog deleted' });
      //Invalidate Worklog tag to trigger getWorklogs
      dispatch(busmanApiSlice.util.invalidateTags(['Worklog']));

      navigate('/worklogs');
    }

    if ('error' in result) {
      setMessage({ type: 'error', msg: 'Error deleting worklog' });
    }
  };

  const handleCloseSnackbar = () => {
    setMessage(null);
  };

  const handleOpenSnackbar = (displayMessage: MessageType) => {
    setMessage(displayMessage);
  };

  const refetchLogs = () => {
    refetch(); // refetch individual changed log

    //Invalidate Worklog tag to trigger getWorklogs
    dispatch(busmanApiSlice.util.invalidateTags(['Worklog']));
  };

  return (
    <>
      <Helmet>
        <title>BusMin</title>
        <meta name="Busmin" content="EEA-SingleWorklog" />
      </Helmet>
      <Loader open={isFetching} />
      {worklog && (
        <PageContainer>
          <Worklog>
            {!isMobileScreen && (
              <Box sx={{ display: 'flex', justifyContent: 'flex-start', paddingX: '16px' }}>
                <Typography variant="h4" gutterBottom sx={{ mt: 2 }}>
                  {`${worklog?.employee.full_name}'s Worklog`}
                </Typography>
              </Box>
            )}
            <WorkLogCard
              worklog={worklog}
              isSelected={() => false}
              onApproveWorklog={() => true}
              onDeleteWorkLog={onDeleteWorkLog}
              onEditWorklog={onEditWorklog}
              openComment
              onUnsyncWorkLog={() => {}}
            />
          </Worklog>
          <Box>
            {editWorklog && (
              <EditWorklog
                close={onEditWorklog}
                reset={refetchLogs}
                showMessage={handleOpenSnackbar}
                worklog={worklog}
              />
            )}
          </Box>
        </PageContainer>
      )}
      {!worklog && !isFetching && <DataNotFound errorMessage="Worklog Not Found" url={'worklogs'} />}
      <SnackBar message={message} onClose={handleCloseSnackbar} />
    </>
  );
};

export default WorklogDetail;
