import * as React from 'react';
import { Helmet } from 'react-helmet-async';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import dayjs, { Dayjs } from 'dayjs';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';

import { SwapVert as SwapVertIcon, ViewStream as ViewStreamIcon } from '@mui/icons-material';
import DoneIcon from '@mui/icons-material/Done';
import { Box, MenuItem, Select, Typography, useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';

import AddLogButton from 'components/AddLogButton';
import CustomButton from 'components/Button';
import DatePicker from 'components/DatePicker';
import EmptyWorklogDayCard from 'components/EmptyWorklogCard';
import { FilterButtons, FilterPanelContainer, PageContainer, WorklogsContainer } from 'components/GlobalStyles/styles';
import Loader from 'components/Loader';
import SnackBar, { MessageType } from 'components/SnackBar';
import WorklogDayCard from 'components/WorklogDayCard';
import EditWorklog from 'features/EditWorklog';
import NewWorklog from 'features/NewWorklog';
import { useDeleteWorklogMutation, useGetWorklogsQuery } from 'services/busmanApi';
import { WorklogDayType, WorklogType } from 'types/WorkLogTypes';
import { SortWorkLogsByDescDate } from 'utils/date';
import { getPreferncesFromLocalStorage, setPreferencesInLocalStorage } from 'utils/localStorage';

import { RootState } from '../../store';

import { findMissingLogDays } from './helper';
import { NoWorklogsBox, RecentlyUpdatedWorklogsBox } from './styles';

dayjs.extend(isSameOrBefore);
export interface RecentlyEditedLogType {
  date: string;
  slip_id: string;
  edited: boolean;
}

interface PredefinedData {
  date?: Dayjs;
  startTime?: Dayjs;
  endTime?: Dayjs;
}

const WorklogPage = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const initialGantPref = getPreferncesFromLocalStorage('showGantt');
  const initialSortPref = getPreferncesFromLocalStorage('worklogOrder');
  const [addWorkLog, setAddWorkLog] = React.useState<boolean>(false);
  const [preDefinedWorklogData, setpreDefinedWorklogData] = React.useState<PredefinedData>({});
  const [editWorklog, setEditWorkLog] = React.useState<boolean>(false);
  const [recentlyEdited, setRecentlyEdited] = React.useState<RecentlyEditedLogType | null>(null);
  const [page, setPage] = React.useState<number>(1);
  const [selected, setSelected] = React.useState<WorklogType | null>(null);
  const [fromDate, setFromDate] = React.useState<Dayjs | null>(dayjs().subtract(1, 'week'));
  const [toDate, setToDate] = React.useState<Dayjs | null>(dayjs());
  const [message, setMessage] = React.useState<MessageType | null>(null);
  const [sortAscending, setSortAscending] = React.useState(initialSortPref);
  const [isGanttChartVisible, setIsGanttChartVisible] = React.useState(initialGantPref);
  const types = ['All', 'WorkLog', 'LeaveLog'];
  const [logType, setLogType] = React.useState(types[0]);
  const isMobileScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const { user } = useSelector((state: RootState) => state.auth);
  const [deleteWorkLog, { isLoading: isDeleting }] = useDeleteWorklogMutation();
  const { filterPanel } = useSelector((state: RootState) => state.filterPanel);

  const params = {
    empID: user?.user_id,
    startDate: dayjs(fromDate).format('YYYY-MM-DD'),
    endDate: dayjs(toDate).format('YYYY-MM-DD'),
    page: page,
  };
  const {
    data: { sortedWorklogs, totalPages } = { sortedWorklogs: [], totalPages: 1 },
    isFetching,
    refetch,
  } = useGetWorklogsQuery(params);

  React.useEffect(() => {
    setPreferencesInLocalStorage('showGantt', isGanttChartVisible);
    setPreferencesInLocalStorage('worklogOrder', sortAscending);
  }, [isGanttChartVisible, sortAscending]);

  const missingLogsToAdd = React.useMemo(() => {
    return findMissingLogDays(sortedWorklogs, fromDate, toDate);
  }, [fromDate, toDate, sortedWorklogs]);

  const missingLogDates = React.useMemo(() => {
    return missingLogsToAdd?.map((date) => dayjs(date).format('Do MMM')).join(', ');
  }, [missingLogsToAdd]);

  const newSortedWorklogs = React.useMemo(() => {
    let worklogs: WorklogDayType[] = [];

    if ((sortedWorklogs && sortedWorklogs.length) || missingLogsToAdd.length) {
      const missingWorklogs = missingLogsToAdd.map((date) => ({ date: date, worklogs: [] }));

      worklogs = [...sortedWorklogs, ...missingWorklogs].sort(SortWorkLogsByDescDate);

      if (logType !== 'All') {
        worklogs = worklogs
          .map((data) => ({
            date: data.date,
            worklogs: data.worklogs.filter((log) => log.worklog_type === logType),
          }))
          .filter((item) => item.worklogs.length > 0);
      }

      if (sortAscending) {
        worklogs = [...worklogs].reverse();
      }
    }

    return worklogs;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [logType, sortAscending, sortedWorklogs, missingLogsToAdd]);

  const totalWorklogs = newSortedWorklogs.flatMap((item) => item.worklogs);

  const closeEditWorklog = () => {
    setEditWorkLog(false);
    setSelected(null);
  };

  const showAddWorklog = () => {
    if (editWorklog) setEditWorkLog(false);
    setAddWorkLog(true);
  };

  const closeNewWorklogForm = () => {
    setpreDefinedWorklogData({});
    setAddWorkLog(false);
  };

  const handleCloseSnackbar = () => {
    setMessage(null);
  };

  const handleOpenSnackbar = (displayMessage: MessageType) => {
    setMessage(displayMessage);
  };

  const handleFromDateChange = (value: Dayjs | null) => {
    if (page !== 1) setPage(1);
    setFromDate(value);
  };

  const handleToDateChange = (value: Dayjs | null) => {
    if (page !== 1) setPage(1);
    setToDate(value);
  };

  const resetRefetchPage = () => {
    if (page === 1) {
      refetch();
      return;
    }
    setPage(1);
  };

  const loadMore = () => {
    if (!isFetching) setPage(page + 1);
  };

  const showEditWorklogForm = () => {
    if (addWorkLog) setAddWorkLog(false);

    setEditWorkLog(true);
  };

  const handleSetPredefinedData = (data: PredefinedData) => {
    setpreDefinedWorklogData({ ...data });
  };

  const onEditWorklog = (worklog: WorklogType) => {
    setSelected(worklog);
    showEditWorklogForm();
  };

  const toggleGanttChart = () => {
    setIsGanttChartVisible(!isGanttChartVisible);
  };

  const onDeleteWorkLog = async (worklogId: string) => {
    const result = await deleteWorkLog(worklogId);

    if ('data' in result) {
      resetRefetchPage();
      setRecentlyEdited(null);
      setMessage({ type: 'success', msg: 'WorkLog deleted' });
    }

    if ('error' in result) {
      setMessage({ type: 'error', msg: 'Error deleting worklog' });
    }
  };

  const renderWorklogDayCard = () => {
    return (
      <>
        {newSortedWorklogs?.length ? (
          newSortedWorklogs.map((dayWorklogs, index) => {
            if (dayWorklogs.worklogs.length === 0) {
              return (
                <EmptyWorklogDayCard
                  key={`${dayWorklogs.date}-${index}`}
                  data={dayWorklogs}
                  showAddWorklog={showAddWorklog}
                  setPredefinedData={handleSetPredefinedData}
                />
              );
            } else
              return (
                <WorklogDayCard
                  key={`${dayWorklogs.date}-${index}`}
                  data={dayWorklogs}
                  onEditWorklog={onEditWorklog}
                  onDeleteWorkLog={onDeleteWorkLog}
                  isGanttChartVisible={isGanttChartVisible}
                  setPredefinedData={handleSetPredefinedData}
                  showAddWorklog={showAddWorklog}
                />
              );
          })
        ) : (
          <NoWorklogsBox>
            <Typography variant="h4">
              {logType === 'WorkLog' || logType === 'All' ? 'No Worklogs' : 'No Leave Logs'}
            </Typography>
          </NoWorklogsBox>
        )}
      </>
    );
  };

  const recentlyEditedBanner = () => {
    if (recentlyEdited) {
      return (
        <RecentlyUpdatedWorklogsBox
          onClick={() => {
            navigate(`/worklogs/${recentlyEdited.slip_id}`);
          }}>
          <DoneIcon sx={{ fontSize: '18px' }} />
          {recentlyEdited.edited ? (
            <Typography variant="body1" sx={{ color: theme.palette.busmanColors.busmanGreen }}>
              Worklog edited for {dayjs(recentlyEdited?.date).format('DD/MM/YYYY')}
            </Typography>
          ) : (
            <Typography variant="body1" sx={{ color: theme.palette.busmanColors.busmanGreen }}>
              New worklog added for {dayjs(recentlyEdited?.date).format('DD/MM/YYYY')}
            </Typography>
          )}
          <Typography variant="body2" sx={{ fontWeight: 'bold', color: theme.palette.busmanColors.busmanGreen }}>
            Edit
          </Typography>
        </RecentlyUpdatedWorklogsBox>
      );
    }
  };

  return (
    <>
      <Helmet>
        <title>BusMin</title>
        <meta name="Busmin" content="EEA-Worklog" />
      </Helmet>
      <Loader open={isFetching || isDeleting} />
      <PageContainer>
        {!addWorkLog && !editWorklog && (
          <>
            {filterPanel && (
              <FilterPanelContainer>
                {!isMobileScreen && (
                  <Typography variant="h4" sx={{ mb: 2 }}>
                    My Worklogs
                  </Typography>
                )}
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                  }}>
                  <Typography variant="body2" gutterBottom>
                    FROM
                  </Typography>
                  <DatePicker allowMaxDate onChange={handleFromDateChange} value={fromDate} />
                  <Typography variant="body2" gutterBottom mt={1}>
                    TO
                  </Typography>
                  <DatePicker allowMaxDate onChange={handleToDateChange} value={toDate} />
                </Box>
                <Box sx={{ width: '100%' }}>
                  <Typography
                    gutterBottom
                    sx={{
                      color: theme.palette.text.disabled,
                      mt: 2,
                    }}
                    variant="body2">
                    LOG TYPE
                  </Typography>
                  <Select
                    onChange={(newSelection) => {
                      setLogType(newSelection.target.value);
                    }}
                    inputProps={{ MenuProps: { disableScrollLock: true } }}
                    sx={{
                      height: '32px',
                      width: '100%',
                    }}
                    value={logType}>
                    {types.map((name) => {
                      return (
                        <MenuItem key={name} value={name}>
                          {name}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </Box>
                <FilterButtons>
                  <CustomButton
                    onClick={() => setSortAscending(!sortAscending)}
                    text={sortAscending ? 'Ascending' : 'Descending'}
                    variant="contained_grey"
                    endIcon={<SwapVertIcon />}
                    width="100%"
                  />
                  <CustomButton
                    text={isGanttChartVisible ? 'Hide Gantt' : 'Show Gantt'}
                    variant="contained"
                    width="100%"
                    onClick={toggleGanttChart}
                    endIcon={<ViewStreamIcon />}
                  />
                </FilterButtons>
                {!isFetching && (
                  <Typography
                    gutterBottom
                    sx={{
                      color: missingLogDates
                        ? theme.palette.busmanColors.busmanRed
                        : theme.palette.busmanColors.busmanGreen,
                      mt: theme.spacing(2),
                    }}
                    variant={missingLogDates ? 'body2' : 'h6'}>
                    {missingLogDates
                      ? `You have missing logs on ${missingLogDates}`
                      : 'Great Job ! Your logs are up to date.'}
                  </Typography>
                )}
                {recentlyEditedBanner()}
              </FilterPanelContainer>
            )}
          </>
        )}
        <WorklogsContainer
          sx={{
            [theme.breakpoints.down('md')]: {
              display: addWorkLog || editWorklog ? 'none' : '',
            },
          }}>
          <InfiniteScroll
            dataLength={totalWorklogs.length}
            hasMore={page < totalPages}
            next={() => loadMore()}
            loader={<Loader open={isFetching} />}>
            {!isFetching && renderWorklogDayCard()}
          </InfiniteScroll>
        </WorklogsContainer>

        {addWorkLog && (
          <NewWorklog
            close={closeNewWorklogForm}
            reset={resetRefetchPage}
            showMessage={handleOpenSnackbar}
            preDefinedData={{ ...preDefinedWorklogData }}
            setRecentlyEdited={setRecentlyEdited}
          />
        )}
        {editWorklog && (
          <EditWorklog
            key={selected?.slip_id}
            close={closeEditWorklog}
            reset={resetRefetchPage}
            showMessage={handleOpenSnackbar}
            worklog={selected}
            setRecentlyEdited={setRecentlyEdited}
          />
        )}
        {!addWorkLog && !editWorklog && <AddLogButton showNewWorklog={() => setAddWorkLog(true)} />}
      </PageContainer>

      <SnackBar message={message} onClose={handleCloseSnackbar} />
    </>
  );
};

export default WorklogPage;
