import * as React from 'react';
import { useLocation } from 'react-router-dom';

import { Typography, useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';

import ApproveWorklogCard from 'components/ApproveWorklogCard';
import CustomButton from 'components/Button';
import { FilterButtons } from 'components/GlobalStyles/styles';
import { MessageType } from 'components/SnackBar';
import { Header } from 'features/UserDetail/styles';
import { useUpdateWorklogStatusMutation } from 'services/busmanApi';
import { WorklogType } from 'types/WorkLogTypes';

import { ApprovalList, ApprovalListContainer, ApproveWorklogPanel } from './styles';

interface ApproveWorklogProps {
  approvalList: WorklogType[];
  setApprovalList: (arg: Array<WorklogType>) => void;
  setShowApprovalPanel: (arg: boolean) => void;
  showMessage?: (arg: MessageType) => void;
  refetch?: () => void;
}

const ApproveWorklog = ({
  approvalList,
  setApprovalList,
  setShowApprovalPanel,
  showMessage = () => {},
  refetch = () => {},
}: ApproveWorklogProps) => {
  const theme = useTheme();
  const [error, setError] = React.useState('');
  const [updateWorklogStatus] = useUpdateWorklogStatusMutation();
  const location = useLocation();
  const isJobDetailPage = location.pathname.startsWith('/job');
  const isMobileScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const handleClearAll = () => {
    setError('');
    setApprovalList([]);
    setShowApprovalPanel(false);
  };

  const onRemoveFromList = (worklogId: string) => {
    const list = [...approvalList];
    const index = list.findIndex((worklog) => worklog.slip_id === worklogId);
    if (index !== -1) {
      list.splice(index, 1);

      if (list.length < 1) {
        setShowApprovalPanel(false);
        setError('');
      }
      setApprovalList(list);
    }
  };

  const onSubmitApproval = async () => {
    const approvalParams = approvalList.map((worklog) => {
      return {
        slip_id: worklog.slip_id,
        status: worklog.status === 'Approved' ? 'pending' : 'approved',
      };
    });

    const params = {
      jobManager: isJobDetailPage ? true : false,
      list: approvalParams,
    };

    const result = await updateWorklogStatus(params);

    if ('error' in result) {
      setError('Error updating worklog status!');
      return;
    }

    if (result.data.data?.all_status_updated) {
      setError('');
      refetch();
      showMessage({ type: 'success', msg: 'Worklogs status updated successfully' });
      setApprovalList([]);
      setShowApprovalPanel(false);
      return;
    }

    const declinedIds = result.data.data.worklog_ids
      .map(({ id, updated }: { id: string; updated: boolean }) => {
        if (!updated) {
          return id;
        }
        return null;
      })
      .filter(Boolean);

    // const declinedWorklogs = data.data.worklog_ids.filter((item) => !item.updated);
    // const newList = approvalList.map((wk) => {
    //   const newWorklog = declinedWorklogs.find((dWl) => dWl.id === wk.slip_id);
    //   return { ...wk, error: newWorklog?.message };
    // });

    const failedRequests = declinedIds.length;
    const successRequests = result.data.data.worklog_ids.length - declinedIds.length;
    const updatedApprovalList = approvalList.filter((worklog) => declinedIds.includes(worklog.slip_id));

    setApprovalList(updatedApprovalList);

    setError(`${failedRequests} worklog failed to update status`);

    if (successRequests) {
      refetch();
      showMessage({
        type: 'success',
        msg: `Only ${successRequests} worklog status updated successfully`,
      });
    }
  };

  return (
    <>
      {!isMobileScreen ? (
        <ApproveWorklogPanel>
          <Header>
            <Typography variant="h5">Marked for Approval</Typography>
          </Header>
          <ApprovalListContainer>
            {error && (
              <Typography variant="subtitle2_italic" sx={{ color: theme.palette.busmanColors.busmanRed }}>
                {error}
              </Typography>
            )}
            <ApprovalList>
              {approvalList.map((worklog) => (
                <ApproveWorklogCard key={worklog.start_time} worklog={worklog} onRemove={onRemoveFromList} />
              ))}
            </ApprovalList>
            <FilterButtons sx={{ gap: theme.spacing(1), padding: theme.spacing(2), pt: 0 }}>
              <CustomButton variant="contained" onClick={onSubmitApproval} text="Approve and Submit" width="large" />
              <CustomButton variant="contained_grey" onClick={handleClearAll} text="Clear" width="100px" />
            </FilterButtons>
          </ApprovalListContainer>
        </ApproveWorklogPanel>
      ) : (
        <FilterButtons
          sx={{
            position: 'fixed',
            bottom: theme.spacing(2),
            width: '93dvw',
            height: '48px',
            backgroundColor: theme.palette.primary.main,
            padding: theme.spacing(1),
            alignItems: 'center',
            borderRadius: '3px',
          }}>
          <CustomButton variant="contained_grey" onClick={handleClearAll} text="Clear" width="100%" />
          <CustomButton
            variant="contained"
            onClick={onSubmitApproval}
            text={`Approve and Submit (${approvalList.length})`}
            width="55dvw"
          />
        </FilterButtons>
      )}
    </>
  );
};
export default ApproveWorklog;
