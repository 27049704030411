import * as React from 'react';
import { Helmet } from 'react-helmet-async';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import dayjs, { Dayjs } from 'dayjs';

import { Edit as EditIcon, SwapVert as SwapVertIcon } from '@mui/icons-material';
import {
  Box,
  Chip,
  Modal,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
  useMediaQuery,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';

import CustomButton from 'components/Button';
import DataNotFound from 'components/DataNotFound';
import DatePicker from 'components/DatePicker';
import { Header, PageContainer } from 'components/GlobalStyles/styles';
import Loader from 'components/Loader';
import SnackBar, { MessageType } from 'components/SnackBar';
import WorkLogCard from 'components/WorklogCard';
import ApproveWorklog from 'features/ApproveWorklog';
import Comments from 'features/Comments';
import EditJob from 'features/EditJob';
import EditWorklog from 'features/EditWorklog';
import {
  useAddJobCommentMutation,
  useDeleteJobCommentMutation,
  useDeleteWorklogMutation,
  useGetActivityBreakdownByJobQuery,
  useGetClientJobsQuery,
  useGetJobCommentsQuery,
  useGetManagerListQuery,
  useGetWorklogsByJobIdQuery,
  useUnsyncWorklogMutation,
  useUpdateJobCommentMutation,
} from 'services/busmanApi';
import { JobActivityBreakdownType, WorklogType } from 'types/WorkLogTypes';
import { sortLists } from 'utils/sort';

import { RootState } from '../../store';

import {
  CustomTextField,
  DateBox,
  DateRangeBox,
  JobDetailContainer,
  modalStyles,
  Row,
  SectionHeader,
  WorklogFilter,
  WorklogsBox,
} from './styles';

const BILLING_CYCLES: { [key: string]: number } = { Weekly: 7, Fortnightly: 14, Monthly: 30 };

const JobDetail = () => {
  const theme = useTheme();
  const [open, setOpen] = React.useState<boolean>(false);
  const [toDate, setToDate] = React.useState<Dayjs | null>(dayjs());
  const [sortAscending, setSortAscending] = React.useState<boolean>(true);
  const [editWorklog, setEditWorkLog] = React.useState<boolean>(false);
  const [showApprovalPanel, setShowApprovalPanel] = React.useState<boolean>(false);
  const [approvalList, setApprovalList] = React.useState<WorklogType[]>([]);
  const [fromDate, setFromDate] = React.useState<Dayjs | null>(dayjs().subtract(1, 'week'));
  const [selectedLog, setSelectedLog] = React.useState<WorklogType | null>(null);
  const [message, setMessage] = React.useState<MessageType | null>(null);
  const { user } = useSelector((state: RootState) => state.auth);
  const { jobID } = useParams();
  const navigate = useNavigate();
  const isMobileScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const params = {
    jobID: jobID,
    startDate: dayjs(fromDate).format('YYYY-MM-DD'),
    endDate: dayjs(toDate).format('YYYY-MM-DD'),
  };

  const { data: jobs, isFetching: isFetchingJob } = useGetClientJobsQuery({});
  const job = jobs?.find((jobItem) => jobItem.job_id === jobID);
  const { data: jobManagers } = useGetManagerListQuery({});
  const { data: jobWorklogs, isFetching: isFetchingLogs } = useGetWorklogsByJobIdQuery(params);
  const { data: jobActivities } = useGetActivityBreakdownByJobQuery(params);
  const { filterPanel } = useSelector((state: RootState) => state.filterPanel);
  const isTabletScreen = useMediaQuery(theme.breakpoints.down('md'));

  const [addJobComment] = useAddJobCommentMutation();
  const [deleteJobComment] = useDeleteJobCommentMutation();
  const [updateJobComment] = useUpdateJobCommentMutation();
  const [deleteWorkLog] = useDeleteWorklogMutation();
  const [unSyncWorklog, { isLoading: isUnsyncing }] = useUnsyncWorklogMutation();

  const isJobManagerOrAdmin = job?.job_manager_data?.user_id === user?.user_id || user?.user_role === 'A';
  const isFetching = isFetchingJob || isFetchingLogs || isUnsyncing;

  const sortWorklogs = (worklogs: WorklogType[]) => {
    const sortedWorklogs = worklogs.slice().sort((a, b) => {
      const dateA = dayjs(a.date);
      const dateB = dayjs(b.date);

      if (dateA < dateB) return -1;
      if (dateA > dateB) return 1;
      return 0;
    });
    return sortedWorklogs;
  };

  let worklogs = jobWorklogs?.length ? sortWorklogs(jobWorklogs) : [];

  if (!sortAscending) {
    worklogs = [...worklogs].reverse();
  }

  const addComment = async (newComment: string) => {
    const result = await addJobComment({
      comment_description: newComment,
      job_id: jobID,
    });

    if ('data' in result) return 'success';

    return 'error';
  };

  const deleteComment = async (commentId: string) => {
    const result = await deleteJobComment(commentId);

    if ('data' in result) return 'success';

    return 'error';
  };

  const updateComment = async (updatedComment: string, commentId: string) => {
    const result = await updateJobComment({
      comment_description: updatedComment,
      comment_id: commentId,
    });

    if ('data' in result) return 'success';

    return 'error';
  };

  const handleDocumentationNavigate = () => {
    navigate(`/jobs/${params.jobID}/documentation`, {
      state: { startDate: params.startDate, endDate: params.endDate, job: job },
    });
  };

  const handleCloseSnackbar = () => {
    setMessage(null);
  };

  const handleFromDateChange = (value: Dayjs | null) => {
    setFromDate(value);
  };

  const handleToDateChange = (value: Dayjs | null) => {
    setToDate(value);
  };

  const handleOpenSnackbar = (displayMessage: MessageType) => {
    setMessage(displayMessage);
  };

  const isSelected = (id: string) => {
    return approvalList.some((log) => log.slip_id === id);
  };

  const onDeleteWorkLog = async (worklogId: string) => {
    const result = await deleteWorkLog(worklogId);

    if ('data' in result) {
      setMessage({ type: 'success', msg: 'WorkLog deleted' });
    }

    if ('error' in result) {
      setMessage({ type: 'error', msg: 'Error deleting worklog' });
    }
  };

  const onEditWorklog = (worklog: WorklogType) => {
    setSelectedLog(worklog);
    setEditWorkLog(true);
  };

  const onApproveWorklog = (worklog: WorklogType) => {
    const list: WorklogType[] = [...approvalList];
    const exists = list.some((log) => log.slip_id === worklog.slip_id);
    if (exists) {
      setMessage({ type: 'error', msg: 'Already in the list' });
      return;
    }
    list.push(worklog);
    setApprovalList(list);
    setShowApprovalPanel(true);
  };

  const onUnsyncWorkLog = async (worklogId: string) => {
    const body = {
      slip_id: worklogId,
    };

    const result = await unSyncWorklog(body);

    if ('data' in result) {
      setMessage({ type: 'success', msg: 'WorkLog unsynced' });
      return;
    }

    setMessage({ type: 'error', msg: 'Error unsyncing worklog' });
  };

  const renderStatusChip = (activityId: string) => {
    return (
      <Chip
        key={activityId}
        size="small"
        label={activityId}
        variant="outlined"
        sx={{
          color: theme.palette.busmanColors.busmanPrimary,
          borderColor: theme.palette.busmanColors.busmanPrimary,
          fontSize: '11px',
        }}
        // avatar={
        //   <Avatar sx={{ bgcolor: 'transparent' }}>
        //     <Typography color={chipColor()} variant="h6">
        //       {activityId}
        //     </Typography>
        //   </Avatar>
        // }
      />
    );
  };
  const renderActivityTable = () => {
    const sortedActivities = jobActivities && sortLists([...jobActivities], 'activity_name');

    return (
      <TableContainer sx={{ maxHeight: 250 }} component={Paper}>
        <Table stickyHeader aria-label="activity table">
          <TableHead>
            <TableRow>
              <TableCell>ACTIVITY ID</TableCell>
              <TableCell align="right">HOURS</TableCell>
              <TableCell align="right">BILLABLE(AUD)</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {sortedActivities?.map((row: JobActivityBreakdownType) => (
              <TableRow key={row.activity_id} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                <TableCell component="th" scope="row">
                  {row.activity_name}
                </TableCell>
                <TableCell align="right">{row.total_hours}</TableCell>
                <TableCell align="right">{`$ ${row.total_billable}`}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    );
  };

  const renderEditJobModal = () => {
    if (!open) return null;

    return (
      <div>
        <Modal
          open={open}
          onClose={() => setOpen(false)}
          sx={modalStyles}
          aria-labelledby="editJob-modal-title"
          aria-describedby="editJob-modal-description">
          <EditJob
            key={job?.job_id}
            close={() => setOpen(false)}
            job={job}
            jobManagers={jobManagers || []}
            showMessage={handleOpenSnackbar}
          />
        </Modal>
      </div>
    );
  };

  const renderJob = () => {
    return (
      <JobDetailContainer>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            width: '100%',
            height: 'fit-content',
          }}>
          <Typography variant="h4">{job?.job_number}</Typography>
          <Box display="flex" justifyContent="space-between" sx={{ width: '225px' }}>
            {!isMobileScreen && (
              <CustomButton
                variant="contained_grey"
                onClick={handleDocumentationNavigate}
                width="184px"
                text="Documentation"
              />
            )}
            <Box sx={{ marginLeft: 'auto' }}>
              <CustomButton
                variant="contained_grey"
                width="small"
                toolTip="Edit Job"
                text={<EditIcon />}
                onClick={() => setOpen(true)}
              />
            </Box>
          </Box>
        </Box>
        <Typography variant="subtitle1">{job?.client.client_name}</Typography>
        <TextField
          InputProps={{
            readOnly: true,
            fullWidth: true,
            multiline: true,
          }}
          value={job?.job_description || ''}
          sx={{
            backgroundColor: theme.palette.background.default,
            border: 'none',
            borderRadius: '2px',
            mb: theme.spacing(1),
            pr: theme.spacing(1),
            '.MuiOutlinedInput-notchedOutline': {
              border: 'none',
            },
          }}
        />
        <Row sx={{ gap: !isMobileScreen ? theme.spacing(4) : '' }}>
          <Box sx={{ width: '100%' }}>
            <Typography variant="body2" gutterBottom>
              TYPE
            </Typography>
            <CustomTextField
              InputProps={{
                readOnly: true,
              }}
              value={job?.job_type}
              fullWidth
              name="type"
              size="small"
            />
          </Box>
          <Box sx={{ width: '100%' }}>
            <Typography variant="body2" gutterBottom>
              STATUS
            </Typography>
            <CustomTextField
              InputProps={{
                readOnly: true,
              }}
              value={job?.job_status}
              fullWidth
              name="type"
              size="small"
            />
          </Box>
        </Row>
        {/* TODO: Stats section to be reviewed and added later */}
        {/* <SectionHeader variant="h6" gutterBottom>
          Statistics
        </SectionHeader>
        <DateRangeBox>
          <DateBox>
            <Typography variant="body2" gutterBottom>
              Start Date
            </Typography>
            <DatePicker value={dayjs(job?.start_date)} />
          </DateBox>
          <DateBox>
            <Typography variant="body2" gutterBottom>
              End Date
            </Typography>
            <DatePicker value={dayjs(job?.end_date)} />
          </DateBox>
        </DateRangeBox>
        <Row>
          <Box sx={{ width: '100%' }}>
            <Typography variant="body2" gutterBottom>
              P.O NUMBER (CUSTOMER P.O)
            </Typography>
            <CustomTextField
              InputProps={{
                readOnly: true,
              }}  fullWidth name="type" size="small" />
          </Box>
          <Box sx={{ width: '280px' }}>
            <Typography variant="body2" gutterBottom>
              TAX STATUS
            </Typography>
            <CustomTextField
              InputProps={{
                readOnly: true,
              }}  fullWidth name="type" size="small" />
          </Box>
        </Row>
        <Row>
          <Box sx={{ width: '280px' }}>
            <Typography variant="body2" gutterBottom>
              HOURS SPENT
            </Typography>
            <CustomTextField
              InputProps={{
                readOnly: true,
              }}  fullWidth name="type" size="small" />
          </Box>
          <Box sx={{ width: '280px' }}>
            <Typography variant="body2" gutterBottom>
              TOTAL COST
            </Typography>
            <CustomTextField
              InputProps={{
                readOnly: true,
              }}  fullWidth name="type" size="small" />
          </Box>
        </Row>
        <Row>
          <Box sx={{ width: '280px' }}>
            <Typography variant="body2" gutterBottom>
              TOTAL BILLABLE
            </Typography>
            <CustomTextField
              InputProps={{
                readOnly: true,
              }}  fullWidth name="type" size="small" />
          </Box>
          <Box sx={{ width: '280px' }}>
            <Typography variant="body2" gutterBottom>
              TOTAL BILLED
            </Typography>
            <CustomTextField
              InputProps={{
                readOnly: true,
              }}  fullWidth name="type" size="small" />
          </Box>
        </Row> */}
        {job?.job_type === 'TimeBilled' && (
          <>
            <SectionHeader variant="h6" gutterBottom>
              Billing
            </SectionHeader>
            <Row sx={{ gap: !isMobileScreen ? theme.spacing(4) : '' }}>
              <Box sx={{ width: '100%' }}>
                <Typography variant="body2" gutterBottom>
                  BILLING PERIOD
                </Typography>
                <CustomTextField
                  InputProps={{
                    readOnly: true,
                  }}
                  value={job?.job_billing_cycle}
                  fullWidth
                  name="type"
                  size="small"
                />
              </Box>
              <Box sx={{ width: '100%' }}>
                <Typography variant="body2" gutterBottom>
                  NO.DAYS IN BILLING CYCLE
                </Typography>
                <CustomTextField
                  InputProps={{
                    readOnly: true,
                  }}
                  value={job?.job_billing_cycle ? BILLING_CYCLES[job?.job_billing_cycle] : ''}
                  fullWidth
                  name="type"
                  size="small"
                />
              </Box>
            </Row>
          </>
        )}
        <SectionHeader variant="h6" gutterBottom>
          Allowable Activities
        </SectionHeader>
        <Box
          sx={{
            display: 'flex',
            flexWrap: 'wrap',
            gap: theme.spacing(1),
            mb: theme.spacing(1),
            mt: theme.spacing(1),
            maxWidth: '100%',
            height: 'fit-content',
          }}>
          {job?.activities?.map((activity) => renderStatusChip(activity.activity_name))}
        </Box>
        <SectionHeader variant="h6" gutterBottom>
          Breakdown by Activity
        </SectionHeader>
        <Row sx={{ gap: !isMobileScreen ? theme.spacing(4) : '' }}>
          {jobActivities?.length ? renderActivityTable() : 'No Activities'}
        </Row>
        <SectionHeader variant="h6" gutterBottom>
          Metadata
        </SectionHeader>
        <Row sx={{ gap: !isMobileScreen ? theme.spacing(4) : '' }}>
          <Box sx={{ width: '100%' }}>
            <Typography variant="body2" gutterBottom>
              EEA PRIMARY CONTACT
            </Typography>
            <CustomTextField
              InputProps={{
                readOnly: true,
              }}
              aria-readonly
              value={job?.job_metadata?.primary_contact}
              fullWidth
              name="type"
              size="small"
            />
          </Box>
          <Box sx={{ width: '100%' }}>
            <Typography variant="body2" gutterBottom>
              JOB NAME
            </Typography>
            <CustomTextField
              InputProps={{
                readOnly: true,
              }}
              value={job?.job_metadata?.job_name}
              fullWidth
              name="type"
              size="small"
            />
          </Box>
        </Row>
        <Row sx={{ gap: !isMobileScreen ? theme.spacing(4) : '' }}>
          <Box sx={{ width: '100%' }}>
            <Typography variant="body2" gutterBottom>
              MAIN CONTACT
            </Typography>
            <CustomTextField
              InputProps={{
                readOnly: true,
              }}
              fullWidth
              name="type"
              size="small"
            />
          </Box>
          <Box sx={{ width: '100%' }}>
            <Typography variant="body2" gutterBottom>
              JOB MANAGER
            </Typography>
            <CustomTextField
              InputProps={{
                readOnly: true,
              }}
              value={job?.job_manager_data?.full_name}
              fullWidth
              name="type"
              size="small"
            />
          </Box>
        </Row>
        <Row sx={{ gap: !isMobileScreen ? theme.spacing(4) : '' }}>
          <Box sx={{ width: '100%' }}>
            <Typography variant="body2" gutterBottom>
              MAXIMUM COST
            </Typography>
            <CustomTextField
              InputProps={{
                readOnly: true,
              }}
              value={job?.job_metadata?.max_cost}
              fullWidth
              name="type"
              size="small"
            />
          </Box>
          <Box sx={{ width: '100%' }}>
            <Typography variant="body2" gutterBottom>
              QUOTED HOURS
            </Typography>
            <CustomTextField
              InputProps={{
                readOnly: true,
              }}
              value={job?.job_metadata?.quoted_hours}
              fullWidth
              name="type"
              size="small"
            />
          </Box>
        </Row>
        <Comments
          addNewComment={addComment}
          deleteComment={deleteComment}
          fetchComments={useGetJobCommentsQuery}
          updateComment={updateComment}
          id={jobID}
        />
      </JobDetailContainer>
    );
  };

  const renderFilterPanel = () => {
    return (
      <WorklogFilter>
        {!isMobileScreen && (
          <Typography variant="h4" sx={{ mb: theme.spacing(2) }}>
            Worklog Entries
          </Typography>
        )}
        <DateRangeBox>
          <DateBox>
            <Typography variant="body2" gutterBottom>
              FROM
            </Typography>
            <DatePicker allowMaxDate onChange={handleFromDateChange} value={fromDate} />
          </DateBox>
          <DateBox>
            <Typography variant="body2" gutterBottom>
              TO
            </Typography>
            <DatePicker allowMaxDate onChange={handleToDateChange} value={toDate} />
          </DateBox>
        </DateRangeBox>
        <Box sx={{ display: 'flex', width: '100%', justifyContent: 'flex-end' }}>
          <CustomButton
            variant="contained_grey"
            onClick={() => setSortAscending(!sortAscending)}
            text={sortAscending ? 'Ascending' : 'Descending'}
            width="medium"
            endIcon={<SwapVertIcon />}
          />
        </Box>
      </WorklogFilter>
    );
  };

  const renderWorklogs = () => {
    return (
      <WorklogsBox>
        {!isMobileScreen && renderFilterPanel()}
        {isMobileScreen && (
          <Box sx={{ width: '100%', backgroundColor: theme.palette.background.paper, mb: theme.spacing(1) }}>
            <Header>
              <Typography variant="h4">Worklog Entries </Typography>
            </Header>
          </Box>
        )}
        <Box sx={{ height: isMobileScreen ? 'fit-content' : '75vh', overflowY: 'auto' }}>
          {worklogs?.length ? (
            worklogs.map((worklog: WorklogType) => (
              <WorkLogCard
                key={worklog.slip_id}
                worklog={worklog}
                isSelected={isSelected}
                isJobManagerOrAdmin={isJobManagerOrAdmin}
                onApproveWorklog={onApproveWorklog}
                onEditWorklog={onEditWorklog}
                onDeleteWorkLog={onDeleteWorkLog}
                onUnsyncWorkLog={onUnsyncWorkLog}
              />
            ))
          ) : (
            <Box
              sx={{
                boxShadow: 2,
                display: 'flex',
                minHeight: '48px',
                maxWidth: '650px',
                borderRadius: '2px',
                alignItems: 'center',
                justifyContent: 'center',
                backgroundColor: theme.palette.background.paper,
              }}>
              <Typography variant="h4">{isFetchingLogs ? 'Loading ...' : 'No Worklogs'}</Typography>
            </Box>
          )}
        </Box>
      </WorklogsBox>
    );
  };

  return (
    <>
      {!isFetching && !job ? (
        <DataNotFound errorMessage="Job Not Found" url="jobs" />
      ) : (
        <>
          <Helmet>
            <title>BusMin</title>
            <meta name="Busmin" content="EEA-Job Detail" />
          </Helmet>
          <Loader open={isFetching} />
          <PageContainer sx={{ flexDirection: isTabletScreen ? 'column' : 'row' }}>
            {isMobileScreen && filterPanel && renderFilterPanel()}
            {!editWorklog && !showApprovalPanel && renderJob()}
            {renderWorklogs()}
            {renderEditJobModal()}
            {editWorklog && (
              <EditWorklog
                key={selectedLog?.slip_id}
                close={() => setEditWorkLog(false)}
                reset={() => {}}
                showMessage={handleOpenSnackbar}
                worklog={selectedLog}
              />
            )}
            {showApprovalPanel && (
              <ApproveWorklog
                approvalList={approvalList}
                setApprovalList={setApprovalList}
                setShowApprovalPanel={setShowApprovalPanel}
              />
            )}
          </PageContainer>
          <SnackBar message={message} onClose={handleCloseSnackbar} />
        </>
      )}
    </>
  );
};

export default JobDetail;
