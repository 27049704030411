/* eslint-disable @typescript-eslint/indent */
import * as React from 'react';
import { Helmet } from 'react-helmet-async';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
// import { pdf } from '@react-pdf/renderer';
import dayjs, { Dayjs } from 'dayjs';
import { eeaLogo } from 'images';

import { SwapVert as SwapVertIcon } from '@mui/icons-material';
import {
  Box,
  FormControl,
  List,
  ListItem,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';

import CustomButton from 'components/Button';
import DatePicker from 'components/DatePicker';
import { FilterButtons, FilterPanelContainer } from 'components/GlobalStyles/styles';
import Loader from 'components/Loader';
import { useGetActivityBreakdownByJobQuery, useGetWorklogsByJobIdQuery } from 'services/busmanApi';
import { JobActivityBreakdownType, WorklogType } from 'types/WorkLogTypes';
import { ABN } from 'utils/constants';

import { RootState } from '../../../store';

import DocHeaderDetails from './Components/DocHeaderDetails';
import DocSummaryHeader from './Components/DocSummaryHeader';
import {
  BackingDataContainer,
  DocHeader,
  DocSummary,
  logoContainer,
  QuoteDocBox,
  QuoteHeaderBox,
  QuoteHeaderBoxEnd,
  tableCelllg,
  tableCellsm,
} from './Styles/PDFstyles';
// import PDFDocument from './PDFDocument';

interface InvoiceFormData {
  invoiceNumber: string;
  customerPO: string;
  invoiceDesc: string;
  invoicedDate: string;
}

interface Map {
  [key: string]: WorklogType[];
}

function generateUniqueNumber() {
  const now = dayjs();

  // Get formatted date and time components
  const formattedDate = now.format('YYMMDD');
  const formattedTime = now.format('HHmmss');

  // Concatenate the formatted date and time components to form the order number
  const orderNumber = `${formattedDate}${formattedTime}`;

  return orderNumber;
}

const uniqueOrderNo = generateUniqueNumber();

const DEFAULT_DATA = {
  invoiceNumber: '',
  customerPO: '',
  invoiceDesc: '',
  invoicedDate: `Invoiced on ${dayjs().format('YYYY-MM-DD')}`,
};

const BackingDoc = () => {
  const theme = useTheme();
  const { jobID } = useParams();
  const { state: docDetails } = useLocation();
  const navigate = useNavigate();
  const [sortAscending, setSortAscending] = React.useState<boolean>(true);
  const [formValues, setFormValues] = React.useState<InvoiceFormData>(DEFAULT_DATA);
  const [isEMPHidden, setIsEMPHidden] = React.useState<boolean>(false);
  const { filterPanel } = useSelector((state: RootState) => state.filterPanel);

  const [fromDate, setFromDate] = React.useState<Dayjs | null>(
    docDetails?.startDate ? dayjs(docDetails?.startDate, 'YYYY-MM-DD') : dayjs().subtract(1, 'month'),
  );
  const [toDate, setToDate] = React.useState<Dayjs | null>(
    docDetails?.endDate ? dayjs(docDetails?.endDate, 'YYYY-MM-DD') : dayjs(),
  );
  const job = docDetails?.job;
  const params = {
    jobID: jobID,
    startDate: dayjs(fromDate).format('YYYY-MM-DD'),
    endDate: dayjs(toDate).format('YYYY-MM-DD'),
  };

  const { data: jobActivities } = useGetActivityBreakdownByJobQuery(params);
  const { data: jobWorklogs, isLoading } = useGetWorklogsByJobIdQuery(params);

  const handleSetFormValues = (key: string, value: string) => {
    setFormValues({ ...formValues, [key]: value });
  };

  const sortWorklogs = (worklogs: WorklogType[]) => {
    const sortedWorklogs: Map = {};
    worklogs
      .slice()
      .sort((a: WorklogType, b: WorklogType) => {
        return a.date.localeCompare(b.date);
      })
      .forEach((item: WorklogType) => {
        if (!sortedWorklogs[item.activity.activity_id]) {
          sortedWorklogs[item.activity.activity_id] = [];
        }
        sortedWorklogs[item.activity.activity_id].push(item);
      });

    const workLogsArr: WorklogType[][] = [];
    Object.entries(sortedWorklogs).forEach((entry) => {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const [key, value] = entry;
      workLogsArr.push(value);
    });

    return workLogsArr;
  };

  let worklogs = jobWorklogs?.length ? sortWorklogs(jobWorklogs) : [];
  let sortedActivities = jobActivities?.length
    ? [...jobActivities]?.sort((a, b) => {
        return a.total_billable - b.total_billable;
      })
    : [];
  if (!sortAscending) {
    worklogs = [...worklogs].reverse();
    sortedActivities = [...sortedActivities].reverse();
  }

  const GST = job?.client?.tax_code?.rate / 100 || 0;
  const subTotal =
    sortedActivities.length > 0
      ? sortedActivities.map((activity) => activity?.total_billable).reduce((prev, curr) => curr + prev)
      : 0;

  const gstTotal = subTotal * GST;
  const total = subTotal * GST + subTotal;

  React.useEffect(() => {
    if (!job) navigate(`/jobs/${jobID}`);

    if (job) {
      const dates =
        dayjs(fromDate).format('MMMM YYYY') === dayjs(toDate).format('MMMM YYYY')
          ? `during ${dayjs(fromDate).format('MMMM YYYY')}`
          : `from ${dayjs(fromDate).format('MMMM Do YYYY')} to ${dayjs(toDate).format('MMMM Do YYYY')}`;
      const invoiceDetail = `${job?.job_description} Work undertaken ${dates} under EEA job number ${job?.job_number}.`;

      setFormValues({
        ...formValues,
        invoiceDesc: invoiceDetail,
        invoiceNumber: `EEA-${uniqueOrderNo}`,
        customerPO: `${job?.job_number}`,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [job, fromDate, toDate]);

  const sortActivities = () => {
    setSortAscending(!sortAscending);
  };

  const handleFromDateChange = (value: Dayjs | null) => {
    setFromDate(value);
  };

  const handleToDateChange = (value: Dayjs | null) => {
    setToDate(value);
  };

  const openPdf = async () => {
    // const blob = await pdf(<PDFDocument formValues={formValues} />).toBlob();
    // const pdfURL = URL.createObjectURL(blob);
    // window.open(pdfURL, '_blank');
    window.print();
  };

  const renderDocHeader = () => {
    return (
      <Box sx={DocHeader}>
        <Box sx={QuoteHeaderBox}>
          <Box sx={logoContainer}>
            <img style={{ objectFit: 'contain', width: '100%' }} src={eeaLogo} alt="Element Logo" />
          </Box>
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: theme.spacing(3) }}>
            <Box>
              <Typography variant="body2">ATTN</Typography>
              <Typography sx={{ fontWeight: 'bold' }} variant="body1">
                {job?.client.address.contact_name}
              </Typography>
            </Box>
            <Box>
              <Typography variant="body2">CLIENT</Typography>
              <Typography sx={{ fontWeight: 'bold' }} variant="body1">
                {job?.client.client_name}
              </Typography>
              <Typography sx={{ fontWeight: 'bold' }} variant="body1">
                {job?.client.address.street}
              </Typography>
              <Typography sx={{ fontWeight: 'bold' }} variant="body1">
                {job?.client.address.city} {job?.client.address.postal_code}
              </Typography>
            </Box>
          </Box>
        </Box>
        <Box sx={QuoteHeaderBoxEnd}>
          <Box>
            <Typography gutterBottom variant="h4">
              Invoice Documentation
            </Typography>
            <Typography variant="body2" sx={{ textAlign: 'right' }}>
              Element Engineering Australia
            </Typography>
            <Typography variant="body2" sx={{ textAlign: 'right' }}>
              {ABN}
            </Typography>
          </Box>
          <DocHeaderDetails
            toDate={toDate}
            fromDate={fromDate}
            invoiceNumber={formValues.invoiceNumber}
            customerPO={formValues.customerPO}
            handleSetFormValues={handleSetFormValues}
          />
        </Box>
      </Box>
    );
  };

  const renderDocTable = (breakdownType: string) => {
    const detailedSections = ['DATE', 'HRS', 'EMP', 'SERVICE', 'DESCRIPTION', 'RATE', 'LINE TOTAL', 'TAX'];
    const summarySections = ['HRS', 'SERVICE', 'RATE', 'LINE TOTAL', 'TAX'];
    const sections = breakdownType === 'summary' ? summarySections : detailedSections;

    return (
      <Box sx={DocSummary}>
        <DocSummaryHeader
          breakdownType={breakdownType}
          invoicedDate={formValues.invoicedDate}
          invoiceDesc={formValues.invoiceDesc}
          handleSetFormValues={handleSetFormValues}
          gstCode={job?.client?.tax_code?.code}
        />

        <TableContainer sx={{ overflow: 'visible' }}>
          <Table aria-label="activity table">
            <TableHead>
              <TableRow>
                {sections.map((section) => {
                  if (section === 'TAX')
                    return (
                      <TableCell align="center" key={section}>
                        {section}
                      </TableCell>
                    );
                  if (section === 'EMP')
                    return (
                      <Tooltip
                        slotProps={{
                          popper: {
                            modifiers: [
                              {
                                name: 'offset',
                                options: {
                                  offset: [-10, -25],
                                },
                              },
                            ],
                          },
                        }}
                        title={isEMPHidden ? 'Show EMP' : 'Hide EMP'}>
                        <TableCell
                          sx={{
                            cursor: 'pointer',
                            textDecoration: isEMPHidden ? 'line-through' : 'none',
                            '@media print': {
                              visibility: isEMPHidden ? 'hidden' : 'show',
                            },
                          }}
                          onClick={() => setIsEMPHidden(!isEMPHidden)}
                          key={section}>
                          {section}
                          {/* {HoverButton()} */}
                        </TableCell>
                      </Tooltip>
                    );
                  return <TableCell key={section}>{section}</TableCell>;
                })}
              </TableRow>
            </TableHead>
            <TableBody
              sx={{
                border: `1px solid ${theme.palette.background.default}`,
                gap: '16px',
              }}>
              {breakdownType === 'summary'
                ? sortedActivities?.map((row: JobActivityBreakdownType) => (
                    <TableRow key={row.activity_id}>
                      <TableCell sx={{ borderBottom: 'none' }}>{row.total_hours}</TableCell>
                      <TableCell component="th" scope="row" sx={{ borderBottom: 'none' }}>
                        {row.activity_name}
                      </TableCell>
                      <TableCell sx={{ borderBottom: 'none' }}>${row.rate?.toFixed(2)}</TableCell>
                      <TableCell sx={{ borderBottom: 'none' }}>${row.total_hours * row.rate}</TableCell>
                      <TableCell align="center" sx={{ borderBottom: 'none' }}>
                        ${(row.rate * Number(row.total_hours) * GST).toFixed(2)}
                      </TableCell>
                    </TableRow>
                  ))
                : worklogs.map((row) =>
                    row.map((item: WorklogType, idx: number) => {
                      const EMP = item.employee.full_name
                        .split(' ')
                        .map((name) => name[0])
                        .join('');
                      return (
                        <TableRow
                          key={item.slip_id}
                          sx={
                            item.activity.activity_id !== row[idx + 1]?.activity.activity_id
                              ? { borderBottom: `1px solid ${theme.palette.background.default}` }
                              : {}
                          }>
                          <TableCell sx={tableCellsm}>
                            {idx !== 0 && item.date === row[idx - 1].date ? null : item.date}
                          </TableCell>
                          <TableCell sx={tableCellsm}>{item.worklog_hours}</TableCell>
                          <Tooltip
                            slotProps={{
                              popper: {
                                modifiers: [
                                  {
                                    name: 'offset',
                                    options: {
                                      offset: [-12, -45],
                                    },
                                  },
                                ],
                              },
                            }}
                            title={!isEMPHidden ? item.employee.full_name : ''}>
                            <TableCell sx={tableCellsm}>{!isEMPHidden ? EMP : ''}</TableCell>
                          </Tooltip>
                          <TableCell component="th" scope="item" sx={tableCellsm}>
                            {item.activity.activity_name}
                          </TableCell>
                          <TableCell sx={{ ...tableCelllg, display: 'flex', flexDirection: 'column' }}>
                            <List sx={{ padding: 0 }}>
                              {item.description.split('\n').map((description) => {
                                return (
                                  <ListItem
                                    sx={{
                                      paddingLeft: '0',
                                      paddingTop: '0',
                                      mt: 0,
                                    }}
                                    key={description}>
                                    <Typography
                                      sx={{ width: '100%', wordWrap: 'breatk-word', overflowWrap: 'break-word' }}>
                                      {description}
                                    </Typography>
                                  </ListItem>
                                );
                              })}
                            </List>
                          </TableCell>

                          <TableCell sx={tableCellsm}>{item.activity.rate}</TableCell>
                          <TableCell sx={tableCellsm}>
                            ${(item.worklog_hours * Number(item.activity.rate))?.toFixed(2)}
                          </TableCell>
                          <TableCell align="center" sx={tableCellsm}>
                            ${(item.worklog_hours * Number(item.activity.rate) * GST).toFixed(2)}
                          </TableCell>
                        </TableRow>
                      );
                    }),
                  )}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    );
  };

  const renderDocFooter = () => {
    return (
      <>
        <Box sx={{ ...DocHeader, marginTop: 'auto' }}>
          <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'flex-end' }}>
            <Typography variant="body1">Please remit the funds into the following account:</Typography>
            <Typography variant="body1">Name: Element Engineering Australia Pty Ltd</Typography>
            <Typography variant="body1">BSB: 036 058</Typography>
            <Typography variant="body1">Account: 036860</Typography>
            <Typography variant="body1">SWIFT Code: WPACAU2S</Typography>
          </Box>
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
            <Box>
              <Typography textAlign={'right'} variant="body2">
                SUBTOTAL
              </Typography>
              <Typography textAlign={'right'} variant="body1">
                ${subTotal?.toFixed(2)}
              </Typography>
            </Box>
            <Box>
              <Typography textAlign={'right'} variant="body2">
                GST
              </Typography>
              <Typography textAlign={'right'} variant="body1">
                ${gstTotal?.toFixed(2)}
              </Typography>
            </Box>
            <Box>
              <Typography textAlign={'right'} variant="body2">
                TOTAL
              </Typography>
              <Typography textAlign={'right'} variant="body1">
                ${total.toFixed(2)}
              </Typography>
            </Box>
          </Box>
        </Box>
      </>
    );
  };

  return (
    <>
      <Helmet>
        <title>BusMin</title>
        <meta name="Busmin" content="EEA-Backing-Documentation" />
      </Helmet>
      <Loader open={isLoading} />
      <Box sx={BackingDataContainer}>
        {filterPanel && (
          <FilterPanelContainer>
            <FormControl>
              <Typography variant="h4" gutterBottom sx={{ mb: 2 }}>
                Backing Documentation
              </Typography>
              <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                <Typography variant="body1" gutterBottom>
                  FROM
                </Typography>
                <DatePicker onChange={handleFromDateChange} value={fromDate}></DatePicker>
                <Typography variant="body1" gutterBottom mt={2}>
                  TO
                </Typography>
                <DatePicker onChange={handleToDateChange} value={toDate}></DatePicker>
                <FilterButtons>
                  <CustomButton
                    variant="contained_grey"
                    onClick={sortActivities}
                    text={sortAscending ? 'Ascending' : 'Descending'}
                    endIcon={<SwapVertIcon />}
                    width="medium"
                  />
                  <CustomButton variant="contained" onClick={openPdf} text="Create PDF" width="medium" />
                </FilterButtons>
              </Box>
            </FormControl>
          </FilterPanelContainer>
        )}
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: theme.spacing(2), height: 'fit-content' }}>
          <Box sx={QuoteDocBox}>
            {renderDocHeader()}
            {renderDocTable('summary')}
            {renderDocFooter()}
          </Box>
          <Box sx={QuoteDocBox}>
            {renderDocHeader()}
            {renderDocTable('detailed')}
            {renderDocFooter()}
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default BackingDoc;
