import React from 'react';

import { Box, InputAdornment, TextField, Typography } from '@mui/material';

interface TextFieldBoxTypes {
  label: string;
  placeholderText: boolean;
  purpose: string;
  onChangeFn: (key: string, value: string) => void;
  formKey: string;
  value: string | number;
  type?: string;
  required?: boolean;
}

const TextFieldBox = ({
  label,
  placeholderText,
  purpose,
  onChangeFn,
  formKey,
  value,
  type,
  required,
}: TextFieldBoxTypes) => {
  const fieldLabel = label
    ?.split('')
    .map((a) => (a === '_' ? ' ' : a))
    .join('');

  return (
    <Box>
      {!placeholderText && (
        <Typography textAlign={'left'} variant="body2">
          {fieldLabel?.toUpperCase()}
        </Typography>
      )}
      <TextField
        required={required}
        inputProps={{ min: 0 }}
        disabled={purpose === 'disabled'}
        sx={{
          width: '100%',
          height: '100%',
          direction: 'ltr',
          'input::-webkit-inner-spin-button': {
            ' -webkit-appearance': label === 'discount' ? 'none' : 'auto',
          },
        }}
        InputProps={{
          startAdornment: <InputAdornment position="start">{purpose === '$' ? '$' : null}</InputAdornment>,
          readOnly: purpose === 'readOnly',
        }}
        type={type}
        size="small"
        variant="outlined"
        placeholder={placeholderText ? fieldLabel.toUpperCase() : ''}
        value={value}
        onChange={onChangeFn ? (event) => onChangeFn(formKey, event.target.value) : () => {}}
      />
    </Box>
  );
};

export default TextFieldBox;
