import { configureStore } from '@reduxjs/toolkit';

import { reducer as themeReducer } from './components/ThemeProvider/slice';
import { reducer as authReducer } from './features/Auth/authSlice';
import { reducer as employeeReducer } from './features/Employee/EmployeeSlice';
import { reducer as filterReducer } from './features/FilterPanelSlice/filterPanelSlice';
import { reducer as workLogReducer } from './features/worklog/worklogSlice';
import { busmanApiSlice } from './services/busmanApi/busmanApiSlice';

const reducer = {
  auth: authReducer,
  theme: themeReducer,
  employee: employeeReducer,
  worklogs: workLogReducer,
  filterPanel: filterReducer,
  [busmanApiSlice.reducerPath]: busmanApiSlice.reducer,
};

export const store = configureStore({
  reducer: reducer,
  middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(busmanApiSlice.middleware),
  devTools: true,
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;
