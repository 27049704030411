import dayjs, { Dayjs } from 'dayjs';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';

import { WorklogDayType } from 'types/WorkLogTypes';
import { generateDateRange } from 'utils/date';

dayjs.extend(isSameOrBefore);

export const findMissingLogDays = (sortedWorklogs: WorklogDayType[], fromDate: Dayjs | null, toDate: Dayjs | null) => {
  const actualLogDates = sortedWorklogs.map(({ date }) => date);
  const selectedDateRange = generateDateRange(fromDate, toDate);
  const publicHolidayData = localStorage.getItem('public-holiday');
  const holidays = publicHolidayData ? JSON.parse(publicHolidayData) : [];

  const missingLogs = selectedDateRange?.filter(
    (date) =>
      !actualLogDates.includes(date) && dayjs(date).day() !== 0 && dayjs(date).day() !== 6 && !holidays.includes(date),
  );

  return missingLogs;
};
