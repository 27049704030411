import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { ActivitiesProps, ClientProps, JobProps, TaggingListType } from '../../types/WorkLogTypes';

interface WorkLogProps {
  clients: Array<ClientProps>;
  jobs: Array<JobProps>;
  activities: Array<ActivitiesProps>;
  tagsList: Array<TaggingListType>;
}

const initialState: WorkLogProps = {
  jobs: [],
  clients: [],
  activities: [],
  tagsList: [],
};

const worklogSlice = createSlice({
  name: 'worklog',
  initialState,
  reducers: {
    setClients: (state, action: PayloadAction<ClientProps[]>) => {
      state.clients = action.payload;
    },
    setActivities: (state, action: PayloadAction<ActivitiesProps[]>) => {
      state.activities = action.payload;
    },
    setJobs: (state, action: PayloadAction<JobProps[]>) => {
      state.jobs = action.payload;
    },
    setTagList: (state, action: PayloadAction<TaggingListType[]>) => {
      state.tagsList = action.payload;
    },
  },
});

export const { actions: worklogActions, reducer } = worklogSlice;

export default worklogSlice.reducer;
