import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { eeaLogoShort } from 'images';
import { RootState } from 'store';

import { Typography } from '@mui/material';

import SnackBar from 'components/SnackBar';
import { useGetNotificationsByIdQuery } from 'services/busmanApi/notificationsEndpoints';

import { onMessageListener, supported } from '../../firebase';

import { BadgeContainer } from './styles';

const NotificationCountBadge = () => {
  const [notificationCount, setNotificationCount] = useState(0);
  const [message, setMessage] = React.useState({ type: '', msg: '' });
  const location = useLocation();

  const { user } = useSelector((state: RootState) => state.auth);
  const { data: notificationsData, refetch } = useGetNotificationsByIdQuery(user?.user_id);

  const unReadNotifications =
    notificationsData && notificationsData.filter((notification) => notification.seen === false);

  //Updating notifications badge
  useEffect(() => {
    setNotificationCount(unReadNotifications ? unReadNotifications.length : 0);
  }, [notificationsData, unReadNotifications]);

  // Refetch data when there is a change in URL if the browser does not support FCM
  useEffect(() => {
    if (!supported) refetch();
  }, [location, refetch]);

  //Listener for new push notifications from firebase
  const enableMessageListener = async () => {
    if (supported) {
      onMessageListener()
        .then((payload) => {
          if (payload?.data.userId === user?.user_id) {
            setMessage({ type: 'success', msg: `Notification: ${payload.data.title}` });
            new Notification(payload.data.title, {
              icon: eeaLogoShort,
              body: payload.data.body,
              badge: eeaLogoShort,
              image: eeaLogoShort,
            });
          }
          refetch();
        })
        .catch((err) => console.error(new Error(err)));
    } else {
      console.log('Firebase Push notifications are not supported in this browser');
    }
  };

  useEffect(() => {
    enableMessageListener();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleCloseSnackbar = () => {
    setMessage({ type: '', msg: '' });
  };

  return (
    <>
      <SnackBar message={message} onClose={handleCloseSnackbar} />
      <BadgeContainer sx={{ display: notificationCount === 0 ? 'none' : 'flex' }}>
        <Typography
          sx={{
            color: 'white',
            fontSize: '8px',
          }}>
          {notificationCount}
        </Typography>
      </BadgeContainer>
    </>
  );
};

export default NotificationCountBadge;
