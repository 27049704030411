import * as React from 'react';
import { useSelector } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';

import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import GroupsIcon from '@mui/icons-material/Groups';
import NotificationsIcon from '@mui/icons-material/Notifications';
import UserIcon from '@mui/icons-material/Person';
import SettingsIcon from '@mui/icons-material/Settings';
import WorkIcon from '@mui/icons-material/Work';
import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import List from '@mui/material/List';
import { useTheme } from '@mui/material/styles';

import SideDrawer from 'components/SideBar/Component/SideDrawer/SideDrawer';

import { BMIconLight } from '../../images';
import { RootState } from '../../store';
import SideBarList from '../SideBarList';

import { AppBarStyled, Divider, Header, IconListBox } from './styles';

const appBarTopIcons = [
  { label: 'ME', Icon: UserIcon, permission: 'All' },
  { label: 'TEAMS', Icon: GroupsIcon, permission: ['A', 'M'] },
];

const appBarBottomIcons = [
  { label: 'CLIENT', Icon: WorkIcon, permission: ['A', 'M'] },
  { label: 'SETTING', Icon: SettingsIcon, permission: ['A'] },
  { label: 'NOTIFICATION', Icon: NotificationsIcon, permission: 'All' },
  { label: 'ACCOUNT', Icon: AccountCircleIcon, permission: 'All' },
];

export default function SideBar() {
  const theme = useTheme();
  const { pathname } = useLocation();
  const [currentMenu, setCurrentMenu] = React.useState<string>('');
  const [openDrawer, setOpenDrawer] = React.useState<boolean>(false);
  const { isAuthenticated, user } = useSelector((state: RootState) => state.auth);

  const closeDrawer = () => (event: React.KeyboardEvent | React.MouseEvent) => {
    if (
      event.type === 'keydown' &&
      ((event as React.KeyboardEvent).key === 'Tab' || (event as React.KeyboardEvent).key === 'Shift')
    )
      return;
    setCurrentMenu('');
    setOpenDrawer(false);
  };

  const handleMenuClick = (label: string) => {
    setCurrentMenu(label);
    if (!openDrawer && label !== 'NOTIFICATION') setOpenDrawer(true);
    if (openDrawer && label === 'NOTIFICATION') setOpenDrawer(false);
  };

  if (pathname === '/theme') {
    return null;
  }

  return (
    <AppBarStyled>
      <Header sx={{ marginTop: '10px', marginBottom: theme.spacing(5) }}>
        <Link to={'/'}>
          <Avatar sx={{ scale: '.8' }} alt="Busmin Icon" src={BMIconLight} />
        </Link>
      </Header>
      {isAuthenticated && (
        <>
          <IconListBox>
            <List>
              {appBarTopIcons.map((menu) => {
                if (menu.permission === 'All' || menu.permission.includes(user.user_role)) {
                  return (
                    <SideBarList
                      key={`${menu.label}-topIcon`}
                      active={currentMenu === menu.label}
                      label={menu.label}
                      MenuIcon={menu.Icon}
                      handleClick={handleMenuClick}
                    />
                  );
                }

                return null;
              })}
            </List>
            <List>
              {appBarBottomIcons.map((menu) => {
                if (menu.permission === 'All' || menu.permission.includes(user.user_role)) {
                  return (
                    <Box key={`${menu.label}-bottomIcon`}>
                      <SideBarList
                        active={currentMenu === menu.label}
                        label={menu.label}
                        MenuIcon={menu.Icon}
                        handleClick={handleMenuClick}
                      />
                      {menu.label === 'CLIENT' && <Divider variant="middle" />}
                    </Box>
                  );
                }

                return null;
              })}
            </List>
          </IconListBox>
          {openDrawer && <SideDrawer openDrawer={openDrawer} close={closeDrawer()} title={currentMenu} />}
        </>
      )}
    </AppBarStyled>
  );
}
