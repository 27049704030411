/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/indent */
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { Dayjs } from 'dayjs';
import { RootState } from 'store';

import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { Autocomplete, Box, TableBody, TableCell, TableRow, TextField, Tooltip, Typography } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import { useTheme } from '@mui/material/styles';

import CustomButton from 'components/Button';
import { MessageType } from 'components/SnackBar';
import { LineHeader, LineItem } from 'types/QuoteTypes';

import { CloseButton, DuplicateIcon } from '../styles';

import TextFieldBox from './TextFieldBox';

const tableCell = {
  borderBottom: 'none',
  padding: '8px',
  overFlow: 'hidden',
};

interface NewLineHeaderTypes {
  setFormValues: (key: string, value: string | LineHeader | LineItem | Dayjs | null, index: number) => void;
  handleDeleteLine: (key: string, lineHeaderIdx: number, lineItemIdx: number) => void;
  handleDuplicateLineHeader: (idx: number) => void;
  handleDeleteLineHeader: (idx: number) => void;
  lineHeaderIdx: number;
  lineHeader: LineHeader;
  setMessage: (message: MessageType) => void;
}

const NewLineHeader = ({
  setFormValues,
  lineHeaderIdx,
  lineHeader,
  handleDeleteLine,
  handleDuplicateLineHeader,
  handleDeleteLineHeader,
  setMessage,
}: NewLineHeaderTypes) => {
  const theme = useTheme();
  const [currentLine, setCurrentLine] = useState<LineItem>({
    units: 0,
    description: '',
    activityDetail: null,
    discount: 0,
    total: 0,
  });
  const [linesExpanded, setlinesExpanded] = useState(true);
  const { activities } = useSelector((state: RootState) => state.worklogs);
  const filteredActivities = activities.filter((activity) => activity.status === 'Chargeable');

  const getLineItemTotal = (lineItem: LineItem) => {
    const lineRate =
      activities.find((activity) => activity.activity_id === lineItem.activityDetail?.activityId)?.chargeable_detail
        ?.rate_excluding_tax || 0;
    return Number(lineItem.units) * Number(lineRate) - lineItem.discount;
  };

  const getLineHeaderTotal = () => {
    let total = 0;
    lineHeader.lineItems.forEach((line) => {
      total += getLineItemTotal(line);
    });

    return total.toFixed(2);
  };

  const handleSetLineHeader = (key: string, value: string | LineHeader | LineItem | Dayjs | null) => {
    const isLineItemEmpty = (lineItem: LineItem) => {
      return !lineItem.activityDetail?.activityId || !lineItem.units;
    };

    if (key === 'lineItems' && isLineItemEmpty(value as LineItem))
      return setMessage({ type: 'error', msg: 'Line item cannot be empty' });

    setFormValues('lineHeaders', value, lineHeaderIdx);

    setCurrentLine({
      units: 0,
      description: '',
      activityDetail: null,
      discount: 0,
      total: 0,
    });
  };

  const handleSetHeaderDescription = (key: string, value: string) => {
    setFormValues('lineHeaders', value, lineHeaderIdx);
  };

  const handlesetCurrentLine = (key: string, value: string | number | string | undefined) => {
    setCurrentLine((prevState) => {
      return {
        ...prevState,
        [key]: value?.toString(),
      };
    });
    setlinesExpanded(true);
  };

  const handleSetActivityDetail = (value: any) => {
    setCurrentLine((prevState) => {
      return { ...prevState, activityDetail: value };
    });
    setlinesExpanded(true);
  };

  const handleDuplicateLine = (idx: number) => {
    const lineToDuplicate = lineHeader.lineItems[idx];
    handleSetLineHeader('lineItems', lineToDuplicate);
  };

  React.useEffect(() => {
    setCurrentLine((prevState) => {
      return {
        ...prevState,
        total: getLineItemTotal(currentLine),
      };
    });
  }, [currentLine.units, currentLine.discount, currentLine.activityDetail?.activityId]);

  const RenderNewTableRow = () => {
    return (
      <>
        <TableRow>
          <TableCell sx={tableCell}>
            <TextFieldBox
              required
              label="00"
              placeholderText={true}
              purpose=""
              onChangeFn={handlesetCurrentLine}
              formKey="units"
              value={currentLine.units}
              type="Number"
            />
          </TableCell>
          <TableCell sx={tableCell}>
            <TextFieldBox
              required
              label="description"
              placeholderText={true}
              purpose=""
              onChangeFn={handlesetCurrentLine}
              formKey="description"
              value={currentLine.description}
            />
          </TableCell>
          <TableCell sx={tableCell}>
            <Box sx={{ height: '100%' }}>
              <Autocomplete
                size="small"
                value={currentLine.activityDetail}
                options={filteredActivities.map((activity) => {
                  return {
                    activityName: activity.activity_name,
                    rate: activity.chargeable_detail?.rate_excluding_tax,
                    activityId: activity.activity_id,
                  };
                })}
                getOptionLabel={(option) => option.activityName || ''}
                isOptionEqualToValue={(option, value) => option.activityName === value.activityName}
                onChange={(event, value) => {
                  handleSetActivityDetail(value);
                }}
                ListboxProps={{
                  style: {
                    fontSize: '10px',
                    textAlign: 'left',
                    padding: '0',
                  },
                }}
                renderInput={(params) => <TextField {...params} required />}
              />
            </Box>
          </TableCell>
          <TableCell sx={tableCell}>
            <TextFieldBox
              label="discount"
              placeholderText={true}
              purpose=""
              onChangeFn={handlesetCurrentLine}
              formKey="discount"
              value={currentLine.discount || 0}
              type="Number"
            />
          </TableCell>
          <TableCell sx={{ ...tableCell, textAlign: 'right' }}>
            <Box
              sx={{
                textAlign: 'left',
                width: '100%',
                backgroundColor: '#F2F2F2',
                height: '32px',
                padding: '8px 16px 8px 16px',
              }}>
              ${currentLine.total.toFixed(2)}
            </Box>
          </TableCell>
        </TableRow>
      </>
    );
  };

  const ButtonGroup = React.memo(() => (
    <Box sx={{ display: 'flex', justifyContent: 'flex-end', gap: theme.spacing(1) }}>
      <CustomButton
        onClick={() => handleSetLineHeader('lineItems', currentLine)}
        variant="contained"
        width="medium"
        text="Add Line Item"
        toolTip="Add Line"
      />
    </Box>
  ));
  ButtonGroup.displayName = 'ButtonGroup';

  return (
    <TableBody
      key={lineHeaderIdx}
      sx={{
        height: 'auto',
        border: `1px solid ${theme.palette.background.default}`,
        mb: '20px',
        position: 'relative',
      }}>
      <TableRow>
        <TableCell sx={tableCell}>
          <IconButton
            aria-label="expand row"
            size="small"
            sx={{ '&: focus': { backgroundColor: theme.palette.background.default } }}
            onClick={() => setlinesExpanded(!linesExpanded)}>
            {!linesExpanded ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell colSpan={2} sx={tableCell}>
          <TextFieldBox
            required
            label="description"
            placeholderText={true}
            purpose=""
            onChangeFn={handleSetHeaderDescription}
            formKey="description"
            value={lineHeader.description}
          />
        </TableCell>
        <TableCell sx={tableCell}></TableCell>
        <TableCell sx={tableCell}>
          <Box
            sx={{
              textAlign: 'left',
              width: '100%',
              backgroundColor: '#F2F2F2',
              height: '32px',
              padding: '8px 16px 8px 16px',
            }}>
            <Typography variant="body1">${getLineHeaderTotal()}</Typography>
          </Box>
        </TableCell>
        <TableCell sx={{ ...tableCell, position: 'relative' }}>
          <Box sx={{ display: 'flex', flexDirection: 'row' }}>
            <Tooltip title="Duplicate Header">
              <DuplicateIcon onClick={() => handleDuplicateLineHeader(lineHeaderIdx)} />
            </Tooltip>
            <Tooltip title="Remove Header">
              <CloseButton onClick={() => handleDeleteLineHeader(lineHeaderIdx)} />
            </Tooltip>
          </Box>
        </TableCell>
      </TableRow>
      {lineHeader.lineItems.map((row, idx) => {
        return (
          <TableRow
            key={`${row.description} ${idx}`}
            sx={linesExpanded ? { position: 'relative' } : { display: 'none' }}>
            <TableCell sx={{ ...tableCell, paddingLeft: theme.spacing(4) }}>{row.units}</TableCell>
            <TableCell sx={tableCell}>{row.description}</TableCell>
            <TableCell sx={{ ...tableCell }}>
              <Typography sx={{ ...tableCell, paddingTop: 0, paddingBottom: 0 }}>
                ${row.activityDetail?.rate || 0}
              </Typography>
              <Typography sx={{ ...tableCell, paddingTop: 0, paddingBottom: 0 }}>
                {row.activityDetail?.activityName}
              </Typography>
            </TableCell>
            <TableCell
              sx={{
                ...tableCell,
              }}>
              {row.discount}
            </TableCell>
            <TableCell
              sx={{
                ...tableCell,
              }}>
              ${getLineItemTotal(row)}
            </TableCell>
            <TableCell sx={{ ...tableCell, position: 'relative' }}>
              <Box sx={{ display: 'flex', flexDirection: 'row' }}>
                <Tooltip title="Duplicate Item" onClick={() => handleDuplicateLine(idx)}>
                  <DuplicateIcon />
                </Tooltip>
                <Tooltip title="Delete Item">
                  <CloseButton onClick={() => handleDeleteLine('line_headers', lineHeaderIdx, idx)} />
                </Tooltip>
              </Box>
            </TableCell>
          </TableRow>
        );
      })}
      {RenderNewTableRow()}
      <TableRow>
        <TableCell sx={tableCell}></TableCell>
        <TableCell sx={tableCell}></TableCell>
        <TableCell sx={tableCell}></TableCell>
        <TableCell sx={tableCell}></TableCell>
        <TableCell colSpan={2} sx={[tableCell, { gap: '16px', textAlign: 'right' }]}>
          <ButtonGroup />
        </TableCell>
      </TableRow>
    </TableBody>
  );
};

export default NewLineHeader;
