import { background } from '../../light/palette/background';

export const MuiButton = {
  MuiButton: {
    styleOverrides: {
      root: {
        borderRadius: '2px',
        fontWeight: 700,
        fontSize: '11px',
        textTransform: 'none' as const,
        '&:hover': {
          backgroundColor: '#9E9E9E',
          boxShadow: 'none',
          border: '1px solid #9E9E9E',
        },
        '&:focus': {
          backgroundColor: '#9E9E9E',
          border: 'none',
          boxShadow: 'none',
        },
      },
      '&:disabled': {
        backgroundColor: '#616161',
        color: '#9E9E9E',
        opacity: 0.5,
      },
      contained: {
        backgroundColor: '#5CB85C',
        border: '1px solid #5CB85C',
        color: '#fff',
        boxShadow: 'none',
      },
      contained_purple: {
        backgroundColor: '#9531FF',
        border: '1px solid #9531FF',
        color: '#fff',
        boxShadow: 'none',
      },
      contained_blue: {
        backgroundColor: '#00A7FF',
        border: '1px solid #00A7FF',
        color: '#fff',
        boxShadow: 'none',
      },
      contained_red: {
        backgroundColor: '#D9534F',
        border: '1px solid #D9534F',
        color: '#fff',
        boxShadow: 'none',
      },
      contained_lightBlue: {
        backgroundColor: '#5BC0DE',
        border: '1px solid #5BC0DE',
        color: '#fff',
      },
      contained_orange: {
        backgroundColor: '#FC8437',
        border: '1px solid #FC8437',
        color: '#fff',
      },
      contained_grey: {
        backgroundColor: '#616161',
        border: '1px solid #616161',
        color: '#fff',
        boxShadow: 'none',
      },
      contained_white: {
        backgroundColor: 'transparent',
        border: '1px solid transparent',
        color: '#000',
        boxShadow: 'none',
      },
      outlined: {
        backgroundColor: '#F2F2F2',
        border: '2px solid #fff',
        color: '#9E9E9E',
        boxShadow: 'none',
        '&:hover': {
          backgroundColor: '#F2F2F2',
          boxShadow: 'none',
          border: '2px solid #9531FF',
        },
      },
      contained_transparent: {
        backgroundColor: 'transparent',
        border: 'none',
        color: '#9E9E9E',
        boxShadow: 'none',
        '&:hover': {
          color: '#616161',
          backgroundColor: 'transparent',
          border: 'none',
        },
      },
    },
  },
};
