import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { useTheme } from '@mui/material';
import Box from '@mui/material/Box';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import Link from '@mui/material/Link';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';

import CustomButton from 'components/Button';
import Loader from 'components/Loader';
import PasswordTextField from 'components/PasswordTextField';
import { useLoginMutation } from 'services/busmanApi';
import { useAddUserFCMTokenMutation } from 'services/busmanApi/notificationsEndpoints';

import { requestForToken, supported } from '../../../../firebase';
import { BusminWordMark } from '../../../../images/index';
import { RootState } from '../../../../store';
import { authActions } from '../../authSlice';

import { BusminLogo, LoginContainer, LoginForm, LoginRow } from './styles';
interface LogInProps {
  setServerStatus: (status: boolean | null) => void;
}

export default function LogIn({ setServerStatus }: LogInProps) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const theme = useTheme();
  const [loginDetail, setloginDetail] = React.useState({ email: '', password: '' });
  const [error, setError] = React.useState<boolean>(false);
  const [errorMessage, setErrorMessage] = React.useState<string>('');
  const [rememberMe, setRememberMe] = React.useState<boolean>(true);
  const { isAuthenticated } = useSelector((state: RootState) => state.auth);
  const [addNewToken] = useAddUserFCMTokenMutation();

  React.useEffect(() => {
    if (isAuthenticated) navigate('/');

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [login, response] = useLoginMutation();

  const handleChange = (event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setloginDetail({ ...loginDetail, [event.currentTarget.name]: event.currentTarget.value });
  };

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    if (loginDetail.email === '' || loginDetail.password === '') {
      return;
    }

    const result = await login(loginDetail);

    if ('error' in result && 'status' in result.error) {
      if (result.error.status === 'FETCH_ERROR') {
        setError(true);
        setServerStatus(false);
        setErrorMessage('Service temporarily unavailable. Please try again later.');
        return;
      }
      setError(true);
      setErrorMessage('Incorrect email or password');
    }

    if ('data' in result) {
      dispatch(authActions.setCredentials(result.data.data));

      const userData = {
        userID: result.data.data.user.user_id,
        token: result.data.data.access_token,
      };

      sessionStorage.setItem('user', JSON.stringify(userData));

      if (rememberMe) localStorage.setItem('user', JSON.stringify(userData));

      if (supported) {
        Notification.requestPermission().then((permission) => {
          if (permission === 'granted') {
            requestForToken().then((currentToken) => {
              if (currentToken) {
                return addNewToken({
                  user_id: result.data.data.user.user_id,
                  token: currentToken,
                  is_active: 'True',
                  type: 'W',
                });
              }
            });
          }
        });
      }

      navigate('/');
    }
  };

  return (
    <>
      {/* <MaskBox>
        <Box
          sx={{
            position: 'absolute',
            top: 0,
            left: 0,
            height: '100vh',
            width: '100vw',
            background: theme.palette.busmanColors.gradient,
          }}></Box>
      </MaskBox> */}
      <LoginContainer>
        <Loader open={response.isLoading} />
        <LoginForm>
          <LoginRow>
            <Typography component="h3" variant="h3">
              Log in
            </Typography>
            <BusminLogo alt="EEA Logo" src={BusminWordMark} />
          </LoginRow>

          <Box component="form" onSubmit={handleSubmit} sx={{ width: '100%' }}>
            <TextField
              size="small"
              margin="normal"
              required
              fullWidth
              id="email"
              label="Email Address"
              placeholder="example@email.com"
              name="email"
              onChange={handleChange}
              value={loginDetail.email}
            />
            <PasswordTextField
              margin="normal"
              name="password"
              label="Password"
              value={loginDetail.password}
              onChange={handleChange}
              size="small"
            />
            {error && (
              <Typography variant="subtitle1" sx={{ color: 'red' }}>
                {errorMessage}
              </Typography>
            )}
            <LoginRow mt={theme.spacing(2)}>
              <FormControlLabel
                control={
                  <Checkbox
                    onChange={() => setRememberMe(!rememberMe)}
                    name="rememberme"
                    checked={rememberMe}
                    value={rememberMe}
                  />
                }
                label="Remember me"
              />
              <CustomButton type="submit" variant="contained_purple" width="medium" text="Log in" />
            </LoginRow>
            <Box sx={{ display: 'flex', width: '100%', justifyContent: 'flex-end', marginTop: '40px' }}>
              <Link href="/forgotpassword" align="right" variant="body2">
                Forgot password?
              </Link>
            </Box>
            {/* <Grid item>
            <Link href="#" variant="body2">
              {"Don't have an account? Sign Up"}
            </Link>
          </Grid> */}
          </Box>
        </LoginForm>
      </LoginContainer>
    </>
  );
}
