import * as React from 'react';
import { Helmet } from 'react-helmet-async';
import dayjs from 'dayjs';

import { Typography, useTheme } from '@mui/material';
import Box from '@mui/material/Box';

import { HeaderUnderline } from 'components/GlobalStyles/styles';
import Loader from 'components/Loader';

import { MessageType } from '../../components/SnackBar';
import {
  useLazySyncActivitiesQuery,
  useLazySyncCompanyFilesQuery,
  useLazySyncCustomersQuery,
  useLazySyncEmployeePayrollQuery,
  useLazySyncEmployeesQuery,
  useLazySyncJobsFromMYOBQuery,
  useLazySyncPayrollCategoryQuery,
  useSyncJobsToMYOBMutation,
} from '../../services/busmanApi';

import SyncWorklogsModal from './component/SyncWorklogsModal';
import SyncButton from './component';
import { ErrorLog, MYOBSyncContainer, SyncBox } from './styles';
interface MYOBSyncPageProps {
  setMessage: (message: MessageType | null) => void;
}

export interface SyncLogType {
  type: string;
  message: string;
  timeStamp: string;
}

interface SyncBoxComponentProps {
  title: string;
  handleSync: () => void;
}

function MYOBSyncPage({ setMessage }: MYOBSyncPageProps) {
  const theme = useTheme();
  const [syncConfirmModal, setSyncConfirmModal] = React.useState(false);
  const [errorLogs, setErrorLogs] = React.useState<SyncLogType[]>([]);
  const [LogMessage, setLogMessage] = React.useState<SyncLogType>({ type: '', message: '', timeStamp: '' });
  const [worklogsSyncing, setWorklogsSyncing] = React.useState(false);
  const lastLogRef = React.useRef<HTMLElement | null>(null);

  const [syncJobsToMYOB, { isLoading: isSyncingJobsToMYOB }] = useSyncJobsToMYOBMutation();
  // const [syncWorklogsToMYOB, { isLoading: isSyncingWorklogsToMYOB }] = useSyncWorklogsToMYOBMutation();
  const [syncActivities, { isFetching: isSyncingActivities }] = useLazySyncActivitiesQuery();
  const [syncCompanyFiles, { isFetching: isSyncingCompanyFiles }] = useLazySyncCompanyFilesQuery();
  const [syncCustomers, { isFetching: isSyncingCustomers }] = useLazySyncCustomersQuery();
  const [syncEmployeePayrollDetails, { isFetching: isSyncingEmployeePayroll }] = useLazySyncEmployeePayrollQuery();
  const [syncEmployees, { isFetching: isSycningEmployees }] = useLazySyncEmployeesQuery();
  const [syncJobsFromMYOB, { isFetching: isSyncingJobs }] = useLazySyncJobsFromMYOBQuery();
  const [syncPayrollCategory, { isFetching: isSyncingPayrollCategory }] = useLazySyncPayrollCategoryQuery();

  React.useEffect(() => {
    if (LogMessage.message === '' && LogMessage.type === '') return;
    setErrorLogs([...errorLogs, LogMessage]);
    setLogMessage({ type: '', message: '', timeStamp: '' });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [LogMessage]);

  const handleSetLogMessage = (type: string, message: string) => {
    setLogMessage({ timeStamp: `[ ${dayjs().format('HH:mm')} ]`, type, message });
  };

  const handleSyncActivities = async () => {
    handleSetLogMessage('success', 'Syncing Activities...');
    const result = await syncActivities({});

    if ('error' in result) {
      handleSetLogMessage('error', 'Syncing Activities Failed!');
      setMessage({ type: 'error', msg: 'Error syncing activities' });
    }

    if ('data' in result) {
      handleSetLogMessage('success', 'Syncing Activities Successful!');
      setMessage({ type: 'success', msg: 'Activities synced successfully' });
    }
  };

  // const handleSyncWorklogsToMYOB = async () => {
  //   const result = await syncWorklogsToMYOB({});

  //   if ('error' in result) setMessage({ type: 'error', msg: 'Error syncing worklogs to myob' });

  //   if ('data' in result) {
  //     setMessage({ type: 'success', msg: 'Worklogs synced to MYOB successfully' });
  //   }
  // };

  const handleSyncCompanyFiles = async () => {
    handleSetLogMessage('success', 'Syncing Company Files...');
    const result = await syncCompanyFiles({});

    if ('error' in result) {
      handleSetLogMessage('error', 'Error syncing Company Files');
      setMessage({ type: 'error', msg: 'Error syncing company files' });
    }

    if ('data' in result) {
      handleSetLogMessage('success', 'Syncing Company Files Complete!');
      setMessage({ type: 'success', msg: 'Company files synced successfully' });
    }
  };

  const handleSyncCustomers = async () => {
    handleSetLogMessage('success', 'Syncing Customers...');
    const result = await syncCustomers({});

    if ('error' in result) {
      handleSetLogMessage('error', 'Error syncing Customers');
      setMessage({ type: 'error', msg: 'Error syncing customers' });
    }

    if ('data' in result) {
      handleSetLogMessage('success', 'Syncing Customers Complete!');
      setMessage({ type: 'success', msg: 'Customers synced successfully' });
    }
  };

  const handleSyncEmployees = async () => {
    handleSetLogMessage('success', 'Syncing Employees...');
    const result = await syncEmployees({});

    if ('error' in result) {
      setMessage({ type: 'error', msg: 'Error syncing employees' });
      handleSetLogMessage('error', 'Syncing Employees Failed!');
    }

    if ('data' in result) {
      setMessage({ type: 'success', msg: 'Employees synced successfully' });
      handleSetLogMessage('success', 'Syncing Employees Complete!');
    }
  };

  const handleSyncEmployeePayroll = async () => {
    handleSetLogMessage('success', 'Syncing Employee Payroll...');
    const result = await syncEmployeePayrollDetails({});

    if ('error' in result) {
      handleSetLogMessage('error', 'Error syncing Employee Payroll');
      setMessage({ type: 'error', msg: 'Error syncing Employee Payroll' });
    }

    if ('data' in result) {
      handleSetLogMessage('success', 'Syncing Employee Payroll Complete!');
      setMessage({ type: 'success', msg: 'Employee Payroll synced successfully' });
    }
  };

  const handleSyncJobsFromMYOB = async () => {
    handleSetLogMessage('success', 'Syncing Jobs From Myob...');
    const result = await syncJobsFromMYOB({});

    if ('error' in result) {
      handleSetLogMessage('error', 'Error syncing Jobs From Myob');
      setMessage({ type: 'error', msg: 'Error syncing jobs' });
    }

    if ('data' in result) {
      handleSetLogMessage('success', 'Syncing Jobs From Myob Complete!');
      setMessage({ type: 'success', msg: 'Jobs synced successfully' });
    }
  };

  const handleSyncJobsToMYOB = async () => {
    handleSetLogMessage('success', 'Syncing Jobs to Myob...');
    const result = await syncJobsToMYOB({});

    if ('error' in result) {
      handleSetLogMessage('error', 'Error syncing Jobs to Myob');
      setMessage({ type: 'error', msg: 'Error syncing jobs to myob' });
    }

    if ('data' in result) {
      handleSetLogMessage('success', 'Syncing Jobs to Myob Complete!');
      setMessage({ type: 'success', msg: 'Jobs synced to MYOB successfully' });
    }
  };

  const handleSyncPayrollCategory = async () => {
    handleSetLogMessage('success', 'Syncing Payroll Category...');
    const result = await syncPayrollCategory({});

    if ('error' in result) {
      handleSetLogMessage('error', 'Error syncing Payroll Category');
      setMessage({ type: 'error', msg: 'Error syncing payroll category' });
    }

    if ('data' in result) {
      handleSetLogMessage('success', 'Syncing Payroll Category Complete!');
      setMessage({ type: 'success', msg: 'Payroll Category synced successfully' });
    }
  };

  const handleOpenSyncConfirm = () => {
    setSyncConfirmModal(true);
  };

  const isSyncing = () => {
    const syncing =
      isSyncingActivities ||
      isSyncingCompanyFiles ||
      isSyncingCustomers ||
      isSyncingEmployeePayroll ||
      isSycningEmployees ||
      isSyncingJobs ||
      isSyncingJobsToMYOB ||
      isSyncingPayrollCategory ||
      worklogsSyncing;

    return syncing;
  };

  // Scroll to the last log
  React.useEffect(() => {
    if (lastLogRef.current) {
      lastLogRef.current?.scrollIntoView({ behavior: 'smooth' });
    }
  }, [errorLogs]);

  const SyncBoxComponent = ({ title, handleSync }: SyncBoxComponentProps) => (
    <SyncBox>
      <Typography variant="subtitle1">{title}</Typography>
      <SyncButton onClick={handleSync} />
    </SyncBox>
  );

  return (
    <>
      <Helmet>
        <title>EEA</title>
        <meta name="Busman" content="EEA-MYOB-Sync" />
      </Helmet>
      <Loader open={isSyncing()} />
      {syncConfirmModal && (
        <SyncWorklogsModal
          open={syncConfirmModal}
          onClose={() => setSyncConfirmModal(false)}
          setMessage={setMessage}
          setLogMessage={handleSetLogMessage}
          setWorklogsSyncing={setWorklogsSyncing}
        />
      )}
      <MYOBSyncContainer>
        <Box sx={{ backgroundColor: theme.palette.background.paper, height: 'fit-content', flex: 2 }}>
          <HeaderUnderline>
            <Typography variant="h4">MYOB Sync</Typography>
          </HeaderUnderline>
          <Box sx={{ padding: theme.spacing(2) }}>
            <SyncBoxComponent title="Sync All Company files" handleSync={handleSyncCompanyFiles} />
            <SyncBoxComponent title="Sync All Customers" handleSync={handleSyncCustomers} />
            <SyncBoxComponent title="Sync All Employees" handleSync={handleSyncEmployees} />
            <SyncBoxComponent title="Sync All Employees Payroll Details" handleSync={handleSyncEmployeePayroll} />
            <SyncBoxComponent title="Sync Jobs From MYOB" handleSync={handleSyncJobsFromMYOB} />
            <SyncBoxComponent title="Sync Activities" handleSync={handleSyncActivities} />
            <SyncBoxComponent title="Sync Payroll Category" handleSync={handleSyncPayrollCategory} />
            <SyncBoxComponent title="Sync Worklogs" handleSync={handleOpenSyncConfirm} />
            <SyncBoxComponent title="Sync Job to MYOB" handleSync={handleSyncJobsToMYOB} />
          </Box>
        </Box>
        <Box
          sx={{
            backgroundColor: theme.palette.background.paper,
            height: '100%',
            maxHeight: '25%',
            minHeight: '25%',
            flex: 1,
            display: 'flex',
            flexDirection: 'column',
            [theme.breakpoints.down('sm')]: {
              width: '100%',
              maxHeight: 'none',
              height: '1000px',
            },
          }}>
          <HeaderUnderline>
            <Typography variant="h4">Log </Typography>
          </HeaderUnderline>
          <Box
            sx={{ padding: theme.spacing(2), height: '100%', maxHeight: '100%', overflow: 'hidden', display: 'flex' }}>
            <ErrorLog>
              {errorLogs.map((log, index) => (
                <Typography
                  key={index}
                  ref={index === errorLogs.length - 1 ? lastLogRef : null}
                  variant="h6"
                  sx={{
                    color:
                      log.type === 'error'
                        ? theme.palette.busmanColors.busmanRed
                        : theme.palette.busmanColors.busmanGreen,
                  }}>
                  {log.timeStamp} - {log.message}
                </Typography>
              ))}
            </ErrorLog>
          </Box>
        </Box>
      </MYOBSyncContainer>
    </>
  );
}

export default MYOBSyncPage;
