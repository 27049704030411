import { Box, styled } from '@mui/material';

export const QuoteDocMain = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  marginRight: theme.spacing(2),
  background: theme.palette.background.paper,
  gap: theme.spacing(4),
  width: '210mm',
  minHeight: '297mm',
  height: 'fit-content',
  pageBreakAfter: 'always',
  ':last-child': {
    marginTop: 'auto',
  },
  [theme.breakpoints.down('md')]: {
    marginRight: '8px',
  },
  '@media print': {
    margin: '0',
    '.page-break': {
      pageBreakAfter: 'always',
    },
  },
}));

export const ViewQuoteContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(2),
  height: 'fit-content',
  overflow: 'auto',
  '@media print': {
    margin: '0',
    gap: '0',
    height: 'fit-content',
    width: '210mm',
    '.page-break': {
      pageBreakAfter: 'always',
    },
  },
}));

export const QuoteDocContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  backgroundColor: theme.palette.background.paper,
  width: '210mm',
  height: 'fit-content',
  // minHeight: '297mm',
  pageBreakAfter: 'always',
  '@media print': {
    margin: '0',
    width: '210mm',
    padding: '0',
  },
}));

export const QuoteDocSummaryContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  backgroundColor: theme.palette.background.paper,
  width: '210mm',
  height: 'fit-content',
  pageBreakBefore: 'always',
  '@media print': {
    margin: '0',
  },
}));

export const QuoteHeader = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  gap: '40px',
  borderBottom: `1px solid ${theme.palette.background.default}`,
  padding: theme.spacing(4),
}));

export const HeaderContent = styled(Box)(() => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignitems: 'center',
  width: '100%',
}));

export const Footer = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  gap: theme.spacing(4),
  padding: theme.spacing(4),
  marginTop: 'auto',
  borderTop: `1px solid ${theme.palette.background.default}`,
}));

export const ContactDetails = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'flex-end',
  justifyContent: 'flex-end',
  gap: theme.spacing(4),
  width: '100%',
}));

export const TypographyContainer = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-end',
}));

export const LogoContainer = styled(Box)(() => ({
  width: '150px',
  height: '50px',
  marginBottom: '20',
}));

export const tableCell = {
  borderBottom: 'none',
};
