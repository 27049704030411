import * as React from 'react';
import { Helmet } from 'react-helmet-async';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import dayjs, { Dayjs } from 'dayjs';

import { ArrowBackIos, SwapVert as SwapVertIcon, ViewStream as ViewStreamIcon } from '@mui/icons-material';
import { Box, FormControl, MenuItem, Select, Typography, useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';

import AddLogButton from 'components/AddLogButton';
import CustomButton from 'components/Button';
import DatePicker from 'components/DatePicker';
import { FilterPanelContainer, PageContainer, WorklogsContainer } from 'components/GlobalStyles/styles';
import { FilterButtons } from 'components/GlobalStyles/styles';
import Loader from 'components/Loader';
import SnackBar, { MessageType } from 'components/SnackBar';
import WorklogDayCard from 'components/WorklogDayCard';
import ApproveWorklog from 'features/ApproveWorklog';
import EditWorklog from 'features/EditWorklog';
import { employeeActions } from 'features/Employee/EmployeeSlice';
import NewWorklog from 'features/NewWorklog';
import { ToggleButton } from 'features/NewWorklog/styles';
import { findMissingLogDays } from 'pages/WorkLog/helper';
import {
  useDeleteWorklogMutation,
  useGetWorklogsQuery,
  useLazyGetEmployeeDetailsQuery,
  useUnsyncWorklogMutation,
} from 'services/busmanApi';
import { WorklogDayType, WorklogType } from 'types/WorkLogTypes';
import { getPreferncesFromLocalStorage, setPreferencesInLocalStorage } from 'utils/localStorage';

import { RootState } from '../../store';

import { NoWorkLogsContainer } from './styles';

const EmployeeWorklogsPage = () => {
  const theme = useTheme();
  const initialGantPref = getPreferncesFromLocalStorage('showGantt');
  const initialSortPref = getPreferncesFromLocalStorage('employeeWorklogOrder');
  const [alignment, setAlignment] = React.useState('All');
  const [addWorkLog, setAddWorkLog] = React.useState<boolean>(false);
  const [editWorklog, setEditWorkLog] = React.useState<boolean>(false);
  const [page, setPage] = React.useState<number>(1);
  const [approvalList, setApprovalList] = React.useState<WorklogType[]>([]);
  const [showApprovalPanel, setShowApprovalPanel] = React.useState<boolean>(false);
  const [selected, setSelected] = React.useState<WorklogType | null>(null);
  const [fromDate, setFromDate] = React.useState<Dayjs | null>(dayjs().subtract(1, 'week'));
  const [toDate, setToDate] = React.useState<Dayjs | null>(dayjs());
  const [message, setMessage] = React.useState<MessageType | null>(null);
  const [view, setView] = React.useState('All');
  const [sortAscending, setSortAscending] = React.useState(initialSortPref);
  const [isGanttChartVisible, setIsGanttChartVisible] = React.useState(initialGantPref);
  const isMobileScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const { filterPanel } = useSelector((state: RootState) => state.filterPanel);

  const navigate = useNavigate();

  const { empID } = useParams();
  const dispatch = useDispatch();

  const { selectedEmployee } = useSelector((state: RootState) => state.employee);

  const params = {
    empID: empID,
    startDate: dayjs(fromDate).format('YYYY-MM-DD'),
    endDate: dayjs(toDate).format('YYYY-MM-DD'),
    page: page,
  };

  const {
    data: { sortedWorklogs, totalPages } = { sortedWorklogs: [], totalPages: 1 },
    isFetching,
    refetch,
  } = useGetWorklogsQuery(params);

  const [fetchEmployeeDetail] = useLazyGetEmployeeDetailsQuery();
  const [deleteWorkLog, { isLoading: isDeleting }] = useDeleteWorklogMutation();
  const [unSyncWorklog, { isLoading: isUnsyncing }] = useUnsyncWorklogMutation();

  const extractWorklogs = () => {
    let newSortedWorklogs: WorklogDayType[] = [];
    if (sortedWorklogs && sortedWorklogs.length) {
      newSortedWorklogs = [...sortedWorklogs];
      if (!sortAscending) {
        newSortedWorklogs = [...newSortedWorklogs].reverse();
      }
    }

    if (view !== 'All') {
      const filteredWorklogs = newSortedWorklogs.map((dayWorklog) => {
        const selectedWorklogs = dayWorklog.worklogs.filter((worklog) => worklog.status === view);
        if (selectedWorklogs.length) {
          return { ...dayWorklog, worklogs: selectedWorklogs };
        }
        return { ...dayWorklog, worklogs: [] };
      });

      newSortedWorklogs = filteredWorklogs.filter((worklogData) => worklogData.worklogs.length);
    }

    if (alignment !== 'All') {
      const filteredWorklogs = newSortedWorklogs.map((dayWorklog) => {
        const selectedWorklogs = dayWorklog?.worklogs.filter((worklog) => worklog.worklog_type === alignment);
        if (selectedWorklogs.length) {
          return { ...dayWorklog, worklogs: selectedWorklogs };
        }
        return { ...dayWorklog, worklogs: [] };
      });

      newSortedWorklogs = filteredWorklogs.filter((worklogData) => worklogData.worklogs.length);
    }
    return newSortedWorklogs;
  };

  const newSortedWorklogs = React.useMemo(() => {
    return extractWorklogs();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [view, sortAscending, sortedWorklogs, alignment]);

  const totalWorklogs = newSortedWorklogs?.flatMap((item) => item.worklogs);

  const fetchSelectedEmployee = async () => {
    const { data } = await fetchEmployeeDetail(empID);
    dispatch(employeeActions.setSelectedEmployee(data));
  };

  const missingLogDays = React.useMemo(() => {
    const missingDays = findMissingLogDays(sortedWorklogs, fromDate, toDate);
    return missingDays?.map((date) => dayjs(date).format('Do MMM')).join(', ');
  }, [sortedWorklogs, fromDate, toDate]);

  React.useEffect(() => {
    setPreferencesInLocalStorage('showGantt', isGanttChartVisible);
    setPreferencesInLocalStorage('employeeWorklogOrder', sortAscending);
  }, [isGanttChartVisible, sortAscending]);

  React.useEffect(() => {
    if (!selectedEmployee) {
      fetchSelectedEmployee();
    }

    return () => {
      dispatch(employeeActions.resetSelectedEmployee());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const closeEditWorklog = () => {
    setEditWorkLog(false);
    setSelected(null);
    if (approvalList.length) setShowApprovalPanel(true);
  };

  const closeNewWorklogForm = () => {
    setAddWorkLog(false);
    setSelected(null);
    if (approvalList.length) setShowApprovalPanel(true);
  };

  const isSelected = (id: string) => {
    return approvalList.some((log) => log.slip_id === id);
  };

  const handleCloseSnackbar = () => {
    setMessage(null);
  };

  const handleOpenSnackbar = (displayMessage: MessageType) => {
    setMessage(displayMessage);
  };

  const handleFromDateChange = (value: Dayjs | null) => {
    if (page !== 1) setPage(1);
    setFromDate(value);
  };

  const handleToDateChange = (value: Dayjs | null) => {
    if (page !== 1) setPage(1);
    setToDate(value);
  };

  const loadMore = () => {
    if (!isFetching) setPage(page + 1);
  };

  const resetRefetchPage = () => {
    if (page === 1) {
      refetch();
      return;
    }
    setPage(1);
  };

  const showEditWorklogForm = () => {
    if (addWorkLog) setAddWorkLog(false);
    if (showApprovalPanel) setShowApprovalPanel(false);

    setEditWorkLog(true);
  };

  const onApproveWorklog = (worklog: WorklogType) => {
    const list: WorklogType[] = [...approvalList];
    const exists = list.some((log) => log.slip_id === worklog.slip_id);
    if (exists) {
      const index = list.indexOf(list.find((log) => log.slip_id === worklog.slip_id) as WorklogType);
      list.splice(index, 1);
    } else {
      list.push(worklog);
    }
    setApprovalList(list);
    if (list.length === 0) {
      setShowApprovalPanel(false);
    } else setShowApprovalPanel(true);
    if (addWorkLog) setAddWorkLog(false);
    if (editWorklog) setEditWorkLog(false);
  };

  const onEditWorklog = (worklog: WorklogType) => {
    setSelected(worklog);
    showEditWorklogForm();
  };

  const showNewWorklogForm = () => {
    if (editWorklog) setEditWorkLog(false);
    if (showApprovalPanel) setShowApprovalPanel(false);

    setAddWorkLog(true);
  };

  const updateApprovalList = (id: string, updatedWorklog?: WorklogType | undefined) => {
    const newList: WorklogType[] = [...approvalList];

    const index = newList.findIndex((log) => log.slip_id === id);

    if (index === -1) return;

    newList.splice(index, 1);

    if (updatedWorklog) newList.push(updatedWorklog);

    setApprovalList([...newList]);
    if (!newList.length) setShowApprovalPanel(false);
  };

  const onDeleteWorkLog = async (worklogId: string) => {
    const result = await deleteWorkLog(worklogId);

    if ('data' in result) {
      //Check if opened edit form contains deleted worklog
      if (selected && selected.slip_id === worklogId) {
        closeEditWorklog();
      }

      updateApprovalList(worklogId);
      resetRefetchPage();
      setMessage({ type: 'success', msg: 'WorkLog deleted' });
    }

    if ('error' in result) {
      setMessage({ type: 'error', msg: 'Error deleting worklog' });
    }
  };

  const onUnsyncWorkLog = async (worklogId: string) => {
    const body = {
      slip_id: worklogId,
    };

    const result = await unSyncWorklog(body);

    if ('data' in result) {
      setMessage({ type: 'success', msg: 'WorkLog unsynced' });
      return;
    }

    setMessage({ type: 'error', msg: 'Error unsyncing worklog' });
  };

  const toggleGanttChart = () => {
    setIsGanttChartVisible(!isGanttChartVisible);
  };

  const renderWorklogDayCard = () => {
    return (
      <>
        {newSortedWorklogs?.length ? (
          newSortedWorklogs.map((dayWorklogs, index) => (
            <WorklogDayCard
              isGanttChartVisible={isGanttChartVisible}
              key={`${dayWorklogs.date}-${index}`}
              data={dayWorklogs}
              isSelected={isSelected}
              onApproveWorklog={onApproveWorklog}
              onEditWorklog={onEditWorklog}
              onDeleteWorkLog={onDeleteWorkLog}
              onUnsyncWorkLog={onUnsyncWorkLog}
            />
          ))
        ) : (
          <NoWorkLogsContainer>
            <Typography variant="h4">{alignment !== 'LeaveLog' ? 'No Worklogs' : 'No Leave Logs'}</Typography>
          </NoWorkLogsContainer>
        )}
      </>
    );
  };

  const handleChangeAlignment = (event: React.MouseEvent<HTMLElement>, newAlignment: string) => {
    if (newAlignment !== null) {
      setAlignment(newAlignment);
    }
  };

  return (
    <>
      <Helmet>
        <title>BusMin</title>
        <meta name="Busmin" content="EEA-Employee Worklogs" />
      </Helmet>
      <Loader open={isFetching || isDeleting || isUnsyncing} />
      <PageContainer>
        {!addWorkLog && !editWorklog && (
          <>
            {filterPanel && (
              <FilterPanelContainer>
                <FormControl>
                  {!isMobileScreen && (
                    <>
                      {empID ? (
                        <Box
                          display="flex"
                          flexDirection="row"
                          alignItems="center"
                          sx={{ mb: 2, gap: theme.spacing(1) }}>
                          <ArrowBackIos
                            sx={{
                              color: theme.palette.text.disabled,
                              fontSize: '0.8rem',
                              cursor: 'pointer',
                            }}
                            onClick={() => {
                              dispatch(employeeActions.resetSelectedEmployee());
                              navigate(-1);
                            }}
                          />
                          <Typography variant="h4">{selectedEmployee?.full_name} Worklogs</Typography>
                        </Box>
                      ) : (
                        <Typography variant="h4" gutterBottom sx={{ mb: 2 }}>
                          My Worklogs
                        </Typography>
                      )}
                    </>
                  )}
                  <ToggleButtonGroup
                    value={alignment}
                    exclusive
                    onChange={handleChangeAlignment}
                    sx={{
                      height: '40px',
                      width: '100%',
                      mb: 2,
                      border: `4px solid ${theme.palette.background.default}`,
                      backgroundColor: theme.palette.background.default,
                    }}>
                    <ToggleButton value="All">
                      <Typography variant="h6">All</Typography>
                    </ToggleButton>
                    <ToggleButton value="WorkLog">
                      <Typography variant="h6">Worklog</Typography>
                    </ToggleButton>
                    <ToggleButton value="LeaveLog">
                      <Typography variant="h6">Leave</Typography>
                    </ToggleButton>
                  </ToggleButtonGroup>
                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                    }}>
                    <Typography variant="body1" gutterBottom>
                      FROM
                    </Typography>
                    <DatePicker allowMaxDate onChange={handleFromDateChange} value={fromDate} />
                    <Typography variant="body1" gutterBottom mt={1}>
                      TO
                    </Typography>
                    <DatePicker allowMaxDate onChange={handleToDateChange} value={toDate} />
                  </Box>
                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      mt: theme.spacing(5),
                    }}>
                    <Typography variant="body2" gutterBottom>
                      VIEW
                    </Typography>
                    <Select
                      MenuProps={{ disableScrollLock: true }}
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={view}
                      sx={{ height: '32px' }}
                      onChange={(newSelection) => {
                        setView(newSelection.target.value);
                      }}>
                      <MenuItem value="All">All</MenuItem>
                      <MenuItem value="Pending">Pending</MenuItem>
                      <MenuItem value="Approved">Approved</MenuItem>
                    </Select>
                  </Box>
                  <FilterButtons>
                    <CustomButton
                      variant="contained_grey"
                      onClick={() => setSortAscending(!sortAscending)}
                      text={sortAscending ? 'Descending' : 'Ascending'}
                      endIcon={<SwapVertIcon />}
                      width="100%"
                    />
                    <CustomButton
                      variant="contained"
                      onClick={toggleGanttChart}
                      text={isGanttChartVisible ? 'Hide Gantt' : 'Show Gantt'}
                      endIcon={<ViewStreamIcon />}
                      width="100%"
                    />
                  </FilterButtons>
                  {!isFetching && (
                    <Typography
                      gutterBottom
                      sx={{
                        color: missingLogDays
                          ? theme.palette.busmanColors.busmanRed
                          : theme.palette.busmanColors.busmanGreen,
                        mt: theme.spacing(2),
                        mb: theme.spacing(2),
                      }}
                      variant={missingLogDays ? 'body2' : 'h6'}>
                      {missingLogDays ? `Missing logs on ${missingLogDays}` : 'Logs are up to date.'}
                    </Typography>
                  )}
                </FormControl>
              </FilterPanelContainer>
            )}
          </>
        )}
        <WorklogsContainer>
          <InfiniteScroll
            dataLength={totalWorklogs.length}
            hasMore={page < totalPages}
            next={() => loadMore()}
            loader={<Loader open={isFetching} />}>
            {renderWorklogDayCard()}
          </InfiniteScroll>
        </WorklogsContainer>
        {!addWorkLog && !editWorklog && <AddLogButton showNewWorklog={() => showNewWorklogForm()} />}
        {showApprovalPanel && (
          <ApproveWorklog
            approvalList={approvalList}
            setApprovalList={setApprovalList}
            setShowApprovalPanel={setShowApprovalPanel}
            showMessage={setMessage}
            refetch={resetRefetchPage}
          />
        )}
        {addWorkLog && (
          <NewWorklog close={closeNewWorklogForm} reset={resetRefetchPage} showMessage={handleOpenSnackbar} />
        )}
        {editWorklog && (
          <EditWorklog
            key={selected?.slip_id}
            close={closeEditWorklog}
            reset={resetRefetchPage}
            showMessage={handleOpenSnackbar}
            updateApprovalList={updateApprovalList}
            worklog={selected}
          />
        )}
      </PageContainer>

      <SnackBar message={message} onClose={handleCloseSnackbar} />
    </>
  );
};

export default EmployeeWorklogsPage;
